// 로딩
import React, { useEffect } from "react";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";
import { SplashAtom } from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";

import { ReactComponent as Logo } from "../Images/logo.svg";

const Splash = styled(S.flexRowCenter)`
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	background-color: white;
	svg {
		max-height: 50%;
		max-width: 90%;
	}
`;

export default () => {
	const setOpenSplash = useSetRecoilState(SplashAtom);

	// 앱 최초 실행 시 스플래시 노출, 3초후 닫힘
	useEffect(() => {
		const timer = setTimeout(() => {
			setOpenSplash(false);
		}, 3000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Splash>
			<Logo />
		</Splash>
	);
};
