// 리스트 없는 페이지
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";

import logoGray from "../Images/logo_gray.png";

const Container = styled.div<{ $small?: boolean }>`
	width: 100%;
	height: ${(props) => (props.$small ? "15rem" : 0)};
	flex-grow: ${(props) => (props.$small ? 0 : 1)};
	padding: 1.4rem 1.4rem 0.4rem;
	div {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img {
		width: 6rem;
		height: 6.3rem;
		margin-bottom: 2rem;
	}
`;
const NoSearch = styled(S.wrapper)`
	h1 {
		margin: 2rem 0;
	}
	ul {
		background-color: ${S.color.gray100};
		padding: 2rem;
		border-radius: 1rem;
	}
`;
const NoList = styled(S.wrapper)`
	background-color: ${S.color.gray100};
	border-radius: 1rem;
	justify-content: center;
`;

interface DefaultType {
	// 검색 결과 없음
	searchKeyword?: string;
	// 전체 페이지를 차지하지 않음
	small?: boolean;
}

export default ({ searchKeyword, small }: PropsWithChildren<DefaultType>) => {
	// 로딩중
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	return (
		<Container $small={small}>
			{searchKeyword !== undefined &&
			searchKeyword !== "" &&
			!isLoading ? (
				<NoSearch>
					<h1>
						<S.subhead_3>
							Tidak ada hasil pencarian untuk ‘{searchKeyword}’.
						</S.subhead_3>
					</h1>
					<S.textList $gray>
						<li>
							<S.bodyText_S>
								Angka, hangeul, abjad semua bisa.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Coba kurangi jumlah kata kunci anda atau ganti
								dengan kata kunci lain.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S>
								Coba cari menggunakan kata kunci yang lebih
								umum. assa
							</S.bodyText_S>
						</li>
					</S.textList>
				</NoSearch>
			) : (
				<NoList>
					<S.bodyText_M color={S.color.gray400}>
						{isLoading
							? "Mohon tunggu sebentar."
							: "Tidak ada pemberitahuan yang masuk."}
					</S.bodyText_M>
				</NoList>
			)}
		</Container>
	);
};
