// 분해하기/합성하기
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { LoadingAtom, UserAtom, NewsPrice } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import Filter from "../Components/Filter";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";
import Modal from "../Components/Modal";
import UPGD001002 from "./UPGD001002";

import { ReactComponent as Check } from "../Images/check.svg";
import { ReactComponent as Chevron } from "../Images/chevron.svg";
import { ReactComponent as Arrow } from "../Images/arrow_right.svg";
import point from "../Images/point.png";
import compose_title from "../Images/compose_title.png";
import { ReactComponent as Question } from "../Images/compose_q.svg";

// 분해
const SelecArea = styled(S.flexRowBetween)`
	width: fit-content;
	max-width: 12.8rem;
	height: 2.8rem;
	border: 1px solid ${S.color.gray300};
	border-radius: 0.6rem;
	margin: 1.4rem;
	padding: 0 0.2rem 0 1rem;
	span {
		color: ${S.color.gray600};
	}
	svg {
		height: 1.8rem;
		transform: rotate(90deg);
	}
`;
const TreasureList = styled.div`
	width: 100%;
	padding: 0.2rem 1.4rem;
	display: flex;
	flex-wrap: wrap;
`;
const TreasureListItem = styled.button<{ $checked?: boolean; $news?: boolean }>`
	width: calc(50% - 0.5rem);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 1.4rem;
	border-radius: 1rem;
	overflow: hidden;
	position: relative;
	border: 1px solid ${S.color.gray300};
	border-color: ${(props) => props.$checked && S.color.primaryVariant};
	background-color: ${(props) => props.$checked && props.$news && "#FFF8EE"};
	.itemImage {
		width: 100%;
		height: 10rem;
		position: relative;
		flex-shrink: 0;
	}
	.itemInfo {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		padding: 0.5rem 1.2rem 1.2rem;
	}
	.name {
		margin-bottom: 0.6rem;
	}
	.checkCircle {
		display: ${(props) => props.$checked && "flex"};
	}
`;
const Circle = styled(S.flexRowCenter)`
	width: 2.2rem;
	height: 2.2rem;
	background-color: ${S.color.primaryVariant};
	display: none;
	border-radius: 50%;
	position: absolute;
	left: 1.2rem;
	top: 1.2rem;
	svg {
		width: 70%;
		height: 70%;
		fill: white;
		stroke: white;
	}
`;
const CheckedInfoArea = styled.div`
	width: 100%;
	height: 0;
	background-color: ${S.color.dimmed70};
	display: flex;
	flex-direction: column;
	position: absolute;
	left: 0;
	bottom: 0;
	transition: height 100ms linear;
	&.view {
		height: 13rem;
	}
	button {
		width: 100%;
		background-color: ${S.color.primary};
		flex-grow: 1;
		color: white;
	}
`;
const CheckedInfo = styled.div`
	margin: 1.4rem;
	& > div {
		margin-bottom: 0.6rem;
	}
	& > div:last-child {
		margin-bottom: 0;
	}
`;
const ConfirmTreasureArea = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${S.color.gray100};
	border-radius: 0.6rem;
	padding: 1.6rem 0 2rem;
	margin-bottom: 2rem;
`;
const ConfirmTreasureWrap = styled.div`
	width: 100%;
	display: flex;
	overflow-x: scroll;
	& > div:first-child {
		margin-left: 1.6rem;
	}
	& > div:last-child {
		margin-right: 1.6rem;
	}
`;
const ConfirmTreasureImage = styled.div<{ $news?: boolean }>`
	width: 8rem;
	height: 8rem;
	margin-left: 1rem;
	flex-shrink: 0;
	border: 1px solid ${S.color.gray400};
	border-color: ${(props) => props.$news && S.color.primaryVariant};
	background-color: white;
	background-color: ${(props) => props.$news && "#FFF8EE"};
	border-radius: 0.5rem;
	overflow: hidden;
	position: relative;
	img {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
const ConfirmPriceInfo = styled.div`
	margin: 1.6rem 1.6rem 0;
	p {
		width: 40%;
	}
	p:last-child {
		text-align: right;
	}
`;
const CompletePoint = styled(S.flexRowCenter)`
	flex-direction: column;
	background-color: ${S.color.gray100};
	border-radius: 0.6rem;
	padding: 1.6rem;
`;
// 합성
const TitleArea = styled(S.flexRowCenter)`
	flex-direction: column;
	margin-top: 1.4rem;
	padding-top: 5.4rem;
	position: relative;
	&::after {
		content: "";
		display: block;
		width: 100%;
		height: 5.7rem;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translate(-50%);
		background-image: url(${compose_title});
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
`;
const Ribbon = styled(S.flexRowCenter)`
	height: 2.6rem;
	background-color: ${S.color.primary};
	margin-bottom: 1.2rem;
`;
const SpecialArea = styled(S.flexRowCenter)`
	margin: 1rem 0.7rem 1.4rem;
`;
const SpecialItem = styled(S.flexRowCenter)`
	flex-grow: 1;
	flex-shrink: 1;
	flex-direction: column;
	max-width: 35%;
	height: 18rem;
	border: 1px solid #3d2100;
	border-radius: 1rem;
	overflow: hidden;
	margin: 0 0.5rem;
`;
const SpecialItemImage = styled(S.flexRowCenter)`
	width: 100%;
	height: 10.3rem;
	background-color: #ffea88;
	padding: 0.8rem;
	div {
		width: 100%;
		height: 100%;
		background-color: white;
		border-radius: 0.6rem;
		box-shadow: inset 0 0.2rem #f7bb6e;
		position: relative;
		flex-direction: column;
		img {
			height: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;
const SpecialItemInfo = styled(S.flexRowCenter)`
	flex-direction: column;
	width: 100%;
	height: 7.7rem;
	background-color: #ffcd6b;
	box-shadow: inset 0 -0.4rem #ee831b;
	border-radius: 0 0 1rem 1rem;
	padding: 0.8rem;
	span {
		text-align: center;
	}
	.userProfile {
		width: 3.4rem;
		height: 3.4rem;
		border: 1px solid #3d2100;
		border-radius: 1rem;
		background-color: #ffeb89;
		padding: 0.4rem;
		div {
			background-color: white;
			height: 100%;
			border-radius: 0.8rem;
			overflow: hidden;
		}
	}
	.userNick {
		border: 1px solid #3d2100;
		background-color: #ffeb89;
		padding: 0.2rem 2rem;
		border-radius: 2rem;
		margin-top: 0.3rem;
	}
`;
const NormalArea = styled(S.flexRowStart)`
	flex: 1;
	flex-direction: column;
	background-color: ${S.color.gray200};
	padding: 1.4rem 1rem 0;
	overflow-y: scroll;
	& > span {
		text-align: center;
		margin-bottom: 0.4rem;
	}
`;
const NormalItem = styled(S.flexRowCenter)`
	width: 100%;
	padding: 1.2rem;
	background-color: white;
	border-radius: 1rem;
	box-shadow: 0 0.3rem ${S.color.gray300};
	margin-bottom: 1rem;
	.itemImage {
		width: 9rem;
		height: 7.3rem;
		border: 1px solid ${S.color.gray200};
		border-radius: 0.8rem;
		margin-right: 2.2rem;
		position: relative;
		.count {
			width: 3rem;
			height: 3rem;
			background-color: ${S.color.gray300};
			position: absolute;
			right: -1.6rem;
			bottom: -0.2rem;
			border-radius: 50%;
		}
	}
	.itemName {
		flex-grow: 1;
		margin-right: 1rem;
	}
`;
const Explain = styled(S.bodyText_S)`
	text-align: center;
	padding: 0.6rem;
`;

interface upgradeData {
	boxName: string;
	boxPrice: number;
	pumIdx: number;
	bmIdx: number;
	pmIdx: number;
	stateCode: string;
	regDt: string;
	modDt: string;
	lockYn: number;
	useYn: number;
	name: string;
	imageUrl: string;
	typeCode: string;
	displayPrice: number;
	realPrice: number;
	categoryCode: string;
	exIdx: number;
	pdmIdx: string;
	shortName: string;
}

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 뉴스룸 등록 기준 금액
	const newsPrice = useRecoilValue(NewsPrice);
	// 황성화 탭 인덱스
	const defaultTab = location.state ? location.state.data : 0;
	const [activeTab, setActiveTab] = useState<number>(0);
	// 팝업
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenNewsConfirm, setOpenNewsConfirm] = useState<boolean>(false);
	const [isOpenCompleteConfirm, setOpenCompleteConfirm] =
		useState<boolean>(false);
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
	const [isOpenComposeDisable, setOpenComposeDisable] =
		useState<boolean>(false);
	const [isOpenUPGD, setOpenUPGD] = useState<boolean>(false);
	const [UPGDTarget, setUPGDTarger] = useState<upgradeData>();
	// 정렬
	const [isOpenFilter, setOpenFilter] = useState<boolean>(false);
	const defaultOrderBy = 0;
	const [orderBy, setOrderBy] = useState<number>(defaultOrderBy);
	const orderByRef = useRef<number>(null);
	const sortList = [
		{ id: 0, text: "Dari harga tertinggi" }, // 가격 높은순
		{ id: 1, text: "Dari harga terendah" }, // 가격 낮은순
		{ id: 2, text: "Dari tanggal terbaru" }, // 최신순
		{ id: 3, text: "Dari tanggal terlama" }, // 오래된순
	];
	// 보유 보물 리스트 정보
	const [upgradeDatas, setUpgradeDatas] = useState<upgradeData[]>([]);
	const [upgradeNextPage, setUpgradeNextPage] = useState<boolean>(true);
	const upgradePage = useRef<number>(1);
	const upgradeObserver = useRef<HTMLDivElement>(null);
	// 선택된 보물 정보
	const [checkedItem, setCheckedItem] = useState<upgradeData[]>([]);
	const [checkedPrice, setCheckedPrice] = useState<number>(0);
	const [checkedNewsItem, setCheckedNewsItem] = useState<upgradeData>();
	// 분해 반환 포인트율
	const decompositionRate = useRef(0.6);
	// 분해 정보 영역
	const anchorRef = useRef<HTMLDivElement>(null);
	// 보유 보물 리셋
	const upgradeReset = () => {
		setCheckedItem([]);
		setCheckedPrice(0);
		setUpgradeDatas([]);
		setUpgradeNextPage(true);
		upgradePage.current = 1;
	};
	// 보유 보물 리스트 불러오기
	const upgradeFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("upgradeFetch", orderByRef.current);
		await Api.get("/present/memberPresentList", {
			stateCode: "00",
			page: upgradePage.current,
			orderBy: orderByRef.current ?? orderBy,
			lockYn: 0,
		})
			.then((res) => {
				console.log(res);
				const data = res.data.data.list;
				setUpgradeDatas((prevDatas) => [...prevDatas, ...data]);
				setUpgradeNextPage(data.length === 10);
				if (data.length) {
					upgradePage.current += 1;
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
		setLoading(false);
	};
	useEffect(() => {
		if (!upgradeObserver.current || !upgradeNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				upgradeFetch();
			}
		});
		io.observe(upgradeObserver.current);

		return () => {
			io.disconnect();
		};
	}, [upgradeNextPage]);
	// 리셋
	useEffect(() => {
		orderByRef.current = orderBy;
		upgradeReset();
		console.log(orderBy);
	}, [orderBy]);
	// 분해 아이템 선택
	const checkItem = async (data: upgradeData, agree?: boolean) => {
		let newData = [...checkedItem];
		let newPrice = checkedPrice;
		if (newData.includes(data)) {
			// 선택되어있는 보물인 경우
			for (let i = 0; i < newData.length; i++) {
				if (newData[i] === data) {
					newData.splice(i, 1);
					i--;
				}
			}
			newPrice = newPrice - data.boxPrice;
		} else {
			// 선택되어있지 않은 보물인 경우
			// 50개 이상 선택되었는지 여부 확인
			if (newData.length >= 50) {
				// 50개 초과 선택 불가
				setToastType(1);
				setToastContents(
					"Jumlah maksimal sekali pemisahan adalah 50 buah"
				);
				setOpenToast(true);
			} else {
				if (data.displayPrice >= newsPrice && !agree) {
					// 뉴스룸 보물 선택 확인 팝업 생성
					setOpenNewsConfirm(true);
					setCheckedNewsItem(data);
				} else {
					newData.push(data);
					newPrice = newPrice + data.boxPrice;
				}
			}
		}
		setCheckedItem(newData);
		setCheckedPrice(newPrice);
	};
	// 분해 하기
	const decomposition = async () => {
		if (isLoading) return;
		setLoading(true);
		let pumIdxArr = [];
		for (const item of checkedItem) {
			pumIdxArr.push(item.pumIdx);
		}
		console.log(pumIdxArr);
		await Api.post(`/present/decomposition`, { pumIdx: pumIdxArr })
			.then((res) => {
				console.log(res.data);
				if (res.data.statusCode === 200) {
					setOpenPopup(false); // 현재 모달 닫기
					setOpenCompleteConfirm(true); // 완료 모달 열기
				}
			})
			.catch((error) => console.log(error));
		setLoading(false);
	};

	return (
		<>
			<Page
				title="Pisah Campur"
				contents={
					<TabMenu
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabs={[
							{
								index: 0,
								name: "Harta Karun",
								content: (
									<>
										{/* 정렬 */}
										<SelecArea
											onClick={() => setOpenFilter(true)}
										>
											<S.bodyText_S $ellipsis={1}>
												{sortList[orderBy].text}
											</S.bodyText_S>
											<Chevron fill={S.color.gray600} />
										</SelecArea>
										{/* 리스트 */}
										<RefreshHandler
											handleRefresh={() => upgradeReset()}
											contents={
												<S.wrapper>
													{upgradeDatas.length ===
													0 ? (
														<NoList />
													) : (
														<>
															<TreasureList>
																{upgradeDatas?.map(
																	(
																		upgradeData,
																		index
																	) => (
																		<TreasureListItem
																			onClick={() =>
																				checkItem(
																					upgradeData
																				)
																			}
																			key={
																				upgradeData.pumIdx
																			}
																			style={{
																				marginRight:
																					index %
																						2 ===
																						0 &&
																					"1rem",
																			}}
																			$checked={checkedItem.includes(
																				upgradeData
																			)}
																			$news={
																				upgradeData.displayPrice >=
																				newsPrice
																			}
																		>
																			<div className="itemImage">
																				<S.heightImg
																					src={
																						imgUrl +
																						upgradeData.imageUrl
																					}
																				/>
																			</div>
																			<div className="itemInfo">
																				<S.subhead_4
																					className="name"
																					$ellipsis={
																						2
																					}
																				>
																					{
																						upgradeData.name
																					}
																				</S.subhead_4>
																				<p>
																					<S.bodyText_S
																						color={
																							S
																								.color
																								.gray600
																						}
																					>
																						Harga
																						pas
																						&nbsp;|
																						&nbsp;
																						{Util.addComma(
																							upgradeData.displayPrice
																						)}{" "}
																						IDR
																					</S.bodyText_S>
																				</p>
																			</div>
																			<Circle className="checkCircle">
																				<Check />
																			</Circle>
																		</TreasureListItem>
																	)
																)}
															</TreasureList>
															<div
																style={{
																	height:
																		checkedItem.length ===
																		0
																			? 0
																			: "15rem",
																}}
															/>
														</>
													)}
													<div
														ref={upgradeObserver}
													/>
												</S.wrapper>
											}
										/>
										<CheckedInfoArea
											ref={anchorRef}
											className={
												checkedItem.length === 0
													? ""
													: "view"
											}
										>
											<CheckedInfo>
												<S.flexRowBetween>
													<S.bodyText_M_long color="white">
														Harta karun terpilih
													</S.bodyText_M_long>
													<S.subhead_3 color="white">
														{checkedItem.length}{" "}
														buah
													</S.subhead_3>
												</S.flexRowBetween>
												<S.flexRowBetween>
													<S.bodyText_M_long color="white">
														Poin yang akan didapat
													</S.bodyText_M_long>
													<S.subhead_3 color="white">
														{Util.addComma(
															checkedPrice *
																decompositionRate.current
														)}{" "}
														P
													</S.subhead_3>
												</S.flexRowBetween>
											</CheckedInfo>
											<button
												onClick={() =>
													setOpenPopup(true)
												}
											>
												<S.button_M color="white">
													Pemisahan
												</S.button_M>
											</button>
										</CheckedInfoArea>
									</>
								),
							},
							{
								index: 1,
								name: "status transaksi",
								content: (
									<>
										<TitleArea>
											<Ribbon>
												<S.bodyText_S color="white">
													Harta karun baru akan segera
													dimasukkan.
												</S.bodyText_S>
											</Ribbon>
											<S.subhead_3
												color={S.color.primaryVariant}
											>
												Harta karun campuran sedang
												dalam persiapan.
											</S.subhead_3>
											{false && (
												<S.bodyText_S
													color={S.color.gray500}
												>
													(Tidak bisa memilih lebih
													dari satu)
												</S.bodyText_S>
											)}
										</TitleArea>
										<SpecialArea>
											<SpecialItem
												onClick={() =>
													setOpenComposeDisable(true)
												}
											>
												<SpecialItemImage>
													<S.flexRowEnd>
														{true ? (
															<>
																<img
																	src=""
																	alt=""
																/>
																<S.subhead_4
																	color={
																		S.color
																			.error
																	}
																>
																	SOLD OUT
																</S.subhead_4>
															</>
														) : (
															<Question />
														)}
													</S.flexRowEnd>
												</SpecialItemImage>
												<SpecialItemInfo>
													{false ? (
														<>
															<S.bodyText_S>
																[CHANEL] CHANEL
																19 WOC
															</S.bodyText_S>
															<S.subhead_4
																color={
																	S.color
																		.error
																}
															>
																7,100,000P
															</S.subhead_4>
														</>
													) : (
														<>
															<div className="userProfile">
																<div>
																	<img
																		src=""
																		alt=""
																	/>
																</div>
															</div>
															<div className="userNick">
																<S.bodyText_XS>
																	test
																</S.bodyText_XS>
															</div>
														</>
													)}
												</SpecialItemInfo>
											</SpecialItem>
										</SpecialArea>
										<NormalArea>
											<S.bodyText_M_long>
												Jumlah campuran tersebut
												diupdate{"\n"}setiap hari pukul
												15.00.
											</S.bodyText_M_long>
											<NormalItem
												onClick={() => {
													// setUPGDTarger();
													setOpenUPGD(true);
												}}
											>
												<div className="itemImage">
													<S.flexRowCenter className="count">
														<S.subhead_4 color="white">
															x0
														</S.subhead_4>
													</S.flexRowCenter>
												</div>
												<S.subhead_3
													$ellipsis={1}
													className="itemName"
												>
													NormalItem
												</S.subhead_3>
												<S.subhead_3
													color={S.color.error}
												>
													56,000P
												</S.subhead_3>
											</NormalItem>
										</NormalArea>
										<Explain color={S.color.gray800}>
											위 합성 보물은 한정 수량으로
											조기품절이 될 수 있습니다. 보물
											하단에 기재된 수량을 확인하시기
											바랍니다.
										</Explain>
									</>
								),
							},
						]}
					/>
				}
			/>
			{/* 정렬 */}
			<Filter
				isOpen={isOpenFilter}
				close={() => setOpenFilter(false)}
				defaultOrderBy={defaultOrderBy}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
			/>
			{/* 뉴스룸 보물 선택 확인 */}
			<Modal
				isOpen={isOpenNewsConfirm}
				contents={
					<>
						<h2>
							<S.headline_4>
								Barang news room telah terpilih
							</S.headline_4>
						</h2>
						<S.bodyText_M_long color={S.color.gray800}>
							Apakah anda akan memilihnya?
						</S.bodyText_M_long>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => setOpenNewsConfirm(false)}
				buttonTextR="Memeriksa"
				buttonActionR={() => {
					setOpenNewsConfirm(false);
					checkItem(checkedNewsItem, true);
				}}
			/>
			{/* 분해 보물 확인 */}
			<Modal
				isOpen={isOpenPopup}
				contents={
					<>
						<h2>
							<S.headline_4>Pisah harta karun</S.headline_4>
						</h2>
						<ConfirmTreasureArea>
							<ConfirmTreasureWrap>
								{checkedItem?.map((item, index) => (
									<ConfirmTreasureImage
										key={index}
										$news={item.displayPrice >= newsPrice}
									>
										<img src={imgUrl + item.imageUrl} />
									</ConfirmTreasureImage>
								))}
							</ConfirmTreasureWrap>
							<ConfirmPriceInfo>
								<S.flexRowBetween
									style={{ marginBottom: "1.2rem" }}
								>
									<p>
										<S.bodyText_S>
											Jumlah pemisahan
										</S.bodyText_S>
									</p>
									<Arrow />
									<p>
										<S.subhead_4>
											{checkedItem.length} buah
										</S.subhead_4>
									</p>
								</S.flexRowBetween>
								<S.flexRowBetween>
									<p>
										<S.bodyText_S>
											Poin yang didapat
										</S.bodyText_S>
									</p>
									<Arrow />
									<p>
										<S.subhead_4>
											{Util.addComma(
												checkedPrice *
													decompositionRate.current
											)}{" "}
											P
										</S.subhead_4>
									</p>
								</S.flexRowBetween>
							</ConfirmPriceInfo>
						</ConfirmTreasureArea>
						<div style={{ textAlign: "center" }}>
							<S.bodyText_M_long color={S.color.gray800}>
								Harta karun yang telah dipisah tidak bisa{"\n"}
								dipulihkan kembali.{"\n"}Apakah anda akan
								melakukannya?
							</S.bodyText_M_long>
						</div>
					</>
				}
				buttonTextL={"Batal"}
				buttonActionL={() => setOpenPopup(false)}
				buttonTextR={"Pemisahan"}
				buttonActionR={() => decomposition()}
			/>
			{/* 분해 완료 */}
			<Modal
				isOpen={isOpenCompleteConfirm}
				contents={
					<>
						<h2>
							<S.headline_4>Pemisahan berhasil</S.headline_4>
						</h2>
						<CompletePoint>
							<img
								src={point}
								alt=""
								style={{
									height: "4.4rem",
									marginBottom: "0.3rem",
								}}
							/>
							<p>
								<S.mainTitle_S>
									Anda mendapat poin{" "}
								</S.mainTitle_S>
								<S.mainTitle_S color={S.color.primary}>
									{Util.addComma(
										checkedPrice * decompositionRate.current
									)}
									p!
								</S.mainTitle_S>
							</p>
						</CompletePoint>
					</>
				}
				buttonTextL="Pergi ke undian harta karun"
				buttonActionL={() =>
					navigate(
						userState.goldboxCnt > 0 ? "/BUPO001001" : "/POIN001001"
					)
				}
				buttonTextR="Memeriksa"
				buttonActionR={() => {
					setOpenCompleteConfirm(false);
					upgradeReset();
				}} // 분해 화면 리셋
				buttonColumn
			/>
			{/* 합성 불가 */}
			<Modal
				isOpen={isOpenComposeDisable}
				contents={
					<>
						<h2>
							<S.headline_4>
								Informasi tidak bisa melakukan pencampuran
							</S.headline_4>
						</h2>
						{/* <p> */}
						<S.bodyText_M_long color={S.color.gray800}>
							Harta karun tidak bisa dicampur karena telah terjual
							habis.
						</S.bodyText_M_long>
						{/* </p> */}
					</>
				}
				buttonTextL="Memeriksa"
				buttonActionL={() => setOpenComposeDisable(false)}
			/>
			{/* 합성 재료 선택 */}
			<UPGD001002 isOpen={isOpenUPGD} close={() => setOpenUPGD(false)} />
		</>
	);
};
