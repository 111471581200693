// 마이페이지
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { UserAtom, IsLoginAtom } from "../Recoil/Atoms/UserState";
import { LoginAtom } from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";
import Page from "../Components/Page";

import delivery from "../Images/icon_delivery.png";
import jewel from "../Images/icon_jewel.png";
import box from "../Images/icon_box.png";
import point from "../Images/point.png";
import defaultProfile from "../Images/default_profile.png";
import { ReactComponent as Chevron } from "../Images/chevron.svg";
import Util from "../Store/Util";

const Container = styled.div`
	padding: 2rem 1.4rem;
	h1 {
		margin-bottom: 0.8rem;
	}
`;
const UserInfoArea = styled(S.flexRowCenter)`
	padding-bottom: 1.6rem;
	margin: 1.6rem 0.8rem 1.4rem 1.6rem;
	border-bottom: 1px solid ${S.color.gray300};
	.prifile {
		align-self: stretch;
	}
`;
const UserTextArea = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	.nickname {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 0.9rem;
		margin-bottom: 0.6rem;
		span {
			margin-right: 0.6rem;
		}
	}
	.phoneNum {
		margin-bottom: 0.7rem;
	}
`;
const UserPropertyArea = styled(S.flexRowCenter)`
	margin-bottom: 1.6rem;
	& > div {
		width: calc(100% / 4);
		flex-direction: column;
		flex-grow: 1;
		position: relative;
	}
	& > div::after {
		content: "";
		display: block;
		width: 1px;
		height: 3rem;
		background-color: ${S.color.gray300};
		position: absolute;
		right: 0;
		bottom: 0.6rem;
	}
	& > div:last-child::after {
		display: none;
	}
	img {
		height: 2.4rem;
		margin-bottom: 0.8rem;
	}
`;
const CSCenter = styled.div`
	& > div {
		margin: 2.4rem 0;
	}
	svg {
		width: 1.8rem;
	}
`;

export default () => {
	const navigate = useNavigate();
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const isLogin = useRecoilValue(IsLoginAtom);
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);

	// 프로필 설정 표시될 이미지
	const profileImage = () => {
		if (userState.profileImageUrl == "") {
			return defaultProfile;
		} else {
			if (userState.profileImageUrl.includes("http")) {
				return userState.profileImageUrl;
			} else {
				return imgUrl + userState.profileImageUrl;
			}
		}
	};

	return (
		<S.wrapper>
			<Page
				title="Halamanku"
				badge={"bell"}
				badgeAction={() => navigate("/HELP005002")}
				backTo={"/"}
				contents={
					<Container>
						<h1>
							<S.subhead_2>Informasi profil</S.subhead_2>
						</h1>
						<S.borderBox>
							<UserInfoArea
								onClick={() => {
									isLogin
										? navigate("/MYME001002")
										: setOpenLogin(true);
								}}
							>
								<div className="prifile">
									<S.userProfile $url={profileImage()}>
										<label />
									</S.userProfile>
								</div>
								<UserTextArea>
									<div className="nickname">
										<S.bodyText_L>
											{isLogin
												? userState.nick
												: "Anda harus log-in."}
										</S.bodyText_L>
										{isLogin && (
											<S.bodyText_S
												color={S.color.gray600}
											>
												{userState.email}
											</S.bodyText_S>
										)}
									</div>
									{userState.hp && (
										<S.flexRowStart className="phoneNum">
											<S.bodyText_S
												color={S.color.gray800}
											>
												{userState.hp}
											</S.bodyText_S>
											<S.badge
												style={{ marginLeft: "0.6rem" }}
											>
												<S.bodyText_XS>
													sertifikasi
												</S.bodyText_XS>
											</S.badge>
										</S.flexRowStart>
									)}
								</UserTextArea>
								<Chevron fill={S.color.gray500} />
							</UserInfoArea>
							<UserPropertyArea>
								<S.flexRowCenter
									onClick={() => {
										isLogin
											? navigate("/MYME002001", {
													state: {
														data: 1,
													},
											  })
											: setOpenLogin(true);
									}}
								>
									<img src={delivery} alt="delivery" />
									<S.subhead_4 color={S.color.gray800}>
										Pengiriman
									</S.subhead_4>
									<S.bodyText_S color={S.color.primary}>
										Melihat
									</S.bodyText_S>
								</S.flexRowCenter>
								<S.flexRowCenter
									onClick={() => {
										isLogin
											? navigate("/MYME002001", {
													state: {
														data: 0,
													},
											  })
											: setOpenLogin(true);
									}}
								>
									<img src={jewel} alt="jewel" />
									<S.subhead_4 color={S.color.gray800}>
										Harta Karun{" "}
									</S.subhead_4>
									<S.bodyText_S color={S.color.primary}>
										Melihat
									</S.bodyText_S>
								</S.flexRowCenter>
								<S.flexRowCenter
									onClick={() => {
										isLogin
											? navigate("/HELP003001", {
													state: {
														data: 0,
													},
											  })
											: setOpenLogin(true);
									}}
								>
									<img src={box} alt="box" />
									<S.subhead_4 color={S.color.gray800}>
										Kotak
									</S.subhead_4>
									<S.bodyText_S color={S.color.primary}>
										{isLogin
											? Util.addComma(
													userState.goldboxCnt
											  )
											: "0"}
									</S.bodyText_S>
								</S.flexRowCenter>
								<S.flexRowCenter
									onClick={() => {
										isLogin
											? navigate("/HELP003001", {
													state: {
														data: 1,
													},
											  })
											: setOpenLogin(true);
									}}
								>
									<img src={point} alt="point" />
									<S.subhead_4 color={S.color.gray800}>
										Poin
									</S.subhead_4>
									<S.bodyText_S color={S.color.primary}>
										{isLogin
											? Util.addComma(userState.freePoint)
											: "0"}
									</S.bodyText_S>
								</S.flexRowCenter>
							</UserPropertyArea>
						</S.borderBox>
						<CSCenter>
							{/* 공지 */}
							<S.flexRowBetween
								onClick={() => navigate("/HELP001001")}
							>
								<S.subhead_3 color={S.color.gray900}>
									Pemberitahuan
								</S.subhead_3>
								<Chevron fill={S.color.gray500} />
							</S.flexRowBetween>
							{/* 이벤트 */}
							<S.flexRowBetween
								onClick={() => navigate("/HELP002001")}
							>
								<S.subhead_3 color={S.color.gray900}>
									Event
								</S.subhead_3>
								<Chevron fill={S.color.gray500} />
							</S.flexRowBetween>
							{/* 이력 */}
							{isLogin && (
								<S.flexRowBetween
									onClick={() => navigate("/HELP003001")}
								>
									<S.subhead_3 color={S.color.gray900}>
										Riwayat Penggunaan
									</S.subhead_3>
									<Chevron fill={S.color.gray500} />
								</S.flexRowBetween>
							)}
							{/* 문의 */}
							{isLogin && (
								<S.flexRowBetween
									onClick={() => navigate("/HELP004001")}
								>
									<S.subhead_3 color={S.color.gray900}>
										Rincian Pertanyaan
									</S.subhead_3>
									<Chevron fill={S.color.gray500} />
								</S.flexRowBetween>
							)}
							{/* 알림설정 */}
							<S.flexRowBetween
								onClick={() => navigate("/HELP005001")}
							>
								<S.subhead_3 color={S.color.gray900}>
									Pengaturan pemberitahuan
								</S.subhead_3>
								<Chevron fill={S.color.gray500} />
							</S.flexRowBetween>
							{/* 자주묻는질문 */}
							<S.flexRowBetween
								onClick={() => navigate("/HELP006001")}
							>
								<S.subhead_3 color={S.color.gray900}>
									FAQ
								</S.subhead_3>
								<Chevron fill={S.color.gray500} />
							</S.flexRowBetween>
							{/* 약관 */}
							<S.flexRowBetween
								onClick={() => navigate("/HELP007001")}
							>
								<S.subhead_3 color={S.color.gray900}>
									Syarat dan ketentuan
								</S.subhead_3>
								<Chevron fill={S.color.gray500} />
							</S.flexRowBetween>
						</CSCenter>
					</Container>
				}
				bottomNav
			/>
		</S.wrapper>
	);
};
