// 배송지관리
import React, { PropsWithChildren, useState, useRef, useEffect } from "react";
import Api from "../libs/api";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
import Modal from "../Components/Modal";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";

const Container = styled.div`
	padding: 2rem 1.4rem 0;
`;
const DeliveryBox = styled(S.borderBox)`
	margin-bottom: 1.4rem;
	padding: 1.6rem;
	&:last-child {
		margin-bottom: 0.4rem;
	}
`;
const DeliveyButton = styled.div`
	display: flex;
	align-items: center;
	button {
		padding: 0 0.5rem;
	}
`;
const DeliveyAddress = styled(S.flexRowStart)`
	margin-top: 1.4rem;
	padding-top: 1.2rem;
	border-top: 1px solid ${S.color.gray300};
	align-items: flex-start;
	& > div {
		margin-left: 1rem;
	}
`;

interface Data {
	mdaIdx: number;
	mmIdx: number;
	addressName: string;
	recipientName: string;
	tel: string;
	postcode: string;
	address1: string;
	address2: string;
	defaultYn: number;
	useYn: boolean;
	regIdx?: number;
	regDt?: string;
	regIp?: string;
	modIdx?: number;
	modDt?: string;
	modIp?: string;
}

export default () => {
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false); // 배송지 추가, 수정
	const [isOpenConfirm, setOpenConfirm] = useState<boolean>(false); // 배송지 삭제 확인
	const location = useLocation();
	const isDelivery = location.state.type === 1;
	// 배송지 리스트 정보
	const [deliveryDatas, setDeliveryDatas] = useState<Data[]>([]);
	const [deliveryNextPage, setDeliveryNextPage] = useState<boolean>(true);
	const deliveryPage = useRef<number>(1);
	const deliveryObserver = useRef<HTMLDivElement>(null);
	// 배송지 등록/수정 구분
	const updateType = useRef<number>();
	// 선택된 배송지 idx
	const [selectedIdx, setSelectedIdx] = useState<number>(null);

	// 배송지 목록 리셋
	const deliveryReset = () => {
		setDeliveryDatas([]);
		setDeliveryNextPage(true);
		deliveryPage.current = 0;
		// setSelectedIdx(null);
	};
	// 배송지 목록 조회
	const deliveryFetch = async () => {
		console.log("deliveryFetch");
		await Api.get(`/member/info/memberDeliveryAddress`)
			.then((res) => {
				// const data: Data[] = res.data.data;
				const data: Data[] = [
					{
						mdaIdx: 1,
						mmIdx: 1,
						addressName: "Rumah",
						recipientName: "SeptiariLisa",
						tel: "0062-18836432234",
						postcode: "string",
						address1:
							"Jalan Jenderal Gatot Subroto Kav. 57 Jakarta Selatan 12950",
						address2: "string",
						defaultYn: 1,
						useYn: true,
						regIdx: 1,
						regDt: "string",
						regIp: "string",
						modIdx: 1,
						modDt: "string",
						modIp: "string",
					},
					{
						mdaIdx: 2,
						mmIdx: 1,
						addressName: "string",
						recipientName: "string",
						tel: "string",
						postcode: "string",
						address1: "string",
						address2: "string",
						defaultYn: 1,
						useYn: true,
						regIdx: 1,
						regDt: "string",
						regIp: "string",
						modIdx: 1,
						modDt: "string",
						modIp: "string",
					},
				];
				console.log("ddd", res);
				setDeliveryDatas((prevDatas) => [...prevDatas, ...data]);
				setDeliveryNextPage(data.length === 10);
				if (data.length) {
					deliveryPage.current += 1;
				}
			})
			.catch((error) => {
				console.log(error.response.data.statusCode);
				console.log(error.response.data.message);
			});
	};
	useEffect(() => {
		if (!deliveryObserver.current || !deliveryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				deliveryFetch();
			}
		});
		io.observe(deliveryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [deliveryNextPage]);
	// 배송지 상세 조회
	const detailDeliveryAddress = async (mdaIdx) => {
		await Api.get(`/member/info/memberDeliveryAddress/` + mdaIdx)
			.then((res) => {
				const data: Data = res.data.data;
				// 기존 정보 전달
				(document.getElementById("mdaIdx") as HTMLInputElement).value =
					data.mdaIdx.toString();
				(
					document.getElementById("addressName") as HTMLInputElement
				).value = data.addressName;
				(
					document.getElementById("recipientName") as HTMLInputElement
				).value = data.recipientName;
				(document.getElementById("tel") as HTMLInputElement).value =
					data.tel;
				(
					document.getElementById("postcode") as HTMLInputElement
				).value = data.postcode;
				(
					document.getElementById("address1") as HTMLInputElement
				).value = data.address1;
				(
					document.getElementById("address2") as HTMLInputElement
				).value = data.address2;
				(
					document.getElementById("defaultYn") as HTMLInputElement
				).checked = data.defaultYn === 1;
				setOpenPopup(true);
			})
			.catch((error) => {
				console.log(error.response.data.statusCode);
				console.log(error.response.data.message);
			});
	};
	// 배송지 등록/수정
	const addressUpdate = async (e) => {
		e.preventDefault();
		const params = {
			addressName: e.target["addressName"].value,
			recipientName: e.target["recipientName"].value,
			tel: e.target["tel"].value,
			postcode: e.target["postcode"].value,
			address1: e.target["address1"].value,
			address2: e.target["address2"].value,
			defaultYn: e.target["defaultYn"].checked ? 1 : 0,
		};
		// 등록/수정 구분
		if (updateType.current == 0) {
			// 등록
			await Api.post(`/member/info/memberDeliveryAddress`, params)
				.then((res) => {
					setOpenPopup(false);
					deliveryReset();
					e.target.value = null;
				})
				.catch((error) => {
					console.log("error : ", error.response.data);
				});
		} else {
			// 수정
			await Api.patch(
				`/member/info/memberDeliveryAddress/` +
					e.target["mdaIdx"].value,
				params
			)
				.then((res) => {
					setOpenPopup(false);
					deliveryReset();
					e.target.value = null;
				})
				.catch((error) => {
					console.log("error : ", error.response.data);
				});
		}
	};
	// 배송지 삭제
	const deleteIdx = useRef<number>(null);
	const deleteDeliveryAddress = async () => {
		// await Api.delete(
		// 	`/member/info/memberDeliveryAddress/` + deleteIdx.current
		// )
		// 	.then((res) => {
		// 		deliveryReset();
		// 	})
		// 	.catch((error) => {
		// 		console.log("error : ", error.response.data);
		// 	});
	};

	return (
		<>
			<Page
				title="Pengaturan alamat pengiriman"
				contents={
					<RefreshHandler
						handleRefresh={() => deliveryReset()}
						contents={
							<S.wrapper>
								{deliveryDatas.length === 0 ? (
									<NoList small />
								) : (
									<Container>
										{deliveryDatas?.map((data, index) => (
											<DeliveryBox key={data.mdaIdx}>
												<S.flexRowBetween>
													<S.controlsWrap>
														<S.radio
															defaultChecked={
																// data.defaultYn === 1
																selectedIdx ==
																data.mdaIdx
															}
															id={
																"delivery" +
																data.mdaIdx
															}
															name="delivery"
															type="radio"
															onChange={() =>
																setSelectedIdx(
																	data.mdaIdx
																)
															}
														/>
														<label
															htmlFor={
																"delivery" +
																data.mdaIdx
															}
														>
															<S.bodyText_M>
																{
																	data.addressName
																}
															</S.bodyText_M>
														</label>
														{data.defaultYn ===
															1 && (
															<S.badge
																style={{
																	marginLeft:
																		"0.8rem",
																}}
															>
																<S.bodyText_XS>
																	alamat utama
																</S.bodyText_XS>
															</S.badge>
														)}
													</S.controlsWrap>
													<DeliveyButton>
														<button
															onClick={() => {
																updateType.current = 1;
																detailDeliveryAddress(
																	data.mdaIdx
																);
															}}
														>
															<S.bodyText_XS
																color={
																	S.color
																		.gray600
																}
															>
																Retouch
															</S.bodyText_XS>
														</button>
														<S.bodyText_S
															color={
																S.color.gray300
															}
														>
															{" "}
															|{" "}
														</S.bodyText_S>
														<button
															onClick={() => {
																deleteIdx.current =
																	data.mdaIdx;
																setOpenConfirm(
																	true
																);
															}}
														>
															<S.bodyText_XS
																color={
																	S.color
																		.gray600
																}
															>
																Delete
															</S.bodyText_XS>
														</button>
													</DeliveyButton>
												</S.flexRowBetween>
												<DeliveyAddress>
													<div>
														<S.subhead_4
															color={
																S.color.gray600
															}
														>
															Alamat
														</S.subhead_4>
													</div>
													<div>
														<S.bodyText_S>
															{data.address1}{" "}
															{data.address2}
														</S.bodyText_S>
														<p
															style={{
																marginTop:
																	"0.8rem",
															}}
														>
															<S.bodyText_S
																color={
																	S.color
																		.gray800
																}
															>
																{
																	data.recipientName
																}{" "}
																/ {data.tel}
															</S.bodyText_S>
														</p>
													</div>
												</DeliveyAddress>
											</DeliveryBox>
										))}
									</Container>
								)}
								<div style={{ margin: "1.6rem 1.4rem 1rem" }}>
									<S.buttonPrimary_B
										onClick={() => {
											updateType.current = 0;
											setOpenPopup(true);
										}}
									>
										<S.button_S>
											Tambahkan alamat pengiriman
										</S.button_S>
									</S.buttonPrimary_B>
								</div>
								<div ref={deliveryObserver} />
							</S.wrapper>
						}
					/>
				}
				button={"Memeriksa"}
				buttonAction={() => console.log("제출")}
				disabled={selectedIdx === null}
				absolute={isDelivery}
			/>
			{/* 배송지 추가, 수정 */}
			<form onSubmit={addressUpdate}>
				<Modal
					isOpen={isOpenPopup}
					close={() => setOpenPopup(false)}
					contents={
						<S.pageContainer>
							<h2>
								<S.headline_4>
									{updateType.current == 0
										? "Masukkan alamat pengiriman"
										: "Revisi alamat pengiriman"}
								</S.headline_4>
							</h2>
							<h2>
								<S.subhead_4>
									Nama alamat pengiriman
								</S.subhead_4>{" "}
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="addressName"
										name="addressName"
										placeholder="Masukkan nama alamat pengiriman"
									></input>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Penerima</S.subhead_4>
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="recipientName"
										name="recipientName"
										placeholder="Masukkan peneriman"
									></input>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Kontak</S.subhead_4>
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="tel"
										name="tel"
										placeholder="0062-18836432234"
									></input>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Alamat</S.subhead_4>
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<div className="inputArea">
								<S.inputField>
									<div className="inputWrap">
										<input
											id="postcode"
											name="postcode"
											placeholder="Masukkan alamat"
										></input>
									</div>
									<button>
										<S.button_XS>Cari alamat</S.button_XS>
									</button>
								</S.inputField>
								<S.inputField>
									<div className="inputWrap">
										<input
											id="address1"
											name="address1"
											placeholder="Masukkan alamat"
										></input>
									</div>
								</S.inputField>
								<S.inputField>
									<div className="inputWrap">
										<input
											id="address2"
											name="address2"
											placeholder="Masukkan alamat"
										></input>
									</div>
								</S.inputField>
							</div>
							<S.controlsWrap>
								<S.checkbox
									id="defaultYn"
									name="defaultYn"
									type="checkbox"
								></S.checkbox>
								<label htmlFor="defaultYn">
									<S.bodyText_S>
										Pilih sebagai alamat utama
									</S.bodyText_S>
								</label>
							</S.controlsWrap>
							<input id="mdaIdx" name="mdaIdx" type="hidden" />
						</S.pageContainer>
					}
					buttonTextL={true ? "Masukkan" : "Memeriksa"}
					buttonActionL={() => {}}
				/>
			</form>
			{/* 배송지 삭제 */}
			<Modal
				isOpen={isOpenConfirm}
				contents={
					<>
						<h2>
							<S.headline_4>Hapus alamat pengiriman</S.headline_4>
						</h2>
						<S.bodyText_M_long color={S.color.gray800}>
							Alamat pengiriman yang telah dihapus tidak bisa
							dipulihkan kembali. Anda yakin akan menghapusnya?
						</S.bodyText_M_long>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => setOpenConfirm(false)}
				buttonTextR="Memeriksa"
				buttonActionR={() => deleteDeliveryAddress()}
			/>
		</>
	);
};
