// 알림내역
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { LoadingAtom, UserAtom } from "../Recoil/Atoms/UserState";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";

const NotifyItem = styled.div`
	margin: 1.4rem 1.4rem 0;
	padding: 1.6rem;
	border: 1px solid ${S.color.gray300};
	border-radius: 1rem;
	&:last-child {
		margin-bottom: 2rem;
	}
	h1 {
		margin: 0.4rem 0 0.8rem;
	}
	.imageArea {
		height: 6.8rem;
		border: 1px solid ${S.color.gray200};
		margin-top: 0.8rem;
		border-radius: 0.3rem;
		overflow: hidden;
		position: relative;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;

export default () => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 슬라이드 메뉴
	const slideMenuData = [
		{ value: 70, text: "Jual beli" },
		{ value: 71, text: "Transaksi selesai" },
		{ value: 72, text: "Pembelian selesai" },
		{ value: 72, text: "Pembelian selesai" },
		{ value: 72, text: "Pembelian selesai" },
	];
	// 활성화 슬라이드 메뉴
	const [slideMenuValue, setSlideMenuValue] = useState<number>(
		slideMenuData[0].value
	);
	// 알림 센터 내역 리스트 정보
	const [historyDatas, setHistoryDatas] = useState<[]>([]);
	const alertHistoryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("alertHistoryFetch");
		await Api.get(``, {})
			.then((res) => console.log(res))
			.catch((error) => console.log(error));
		setLoading(false);
	};

	return (
		<Page
			title="Riwayat pemberitahuan"
			contents={
				<S.wrapper>
					{/* 슬라이드 메뉴 */}
					<S.slideMenuWrap>
						<S.slideMenu>
							{slideMenuData?.map((data, idx) => (
								<S.slideMenuItem
									key={idx}
									value={idx}
									onClick={() => {
										if (isLoading) return;
										setSlideMenuValue(data.value);
									}}
									$active={slideMenuValue === data.value}
								>
									<button>
										<S.bodyText_XS>
											{data.text}
										</S.bodyText_XS>
									</button>
								</S.slideMenuItem>
							))}
						</S.slideMenu>
					</S.slideMenuWrap>
					<S.bodyText_S
						color={S.color.gray600}
						style={{ margin: "1.4rem 1.4rem 0" }}
					>
						Itu disimpan selama 7 hari terakhir dan dihapus secara
						otomatis setelahnya.
					</S.bodyText_S>
					{historyDatas.length === 0 ? (
						<NoList />
					) : (
						historyDatas?.map((data, index) => (
							<NotifyItem key={index}>
								<p>
									<S.bodyText_XS color={S.color.primary}>
										Menggunakan
									</S.bodyText_XS>
								</p>
								<h1>
									<S.subhead_4>
										Jawaban telah dibuat untuk pertanyaan
										Anda!
									</S.subhead_4>
								</h1>
								<p>
									<S.bodyText_S color={S.color.gray800}>
										Pembaruan acara baru untuk bulan
										November selesai! Nikmati perjalanan
										kapal harta karun yang menyenangkan.
									</S.bodyText_S>
								</p>
								{/* TODO: 이미지 있으면 영역 생성 */}
								{true && (
									<div className="imageArea">
										<img src="" alt="" />
									</div>
								)}
								<S.flexColEnd style={{ marginTop: "1.2rem" }}>
									<S.bodyText_XS color={S.color.gray400}>
										Sebelum
									</S.bodyText_XS>
								</S.flexColEnd>
							</NotifyItem>
						))
					)}
				</S.wrapper>
			}
		/>
	);
};
