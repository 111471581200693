// 문의 내역
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";

import search from "../Images/search.png";

const InquiryItem = styled.div`
	width: 100%;
	padding: 1.6rem 1.4rem 0.5rem;
	border-bottom: 1px solid ${S.color.gray200};
`;
const InquiryInfo = styled.div`
	display: flex;
	margin-bottom: 1rem;
	.number {
		min-width: 3rem;
		color: ${S.color.gray700};
		background-color: ${S.color.gray200};
		border-radius: 1rem;
		margin-right: 0.8rem;
		padding: 0 0.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

interface inquiryData {
	bqIdx: number;
	mmIdx: number;
	name: string;
	email: string;
	categoryCode: string;
	progressCode: string;
	questionSubject: string;
	questionText: string;
	answerText: string;
	tel: string;
	answerDt: string;
	useYn: number;
	displayYn: number;
	mailSendYn: number;
	privacyAgreeYn: number;
	regIdx: number;
	regIp: string;
	regDt: string;
	modIdx: number;
	modIp: string;
	modDt: string;
	file1: string;
	file2: string;
	file3: string;
}

export default () => {
	const navigate = useNavigate();
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 문의 내역 리스트 정보
	const [inquiryDatas, setInquiryDatas] = useState<inquiryData[]>([]);
	const [inquiryNextPage, setInquiryNextPage] = useState<boolean>(true);
	const inquiryPage = useRef<number>(1);
	const inquiryObserver = useRef<HTMLDivElement>(null);
	// 검색
	const [filter, setFilter] = useState<string>(null);
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");
	const options = [
		{ id: 1, text: "Menggunakan" }, // 이용 문의
		{ id: 2, text: "Pembayaran" }, // 결제 문의
		{ id: 4, text: "Pengiriman" }, // 배송 문의
		{ id: 7, text: "Anggota terendah" }, // 회원 문의
		{ id: 8, text: "Peristiwa" }, // 이벤트 문의
		{ id: 99, text: "Laporan kesalahan" }, // 오류제보
		{ id: 6, text: "dll" }, // 기타
	];
	// 문의 내역 리셋
	const inquiryReset = () => {
		setInquiryDatas([]);
		setInquiryNextPage(true);
		inquiryPage.current = 1;
	};
	// 문의 내역 리스트 불러오기
	const inquiryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("inquiryFetch");
		searchYnRef.current = false;
		await Api.get(`/board/qnaList`, {
			page: inquiryPage.current,
			keyword: keywordRef.current == "" ? undefined : keywordRef.current,
			// filter: filter, // (제목 + 내용), 제목, 내용
		})
			.then((res) => {
				const data = res.data.data.list;
				setInquiryDatas((prevDatas) => [...prevDatas, ...data]);
				setInquiryNextPage(data.length === 10);
				if (data.length) {
					inquiryPage.current += 1;
				}
				setLoading(false);
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};
	useEffect(() => {
		if (!inquiryObserver.current || !inquiryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				inquiryFetch();
			}
		});
		io.observe(inquiryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [inquiryNextPage]);

	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), inquiryReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		inquiryReset();
	};
	// 답변 상태 확인
	const progressState = (progressCode) => {
		switch (progressCode) {
			case "1": // 답변 완료
				return (
					<S.subhead_4 fontSize="1rem" color={S.color.gray700}>
						Jawaban selesai
					</S.subhead_4>
				);
			case "2": // 답변 중
				return (
					<S.subhead_4 fontSize="1rem" color={S.color.primary}>
						Membalas
					</S.subhead_4>
				);
			case "0": // 답변 전
				return (
					<S.subhead_4 fontSize="1rem" color={S.color.gray700}>
						Sebelum membalas
					</S.subhead_4>
				);
		}
	};
	// 상담분류 텍스트
	const categoryText = (categoryCode: string) => {
		return options.filter((item) => item.id === Number(categoryCode))[0]
			.text;
	};

	return (
		<>
			<Page
				title="Rincian Pertanyaan"
				backTo={"/MYME001001"}
				contents={
					<>
						{/* 검색 */}
						<S.searchArea>
							<div className="searchBox">
								<input
									id="keyword"
									type="text"
									placeholder="kata pencarian"
								/>
								<S.searchButton onClick={() => searchAction()}>
									<img src={search} />
								</S.searchButton>
							</div>
						</S.searchArea>
						{/* 리스트 */}
						<RefreshHandler
							handleRefresh={() => inquiryReset()}
							contents={
								<S.wrapper>
									{inquiryDatas.length === 0 ? (
										<NoList searchKeyword={keyword} />
									) : (
										inquiryDatas?.map(
											(inquiryData, index) => (
												<S.helpItem
													key={index}
													onClick={() =>
														navigate(
															"/HELP004003",
															{
																state: {
																	data: inquiryData,
																},
															}
														)
													}
												>
													<div>
														<S.subhead_4
															$ellipsis={1}
															color={
																S.color.gray500
															}
															style={{
																marginBottom:
																	"0.8rem",
															}}
														>
															{categoryText(
																inquiryData.categoryCode
															)}
														</S.subhead_4>
														<S.subhead_3
															$ellipsis={1}
															style={{
																marginBottom:
																	"0.8rem",
															}}
														>
															{
																inquiryData.questionSubject
															}
														</S.subhead_3>
														<S.flexRowStart>
															<S.bodyText_XS
																color={
																	S.color
																		.gray500
																}
																style={{
																	marginRight:
																		"0.8rem",
																}}
															>
																{Util.dateFormat(
																	inquiryData.regDt
																)}
															</S.bodyText_XS>
															{progressState(
																inquiryData.progressCode
															)}
														</S.flexRowStart>
													</div>
												</S.helpItem>
											)
										)
									)}
									<div ref={inquiryObserver} />
								</S.wrapper>
							}
						/>
					</>
				}
				button={"Tulis pertanyaan baru"} // TODO: 문의하기 번역 필요
				buttonAction={() => navigate("/HELP004002")} // 문의 작성 화면 이동
				absolute={true}
			/>
		</>
	);
};
