import { useState, useEffect } from "react";
import styled from "styled-components";
import React, { PropsWithChildren } from "react";
import { useRecoilValue, useRecoilState } from "recoil";

import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";

import { ReactComponent as Check } from "../Images/check.svg";
import { ReactComponent as Exclamation } from "../Images/exclamation.svg";

const ToastArea = styled(S.flexRowStart)<{ $isOpen: boolean }>`
	width: calc(100% - 2.4rem);
	position: absolute;
	top: ${(props) => (props.$isOpen ? "2rem" : "1rem")};
	left: 50%;
	transform: translateX(-50%);
	padding: 1.2rem 1.4rem;
	color: white;
	background-color: ${S.color.gray900};
	visibility: ${(props) => (props.$isOpen ? "visible" : "hidden")};
	transition: visibility 150ms linear, opacity 100ms linear, top 50ms linear;
	border-radius: 1rem;
	align-items: flex-start;
	span {
		line-height: 2.2rem;
	}
`;
const Circle = styled(S.flexRowCenter)`
	width: 2.2rem;
	height: 2.2rem;
	background-color: ${S.color.primaryVariant};
	border-radius: 50%;
	margin-right: 0.8rem;
	flex-shrink: 0;
	svg {
		/* width: 70%; */
		height: 70%;
		fill: white;
		stroke: white;
	}
`;

export default () => {
	const [isOpenToast, setOpenToast] = useRecoilState<boolean>(ToastAtom);
	const toastType = useRecoilValue<number>(ToastTypeAtom);
	const toastContents = useRecoilValue<string>(ToastContentsAtom);

	useEffect(() => {
		const timer = setTimeout(() => {
			setOpenToast(false);
		}, 1500);
		return () => clearTimeout(timer);
	}, [isOpenToast, toastContents]);

	return (
		<ToastArea $isOpen={isOpenToast}>
			<Circle>{toastType === 0 ? <Check /> : <Exclamation />}</Circle>
			{/* 내용 */}
			<p>
				<S.bodyText_M color="white">{toastContents}</S.bodyText_M>
			</p>
		</ToastArea>
	);
};
