// 보물상자 구매
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UserAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";

import chestGold from "../Images/chest_gold.png";
import point from "../Images/point.png";
import polygon from "../Images/polygon.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import { ReactComponent as Chevron } from "../Images/chevron.svg";

const PaymentItem = styled(S.flexRowBetween)`
	margin-bottom: 0.6rem;
	img {
		width: 2.2rem;
		height: 2.2rem;
		margin-left: 0.4rem;
	}
`;
const BoxSelectArea = styled.div`
	width: 100%;
	display: flex;
	.boxArea {
		width: 10rem;
		height: 10rem;
		border: 1px solid ${S.color.gray300};
		border-radius: 1rem;
		margin-right: 1.6rem;
		flex-shrink: 0;
		background-color: white;
	}
	img {
		width: 70%;
	}
	.countArea {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
	.boxExplain {
		width: 100%;
		flex-grow: 1;
		background-color: white;
		padding: 1.4rem 1.4rem 1rem;
		border-radius: 1rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
		p {
			padding: 0.2rem 0;
			background-color: ${S.color.primary};
			border: 1px solid #f0ba8a;
			box-shadow: 0 0.2rem 0.1rem #e87c1c;
			border-radius: 0.6rem;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		&::after {
			content: "";
			display: block;
			width: 1rem;
			height: 2rem;
			position: absolute;
			left: -0.8rem;
			top: 50%;
			transform: translateY(-50%);
			background-image: url(${polygon});
			background-size: contain;
			background-repeat: no-repeat;
		}
	}
`;
const BoxCountArea = styled.div`
	margin: 1rem 0;
	border-bottom: 1px solid ${S.color.primary};
	display: flex;
	justify-content: space-between;
	padding: 1rem 0;
	input {
		width: 100%;
		font-size: 1.6rem;
		line-height: 2.4rem;
	}
	button {
		width: 2.2rem;
		height: 2.2rem;
		background-color: ${S.color.gray400};
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
`;
const BoxCountButtonArea = styled(S.flexRowBetween)`
	button {
		padding: 0.8rem 0;
		margin-right: 0.5rem;
		border-radius: 2rem;
		border: 1px solid ${S.color.primary};
		flex-grow: 1;
		span {
			color: ${S.color.primary};
		}
	}
	button:last-child {
		margin-right: 0;
	}
`;
const PaymentArea = styled(S.inputList)`
	margin-top: 1.6rem;
	flex-wrap: wrap;
	flex-direction: row;
	li {
		width: 50%;
	}
`;
// 상자 정보 팝업
const BoxInfoPopup = styled(S.popupContainer)`
	justify-content: flex-start;
	padding-top: 4.4rem;
`;

export default () => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 팝업
	const setOpenToast = useSetRecoilState(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isOpenBoxInfo, setOpenBoxInfo] = useState<boolean>(false);
	// 결제 방식
	const [paymentType, setPaymentType] = useState<number>(0);
	// 결제 안내 아코디언
	const [viewInfo, setViewInfo] = useState<boolean>(false);
	// 선택된 상자 종류
	const selectedBoxRef = useRef<number>(0);
	const [selectedBox, setSelectedBox] = useState<number>(0);
	// 선택된 상자 개수
	const [selectedBoxCount, setSelectedBoxCount] = useState<number>(0);
	// 사용할 포인트
	const [pointToUse, setPointToUse] = useState<string>("");
	// 결제할 금액
	const [totalPrice, setTotalPrice] = useState<number>(0);

	// 최종 결제 금액 업데이트
	useEffect(() => {
		// 선택된 상자 가격
		const boxPrice = selectedBox == 0 ? 50000 : 5000;
		setTotalPrice(selectedBoxCount * boxPrice);
	}, [selectedBoxCount, selectedBox]);
	useEffect(() => {
		setPointToUse("");
	}, [paymentType]);

	// 상자 개수 선택
	const addBoxCount = (count: number) => {
		setPointToUse("");
		if (selectedBoxCount + count >= 50) {
			setSelectedBoxCount(50);
		} else if (selectedBoxCount <= 50) {
			setSelectedBoxCount(selectedBoxCount + count);
		}
	};
	// 포인트 전체 사용 선택
	const usePointAll = () => {
		if (selectedBoxCount <= 0) {
			setPointToUse("");
		} else if (userState.freePoint > totalPrice) {
			// 보유 포인트 > 구매 금액
			setPointToUse(Util.addComma(totalPrice));
		} else if (userState.freePoint < totalPrice) {
			// 보유 포인트 < 구매 금액
			setPointToUse(Util.addComma(userState.freePoint));
		} else {
			setPointToUse("");
		}
	};
	// 사용할 포인트 입력
	const usePointInput = (e) => {
		if (selectedBoxCount <= 0) {
			e.target.value = "";
		} else {
			if (
				Util.toNumber(e.target.value) >= totalPrice &&
				userState.freePoint >= totalPrice
			) {
				setPointToUse(Util.addComma(totalPrice));
			} else if (Util.toNumber(e.target.value) >= userState.freePoint) {
				setPointToUse(Util.addComma(userState.freePoint));
			} else {
				setPointToUse(Util.onChangeComma(e.target.value));
			}
		}
	};
	// 상자 구매
	const buyBox = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log(selectedBoxCount);
		await Api.post("/box/buy", {
			bmIdx: 2,
			bmCnt: selectedBoxCount,
			paymentType: 1,
		})
			.then((res) => {
				console.log(res);
				setToastType(0);
				setToastContents("Pembelian kotak harta karun berhasil.");
				setOpenToast(true);
				navigate("/BUPO001001");
			})
			.catch((error) => console.log(error.response.data));
		setLoading(false);
	};

	return (
		<S.wrapper>
			<Page
				title="Beli kotak harta karun"
				badge="information"
				badgeAction={() => setOpenBoxInfo(true)}
				contents={
					<S.pageContainer>
						<section>
							<BoxSelectArea>
								<S.flexRowCenter className="boxArea">
									<img src={chestGold} />
								</S.flexRowCenter>
								<div className="countArea">
									<S.flexRowStart
										style={{ marginBottom: "0.6rem" }}
									>
										<S.subhead_2
											style={{ marginRight: "0.8rem" }}
										>
											Gold Box
										</S.subhead_2>
										<S.bodyText_L>50,000 IDR</S.bodyText_L>
									</S.flexRowStart>
									<S.bodyText_XS color={S.color.gray600}>
										Anda bisa membeli maksimal 50 kotak
										harta karun.
									</S.bodyText_XS>
									<BoxCountArea>
										<input
											type="number"
											placeholder="0"
											value={selectedBoxCount}
											readOnly
										/>
										<button
											onClick={() => {
												setSelectedBoxCount(0);
												setPointToUse("");
											}}
										>
											<XIcon fill="white" width="80%" />
										</button>
									</BoxCountArea>
									<BoxCountButtonArea>
										<button onClick={() => addBoxCount(1)}>
											<S.subhead_4>+ 1</S.subhead_4>
										</button>
										<button onClick={() => addBoxCount(5)}>
											<S.subhead_4>+ 5</S.subhead_4>
										</button>
										<button onClick={() => addBoxCount(10)}>
											<S.subhead_4>+ 10</S.subhead_4>
										</button>
										<button onClick={() => addBoxCount(50)}>
											<S.subhead_4>Maksimal</S.subhead_4>
										</button>
									</BoxCountButtonArea>
								</div>
							</BoxSelectArea>
						</section>
						<section>
							<h1>
								<S.subhead_2>
									Pembayaran dengan poin
								</S.subhead_2>
							</h1>
							<h2>
								<S.controlsWrap
									style={{ alignItems: "center" }}
								>
									<S.radio
										id="paymentType1"
										name="paymentType"
										type="radio"
										disabled={selectedBoxCount < 1}
										defaultChecked
										checked={paymentType == 0}
										onChange={(e) => setPaymentType(0)}
									></S.radio>
									<label htmlFor="paymentType1">
										<S.subhead_2 color={S.color.gray800}>
											Poin
										</S.subhead_2>
									</label>
								</S.controlsWrap>
							</h2>
							{paymentType === 0 && (
								<>
									<PaymentItem>
										<S.subhead_3 color={S.color.gray800}>
											Yang anda miliki
										</S.subhead_3>
										<S.flexRowCenter>
											<S.subhead_2>
												{Util.addComma(
													userState.freePoint
												)}
											</S.subhead_2>
											<img src={point} />
										</S.flexRowCenter>
									</PaymentItem>
									<p style={{ marginBottom: "0.6rem" }}>
										<S.subhead_3 color={S.color.gray800}>
											Gunakan
										</S.subhead_3>
									</p>
									<S.inputField>
										<div className="inputWrap">
											<input
												type="text"
												placeholder="0"
												value={pointToUse}
												readOnly
												onChange={(e) =>
													usePointInput(e)
												}
											/>
										</div>
										<button onClick={() => usePointAll()}>
											<S.button_XS>
												Gunakan semua
											</S.button_XS>
										</button>
									</S.inputField>
								</>
							)}{" "}
							<S.bar />
							<h2>
								<S.controlsWrap
									style={{ alignItems: "center" }}
								>
									<S.radio
										id="paymentType2"
										name="paymentType"
										type="radio"
										disabled={selectedBoxCount < 1}
										checked={paymentType == 1}
										onChange={(e) => setPaymentType(1)}
									></S.radio>
									<label htmlFor="paymentType2">
										<S.subhead_2 color={S.color.gray800}>
											Cara pembayaran
										</S.subhead_2>
									</label>
								</S.controlsWrap>
							</h2>
							{paymentType === 1 && (
								<PaymentArea>
									<li>
										<S.controlsWrap>
											<S.radio
												id="payment01"
												name="payment"
												type="radio"
												defaultChecked
												// 포인트로 전체 결제 시 비활성화
												disabled={
													totalPrice ==
													Util.toNumber(pointToUse)
												}
											></S.radio>
											<label htmlFor="payment01">
												<S.bodyText_M
													color={S.color.gray800}
												>
													Kartu kredit
												</S.bodyText_M>
											</label>
										</S.controlsWrap>
									</li>
									<li>
										<S.controlsWrap>
											<S.radio
												id="payment02"
												name="payment"
												type="radio"
												// 포인트로 전체 결제 시 비활성화
												disabled={
													totalPrice ==
													Util.toNumber(pointToUse)
												}
											></S.radio>
											<label htmlFor="payment02">
												<S.bodyText_M
													color={S.color.gray800}
												>
													Internet banking
												</S.bodyText_M>
											</label>
										</S.controlsWrap>
									</li>
									<li>
										<S.controlsWrap className="paymentItem">
											<S.radio
												id="payment03"
												name="payment"
												type="radio"
												// 포인트로 전체 결제 시 비활성화
												disabled={
													totalPrice ==
													Util.toNumber(pointToUse)
												}
											></S.radio>
											<label htmlFor="payment03">
												<S.bodyText_M
													color={S.color.gray800}
												>
													Rekening virtual
												</S.bodyText_M>
											</label>
										</S.controlsWrap>
									</li>
								</PaymentArea>
							)}
						</section>
						<section>
							<S.flexRowBetween>
								<S.subhead_2 color={S.color.gray800}>
									{paymentType === 1 ? "Jumlah" : "Poin"} yang
									akan diharapkan
								</S.subhead_2>
								<S.subhead_2 color={S.color.primary}>
									{Util.addComma(
										totalPrice - Util.toNumber(pointToUse)
									)}{" "}
									{paymentType === 1 ? "IDR" : "P"}
								</S.subhead_2>
							</S.flexRowBetween>
							<S.expandedExplain style={{ marginBottom: 0 }}>
								<div
									onClick={() => {
										setViewInfo(true);
									}}
								>
									<header>
										<S.subhead_4>
											Informasi Pembukaan
										</S.subhead_4>
										<Chevron fill={S.color.gray400} />
									</header>
								</div>
							</S.expandedExplain>
						</section>
					</S.pageContainer>
				}
				button={"Setuju dan bayar."}
				buttonAction={() => buyBox()}
				disabled={
					totalPrice == 0 ||
					(paymentType === 0 &&
						totalPrice - Util.toNumber(pointToUse) !== 0)
				}
			/>
			{/* 결재 안내 팝업 */}
			<Modal
				isOpen={viewInfo}
				close={() => setViewInfo(false)}
				contents={
					<>
						<h2>
							<S.headline_4>Informasi Pembukaan</S.headline_4>
						</h2>
						<div style={{ marginBottom: "1.2rem" }}>
							<S.bodyText_M_long color={S.color.gray800}>
								Anda akan mendapatkan satu barang secara acak
								bila membuka random box yang anda beli. Bila
								anda mendapatkan barang setelah membuka kotak
								harta karun, anda tidak bisa membatalkan
								pembayaran karena terhitung sudah menggunakan
								kotak harta karun.
							</S.bodyText_M_long>
						</div>
						<p>
							<S.subhead_3 color={S.color.gray800}>
								[Informasi Pembatalan Pembayaran]
							</S.subhead_3>
						</p>
						<S.textList $gray style={{ marginBottom: "1.2rem" }}>
							<li>
								<S.bodyText_S>
									Pembatalan pembayaran hanya meliputi kotak
									harta karun yang tidak dipakai dalam waktu 7
									hari sejak tanggal pembayaran. Pembatalan
									pembayaran bisa dilakukan melalui pertanyaan
									1:1.
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Bila anda mendapatkan barang setelah membuka
									kotak harta karun, anda tidak bisa
									membatalkan pembayaran karena terhitung
									sudah menggunakan kotak harta karun.
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Setelah dilakukan pembatalan pembayaran,
									pengembalian dana yang sebenarnya bisa
									memakan waktu sekitar 2-5 hari kerja
									tergantu pada metode pembayaran.
								</S.bodyText_S>
							</li>
						</S.textList>
						<p>
							<S.subhead_3 color={S.color.gray800}>
								[Informasi Pengiriman/Pengembalian]
							</S.subhead_3>
						</p>
						<S.textList $gray>
							<li>
								<S.bodyText_S>
									Bila menginginkan pengiriman barang yang
									didapat dari kotak harta karun, anda harus
									mengajukan pengiriman di harta karun saya.
									(Biaya pengiriman 3,000P, Jeju/daerah pulau
									6,000P)
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Anda bisa membatalkan pengiriman di status
									pengiriman bila harta karun saya berstatus
								</S.bodyText_S>
							</li>
						</S.textList>
					</>
				}
			/>
			{/* 상자 정보 팝업 */}
			<BoxInfoPopup
				$isOpen={isOpenBoxInfo}
				onClick={() => setOpenBoxInfo(false)}
			>
				<div style={{ margin: "2rem 0", width: "calc(100% - 2.8rem)" }}>
					<BoxSelectArea onClick={(e) => e.stopPropagation()}>
						<S.flexRowCenter className="boxArea">
							<img src={chestGold} />
						</S.flexRowCenter>
						<div className="boxExplain">
							<p>
								<S.mainTitle_M color="white">
									Gold box
								</S.mainTitle_M>
							</p>
							<S.bodyText_S>
								Anda bisa mendapatkan harta karun setara 50,000
								IDR lebih{" "}
							</S.bodyText_S>
						</div>
					</BoxSelectArea>
				</div>
			</BoxInfoPopup>
		</S.wrapper>
	);
};
