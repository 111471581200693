import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";

import * as S from "../Styles/Style";

import defaultProfile from "../Images/default_profile.png";
import { ReactComponent as XIcon } from "../Images/x.svg";

const PopupWrapper = styled.div`
	width: calc(100% - 2.8rem);
	max-height: 100vh;
	position: relative;
	background-color: white;
	border-radius: 1rem;
	overflow: hidden;
	h1 {
		margin: 2rem 2rem 1.6rem;
	}
`;
const LargeImage = styled.img`
	width: 100%;
`;

interface DefaultType {
	isOpen: boolean;
	close: () => void;
	$url?: string;
}

export default ({ isOpen, close, $url }: PropsWithChildren<DefaultType>) => {
	const imgUrl = process.env.REACT_APP_IMG_URL;
	return (
		<S.popupContainer $isOpen={isOpen} onClick={() => close()}>
			<PopupWrapper>
				<h1>
					<S.headline_4>Memperbesar gambar</S.headline_4>
				</h1>
				<S.popupXIcon onClick={() => close()}>
					<XIcon fill={S.color.gray700} />
				</S.popupXIcon>
				<LargeImage src={$url ? imgUrl + $url : defaultProfile} />
			</PopupWrapper>
		</S.popupContainer>
	);
};
