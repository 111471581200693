import React, { useState } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";

const apiUrl = process.env.REACT_APP_API_URL;
// const apiUrl = "http://172.30.1.70:3001";

const get = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	// const token = JSON.stringify({
	// 	accessToken:
	// 		"ya29.a0AcM612xJ0IKjA4MbVAMwy4FWaMBT26VMVA_udErutUkrgZwgILBjH2t64fwqz-10nfwwQFir-V2fEtbyKm9Ql1D4B_YwwXHUKPquFF1kF2HH8A-VZHQxACIhLWSzvSlHiduiZJJGr3YOrFrw5-kP5qdPOjV-2rwDNzC-HDVCaCgYKARcSARASFQHGX2MiPSCRLoqul6tJmaFStSPWrg0175",
	// 	refreshToken: undefined,
	// });
	let result = null;
	try {
		result = await axios.get(apiUrl + url, {
			params: params,
			headers: { Authorization: token },
		});
	} catch (e) {
		result = e;
	}
	return result;
};
const post = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.post(apiUrl + url, params, {
			headers: { Authorization: token },
		});
	} catch (e) {
		result = e;
	}
	return result;
};
const patch = async (url: string, params?: object) => {
	const token = localStorage.getItem("token");
	let result = null;
	try {
		result = await axios.patch(apiUrl + url, params, {
			headers: {
				Authorization: token,
				"Content-Type": `application/json;charset=UTF-8`,
				Accept: "application/json",
				// 추가
				"Access-Control-Allow-Origin": [
					`https://api.service.go-pang.com`,
					"https://service.go-pang.com",
				],
				"Access-Control-Allow-Credentials": true,
			},
			// headers: { Authorization: token },
			// headers: {
			// 	Authorization: token,
			// 	"Content-Type": "multipart/form-data",
			// },
		});
	} catch (e) {
		result = e;
	}
	return result;
};
export default { get, post, patch };
