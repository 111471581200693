// 약관동의
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import Api from "../libs/api";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";

import logo from "../Images/logo.svg";

const ContentsWrap = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const Logo = styled.img`
	width: 24rem;
	height: 7.4rem;
`;
const CheckArea = styled.div`
	width: 92%;
	margin-top: 2rem;
	padding-left: 1.4rem;
	div {
		margin-bottom: 2rem;
	}
	div:last-child {
		margin-bottom: 0;
	}
`;
const CheckAll = styled.button`
	width: 92%;
	height: 5rem;
	border: 1px solid ${S.color.gray400};
	border-radius: 1rem;
	margin-top: 7.2rem;
	padding-left: 1.4rem;
	&.checked {
		border-color: ${S.color.primary};
	}
`;

export default () => {
	const [isOpenPopup, setOpenPopup] = useState<boolean>(false);
	const navigate = useNavigate();
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	// 체크박스 아이템
	const data = [
		{
			id: "userAgreeYn",
			text: "Menyetujui syarat dan ketentuan",
			essential: true, // 필수값 여부
			emphasis: false, // 강조 여부
		},
		{
			id: "financialAgreeYn",
			text: "Menyetujui kebijakan privasi",
			essential: true,
			emphasis: false,
		},
		{
			id: "mailSendYn",
			text: "Menyetujui pengiriman email dan SMS marketing ",
			essential: false,
			emphasis: false,
		},
		{
			id: "ageOver",
			text: "Hanya pelanggan berusia 14 tahun ke atas yang bisa mendaftar. Apa anda berusia 14 tahun keatas?",
			essential: true,
			emphasis: true,
		},
	];
	// 체크된 아이템을 담을 배열
	const [checkItems, setCheckItems] = useState<string[]>([]);
	const [clickedItems, setClickedItems] = useState<number>(0);

	// 체크박스 단일 선택
	const handleSingleCheck = (checked, id) => {
		if (checked) {
			// 단일 선택 시 체크된 아이템을 배열에 추가
			setCheckItems((prev) => [...prev, id]);
		} else {
			// 단일 선택 해제 시 체크된 아이템을 제외한 배열 (필터)
			setCheckItems(checkItems.filter((el) => el !== id));
		}
	};
	// 체크박스 전체 선택
	const handleAllCheck = (checked) => {
		if (checked) {
			// 전체 선택 클릭 시 데이터의 모든 아이템(id)를 담은 배열로 checkItems 상태 업데이트
			const idArray = [];
			data.forEach((el) => idArray.push(el.id));
			setCheckItems(idArray);
		} else {
			// 전체 선택 해제 시 checkItems 를 빈 배열로 상태 업데이트
			setCheckItems([]);
		}
	};

	// 약관 팝업 오픈
	const checkTerms = (i) => {
		setClickedItems(i);
		setOpenPopup(true);
	};

	// 필수값 선택 완료 여부
	const checkEssential = () => {
		const essentialList = data.filter((item) => item.essential == true);
		let result = false;
		// 필수값 중 선택되어있지 않은게 있으면 disabled true
		essentialList.map((item) => {
			if (!checkItems.includes(item.id)) {
				result = true;
			}
		});
		return result;
	};
	// 동의 버튼 클릭
	const authAgree = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.patch("/auth/authAgree", {
			userAgreeYn: checkItems.includes("userAgreeYn") ? 1 : 0,
			financialAgreeYn: checkItems.includes("financialAgreeYn") ? 1 : 0,
			mailSendYn: checkItems.includes("mailSendYn") ? 1 : 0,
		})
			.then((res) => {
				console.log(res);
				if (res.data.statusCode == 200) {
					navigate("/");
				} else {
					console.log("에러");
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
		setLoading(false);
	};

	return (
		<Page
			title="Syarat dan Ketentuan"
			contents={
				<ContentsWrap>
					<Logo src={logo} />
					<CheckAll
						className={
							checkItems.length == data.length && "checked"
						}
					>
						<S.controlsWrap
							style={{
								alignItems: "center",
							}}
						>
							<S.checkbox
								size="L"
								type="checkbox"
								id={"all"}
								onChange={(e) =>
									handleAllCheck(e.target.checked)
								}
								checked={checkItems.length == data.length}
							/>
							<label htmlFor={"all"}>
								<S.bodyText_M>Persetujuan penuh</S.bodyText_M>
							</label>
						</S.controlsWrap>
					</CheckAll>
					<CheckArea>
						{data?.map((data, index) => (
							<S.controlsWrap key={index}>
								<S.checkbox
									type="checkbox"
									id={data.id}
									name={"agree"}
									onChange={(e) =>
										handleSingleCheck(
											e.target.checked,
											data.id
										)
									}
									checked={checkItems.includes(data.id)}
								/>
								{data.emphasis ? (
									<label htmlFor={data.id}>
										<S.subhead_4 color={S.color.gray800}>
											{data.text}{" "}
											{data.essential
												? "(Wajib)"
												: "(Pilihan)"}
										</S.subhead_4>
										{data.essential && (
											<S.bodyText_M color={S.color.error}>
												*
											</S.bodyText_M>
										)}
									</label>
								) : (
									<p onClick={() => checkTerms(index)}>
										<S.bodyText_S color={S.color.gray700}>
											{data.text}{" "}
										</S.bodyText_S>
										<S.subhead_4 color={S.color.gray700}>
											{data.essential
												? "(Wajib)"
												: "(Pilihan)"}
										</S.subhead_4>
										{data.essential && (
											<S.bodyText_M color={S.color.error}>
												*
											</S.bodyText_M>
										)}
									</p>
								)}
							</S.controlsWrap>
						))}
					</CheckArea>
					{/* 약관 */}
					<Modal
						isOpen={isOpenPopup}
						contents={
							<>
								<h2>
									<S.headline_4>
										{data[clickedItems].text}
									</S.headline_4>
								</h2>
								<p>
									<S.bodyText_M_long color={S.color.gray800}>
										Menyetujui syarat dan ketentuan
									</S.bodyText_M_long>
								</p>
							</>
						}
						close={() => setOpenPopup(false)}
						buttonTextL="Simpan"
						buttonActionL={() => {
							!checkItems.includes(data[clickedItems].id) &&
								setCheckItems((prev) => [
									...prev,
									data[clickedItems].id,
								]);
							setOpenPopup(false);
						}}
					/>
				</ContentsWrap>
			}
			button="Pendaftaran selesai"
			disabled={checkEssential() ?? true}
			buttonAction={() => authAgree()}
		/>
	);
};
