import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";

import xWhite from "../Images/x_white.png";

const Container = styled.div`
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	min-height: calc(100% - 6rem);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Contents = styled.div`
	flex-grow: 1;
`;
const ButtonWrap = styled.div`
	padding: 1.4rem;
`;

interface DefaultType {
	isOpen: boolean;
	close: () => void;
	title: string;
	contents: React.ReactNode;
	button?: string;
	buttonAction?: () => void;
}

export default ({
	isOpen,
	close,
	title,
	contents,
	button,
	buttonAction,
}: PropsWithChildren<DefaultType>) => {
	const navigate = useNavigate();

	return (
		<S.popupContainer $isOpen={isOpen} style={{ backgroundColor: "white" }}>
			<S.page>
				<S.pageTitle>
					<S.mainTitle_M color="white">{title}</S.mainTitle_M>
					<button className="close" onClick={() => close()}>
						<img src={xWhite} />
					</button>
				</S.pageTitle>
				<Container>
					<Contents>{contents}</Contents>
					{button && (
						<ButtonWrap>
							<S.buttonPrimary
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									close();
									buttonAction && buttonAction();
								}}
							>
								{button}
							</S.buttonPrimary>
						</ButtonWrap>
					)}
				</Container>
			</S.page>
		</S.popupContainer>
	);
};
