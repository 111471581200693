// 페이지 탬플릿
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
import BottomNav from "../Components/BottomNav";

import chevronLeft from "../Images/chevron_left.png";
import { ReactComponent as InfoIcon } from "../Images/info.svg";
import { ReactComponent as Bell } from "../Images/bell.svg";

const Container = styled.div`
	width: 100%;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const Contents = styled.div`
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-direction: column;
`;
const ButtonWrap = styled.div`
	padding: 0 1.4rem 1.4rem;
`;
const AbsoluteButtonWrap = styled.div`
	width: 100%;
	padding: 1.4rem;
	position: absolute;
	bottom: 0;
	background: linear-gradient(transparent 0%, white 20%);
`;

interface DefaultType {
	title: string;
	badge?: string;
	badgeAction?: () => void;
	backTo?: string;
	contents: React.ReactNode;
	button?: string;
	buttonAction?: () => void;
	disabled?: boolean;
	absolute?: boolean;
	bottomNav?: boolean;
}

export default ({
	title,
	badge,
	badgeAction,
	backTo,
	contents,
	button,
	buttonAction,
	disabled,
	absolute,
	bottomNav,
}: PropsWithChildren<DefaultType>) => {
	const navigate = useNavigate();

	const badgeButton = () => {
		if (badge == "information") {
			return (
				<button className="information" onClick={() => badgeAction()}>
					<InfoIcon fill="white" width="2.4rem" />
				</button>
			);
		} else if (badge == "bell") {
			return (
				<button className="information" onClick={() => badgeAction()}>
					<Bell fill="white" width="2.4rem" />
				</button>
			);
		} else if (badge !== undefined && badge !== "information") {
			return (
				<button className="badge" onClick={() => badgeAction()}>
					<S.subhead_4>{badge}</S.subhead_4>
				</button>
			);
		}
	};

	return (
		<S.page>
			<S.pageTitle>
				<button
					className="goBack"
					onClick={() => {
						backTo !== undefined ? navigate(backTo) : navigate(-1);
					}}
				>
					<img src={chevronLeft} />
				</button>
				<S.mainTitle_M color="white">{title}</S.mainTitle_M>
				{badgeButton()}
			</S.pageTitle>
			<Container
				style={{
					paddingBottom: absolute && "6.4rem",
					marginBottom: bottomNav && "5.5rem",
				}}
			>
				<Contents>{contents}</Contents>
				{button !== "" && button !== undefined && !absolute && (
					<ButtonWrap>
						<S.buttonPrimary
							onClick={() => buttonAction && buttonAction()}
							disabled={disabled}
						>
							{button}
						</S.buttonPrimary>
					</ButtonWrap>
				)}
			</Container>
			{absolute && (
				<AbsoluteButtonWrap>
					<S.buttonPrimary
						onClick={() => buttonAction && buttonAction()}
						disabled={disabled}
					>
						{button}
					</S.buttonPrimary>
				</AbsoluteButtonWrap>
			)}

			{bottomNav && <BottomNav />}
		</S.page>
	);
};
