import React from "react";
import Api from "./libs/api";
import { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import {
	useRecoilState,
	useSetRecoilState,
	useRecoilValue,
	useResetRecoilState,
} from "recoil";
import {
	IsLoginAtom,
	UserAtom,
	LoadingAtom,
	NickChangeCount,
} from "./Recoil/Atoms/UserState";
import {
	LoginAtom,
	SplashAtom,
	AlertSettingAtom,
	AlertSettingContentsAtom,
	ImageLargeViewAtom,
	ImageLargeViewUrlAtom,
	SetProfileAtom,
	TRSRAtom,
	TRSRIdxAtom,
} from "./Recoil/Atoms/PopupState";

import * as S from "./Styles/Style";
import Modal from "./Components/Modal";
import Toast from "./Components/Toast";
import AlertSetting from "./Components/AlertSetting";
import SetProfile from "./Components/SetProfile";
import ImageLargeView from "./Components/ImageLargeView";

import LOAD001000 from "./Screens/LOAD001000";
import MAIN001001 from "./Screens/MAIN001001";
import NOTI001001 from "./Screens/NOTI001001";
import MEMB001001 from "./Screens/MEMB001001";
import MEMB002001 from "./Screens/MEMB002001";
import MEMB003001 from "./Screens/MEMB003001";
import BUPO001001 from "./Screens/BUPO001001";
import BUPO001002 from "./Screens/BUPO001002";
import BUPO001003 from "./Screens/BUPO001003";
import POIN001001 from "./Screens/POIN001001";
import NEWS001001 from "./Screens/NEWS001001";
import EXCH001001 from "./Screens/EXCH001001";
import DLVR001001 from "./Screens/DLVR001001";
import DLVR002001 from "./Screens/DLVR002001";
import DLVR003001 from "./Screens/DLVR003001";
import MYME001001 from "./Screens/MYME001001";
import MYME001002 from "./Screens/MYME001002";
import HELP005001 from "./Screens/HELP005001";
import HELP005002 from "./Screens/HELP005002";
import MYME002001 from "./Screens/MYME002001";
import TRSR001001 from "./Screens/TRSR001001";
import TRSR001002 from "./Screens/TRSR001002";
import UPGD001001 from "./Screens/UPGD001001";
import HELP001001 from "./Screens/HELP001001";
import HELP002001 from "./Screens/HELP002001";
import HELP006001 from "./Screens/HELP006001";
import HELP004001 from "./Screens/HELP004001";
import HELP004002 from "./Screens/HELP004002";
import HELP004003 from "./Screens/HELP004003";
import HELP003001 from "./Screens/HELP003001";
import HELP007001 from "./Screens/HELP007001";

const App = () => {
	// 유저 정보
	const setIsLogin = useSetRecoilState(IsLoginAtom);
	const [userState, setUserState] = useRecoilState(UserAtom);
	const resetUserState = useResetRecoilState(UserAtom);
	const setNickChangeCount = useSetRecoilState(NickChangeCount);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);

	const [isOpenTRSR, setOpenTRSR] = useRecoilState(TRSRAtom);
	const TRSRIdx = useRecoilValue(TRSRIdxAtom);
	const [isOpenSetProfile, setOpenSetProfile] =
		useRecoilState<boolean>(SetProfileAtom); // 최초 프로필 설정 모달
	const [nick, setNick] = useState<string>("");
	const [profile, setProfile] = useState<string | ArrayBuffer>("");
	const [isOpenImageLargeView, setOpenImageLargeView] =
		useRecoilState<boolean>(ImageLargeViewAtom); // 이미지 크게보기
	const imageLargeViewUrl = useRecoilValue(ImageLargeViewUrlAtom);
	const [isOpenUpdate, setOpenUpdate] = useState<boolean>(false); // 업데이트 안내
	const [isOpenOverlap, setOpenOverlap] = useState<boolean>(false); // 중복 로그인
	const [isOpenAlertSetting, setOpenAlertSetting] =
		useRecoilState<boolean>(AlertSettingAtom);
	const alertSettingContents = useRecoilValue(AlertSettingContentsAtom);
	const [isOpenLogin, setOpenLogin] = useRecoilState<boolean>(LoginAtom);
	const navigate = useNavigate();
	const isOpenSplash = useRecoilValue(SplashAtom);
	// 회원정보 조회
	const memberInfoSelect = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.get("/member/info/memberInfoSelect")
			.then((res) => {
				console.log("memberInfoSelect", res);
				if (res.data == undefined) {
					setIsLogin(false);
					resetUserState();
				} else {
					const data = res.data.data;
					// 약관 미동의 회원
					if (data.member.stateCode == "01") {
					} else {
						setIsLogin(true);
						setUserState(data.member);
						setNickChangeCount(data.nickChangeCount);
						setNick(data.member.nick); // 최초 프로필 설정 모달 내 기본값
						setProfile(data.member.profileImageUrl);
						if (data.member.stateCode == "02") {
							// 최초로그인 닉네임 설정 팝업 생성
							setOpenSetProfile(true);
						}
					}
				}
			})
			.catch((error) => {
				// console.log(error.response.data);
			});
		setLoading(false);
	};
	useEffect(() => {
		memberInfoSelect();
		// const { ReactNativeWebView } = window; // web 으로 접속 시 null, WebView로 접속 시에만 있음
		// if (ReactNativeWebView) {
		// 	ReactNativeWebView.postMessage(JSON.stringify({ type: "test" }));
		// }
	}, [isOpenSetProfile, navigate]);
	// TODO: 업데이트 중 팝업 생성 여부
	// setOpenToast(true);

	return (
		<>
			<Routes>
				{/* Main */}
				<Route path="/" element={<MAIN001001 />} />
				{/* 점검 */}
				<Route path="/NOTI001001" element={<NOTI001001 />} />
				{/* 로그인 */}
				<Route path="/MEMB001001" element={<MEMB001001 />} />
				{/* 약관동의 */}
				<Route path="/MEMB002001" element={<MEMB002001 />} />
				{/* 회원탈퇴 */}
				<Route path="/MEMB003001" element={<MEMB003001 />} />
				{/* 보물상자 오픈 */}
				<Route path="/BUPO001001" element={<BUPO001001 />} />
				{/* 보물상자 오픈 애니메이션 */}
				<Route path="/BUPO001002" element={<BUPO001002 />} />
				{/* 보물상자 오픈 결과 */}
				<Route path="/BUPO001003" element={<BUPO001003 />} />
				{/* 보물상자 구매 */}
				<Route path="/POIN001001" element={<POIN001001 />} />
				{/* 뉴스룸 */}
				<Route path="/NEWS001001" element={<NEWS001001 />} />
				{/* 거래소 */}
				<Route path="/EXCH001001" element={<EXCH001001 />} />
				{/* 배송요청 */}
				<Route path="/DLVR001001" element={<DLVR001001 />} />
				{/* 상세정보 */}
				<Route path="/DLVR002001" element={<DLVR002001 />} />
				{/* 배송지관리 */}
				<Route path="/DLVR003001" element={<DLVR003001 />} />
				{/* 마이페이지 */}
				<Route path="/MYME001001" element={<MYME001001 />} />
				{/* 내 정보 관리 */}
				<Route path="/MYME001002" element={<MYME001002 />} />
				{/* 내보물함 */}
				<Route path="/MYME002001" element={<MYME002001 />} />
				{/* 보물 */}
				<Route path="/TRSR001001" element={<TRSR001001 />} />
				{/* 분해하기 */}
				<Route path="/UPGD001001" element={<UPGD001001 />} />
				{/* 공지사항 */}
				<Route path="/HELP001001" element={<HELP001001 />} />
				{/* 이벤트 */}
				<Route path="/HELP002001" element={<HELP002001 />} />
				{/* 이용 내역 */}
				<Route path="/HELP003001" element={<HELP003001 />} />
				{/* 문의 내역 */}
				<Route path="/HELP004001" element={<HELP004001 />} />
				{/* 문의하기 */}
				<Route path="/HELP004002" element={<HELP004002 />} />
				{/* 나의 문의 */}
				<Route path="/HELP004003" element={<HELP004003 />} />
				{/* 알림설정 */}
				<Route path="/HELP005001" element={<HELP005001 />} />
				{/* 알림내역 */}
				<Route path="/HELP005002" element={<HELP005002 />} />
				{/* 자주 묻는 질문 */}
				<Route path="/HELP006001" element={<HELP006001 />} />
				{/* 약관 */}
				<Route path="/HELP007001" element={<HELP007001 />} />
				{/* Not found */}
				<Route path="*" element={<NOTI001001 />} />
			</Routes>

			<TRSR001002 />
			{/* 이미지 크게보기 */}
			<ImageLargeView
				isOpen={isOpenImageLargeView}
				close={() => setOpenImageLargeView(false)}
				$url={imageLargeViewUrl}
			/>
			{/* 최초 프로필 설정 */}
			<SetProfile
				isOpen={isOpenSetProfile}
				close={() => setOpenSetProfile(false)}
			/>
			<Toast />
			{/* 푸시동의 */}
			<AlertSetting
				isOpen={isOpenAlertSetting}
				close={() => setOpenAlertSetting(false)}
				contents={alertSettingContents}
			/>
			{/* Splash */}
			{isOpenSplash ? <LOAD001000 /> : null}
			{/* 업데이트 중 강제 종료하지 마십시오. */}
			<Modal
				isOpen={isOpenUpdate}
				contents={
					<>
						<h2>
							<S.headline_4>
								Dalam proses update, jangan{"\n"}matikan
								aplikasi secara paksa.
							</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Aplikasi akan dimulai kembali setelah proses
								selesai.
							</S.bodyText_M_long>
						</p>
						<p>
							<S.bodyText_S color={S.color.error}>
								Anda tidak bisa mengakses update dan layanan
								yang lancar apabila mematikan aplikasi secara
								paksa.
							</S.bodyText_S>
						</p>
					</>
				}
				icon={"rotate"}
			/>
			{/* 로그인 안내 */}
			<Modal
				isOpen={isOpenLogin}
				contents={
					<>
						<h2>
							<S.headline_4>Anda harus log-in.</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Anda harus log-in untuk mengakses layanan ini.
								Akses kembali setelah log-in.
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL={"Kembali"}
				buttonActionL={() => setOpenLogin(false)}
				buttonTextR={"Log-in"}
				buttonActionR={() => {
					setOpenLogin(false);
					navigate("/MEMB001001");
				}}
			/>
			{/* 중복 로그인 */}
			<Modal
				isOpen={isOpenOverlap}
				contents={
					<>
						<h2>
							<S.headline_4>
								Pemberitahuan Log-in Ganda
							</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Anda telah log-in di perangkat lain. Apakah anda
								akan log-in di perangkat ini setelah log out
								paksa di perangkat lain?
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => console.log("취소")}
				buttonTextR="Memeriksa"
				buttonActionR={() => console.log("로그인")}
			/>
		</>
	);
};

export default App;
