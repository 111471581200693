import { atom } from "recoil";

const ToastAtom = atom({
	key: "isOpenToast",
	default: false,
});
const ToastTypeAtom = atom({
	key: "toastType",
	default: 0,
});
const ToastContentsAtom = atom({
	key: "toastContents",
	default: "",
});
// 알림 설정 토스트
const AlertSettingAtom = atom({
	key: "isOpenAlertSetting",
	default: false,
});
const AlertSettingContentsAtom = atom({
	key: "AlertSettingContents",
	default: { agree: false, type: 0 },
});
// 스플래시
const SplashAtom = atom({
	key: "isOpenSplash",
	default: true,
});
// 필터
const FilterAtom = atom({
	key: "isOpenFilter",
	default: false,
});
// 로그인 안내
const LoginAtom = atom({
	key: "isOpenLogin",
	default: false,
});
// 최초 닉네임 설정
const SetProfileAtom = atom({
	key: "isOpenSetProfile",
	default: false,
});
// 이미지 크게보기
const ImageLargeViewAtom = atom({
	key: "isOpenImageLargeView",
	default: false,
});
const ImageLargeViewUrlAtom = atom({
	key: "imageLargeViewUrl",
	default: "",
});
// 보물 상세정보
const TRSRAtom = atom({
	key: "isOpenTRSR",
	default: false,
});
const TRSRIdxAtom = atom({
	key: "TRSRIdx",
	default: null,
});

export {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
	AlertSettingAtom,
	AlertSettingContentsAtom,
	SplashAtom,
	FilterAtom,
	LoginAtom,
	SetProfileAtom,
	ImageLargeViewAtom,
	ImageLargeViewUrlAtom,
	TRSRAtom,
	TRSRIdxAtom,
};
