// 보물
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import { FilterAtom, TRSRAtom, TRSRIdxAtom } from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";
import Filter from "../Components/Filter";

import search from "../Images/search.png";
import sliders from "../Images/sliders.png";
import chestGoldS from "../Images/chest_gold_s.png";

interface treasureData {
	pmIdx: number;
	name: string;
	gradeCode: string;
	categoryCode: string;
	typeCode: string;
	registType: string;
	imageUrl: string;
	memo: string;
	displayPrice: number;
	realPrice: number;
	addPoint: number;
	orderNo: number;
	expiryTime: number;
	notifyYn: number;
	useYn: number;
	displayYn: number;
	regIdx: number;
	regIp: string;
	regDt: string;
	modIdx: number;
	modIp: string;
	modDt: string;
	brandCode: string;
	presentOptions: string;
	buyUrl: string;
	presentCount: number;
	linkUrl: string;
}

export default () => {
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 필터 모달 생성
	const [isOpenFilter, setOpenFilter] = useRecoilState<boolean>(FilterAtom);
	// 보물상세정보 팝업 생성
	const setOpenTRSR = useSetRecoilState<boolean>(TRSRAtom);
	const SetTRSRIdx = useSetRecoilState<number>(TRSRIdxAtom);
	// 보물 리스트 정보
	const [treasureDatas, setTreasureDatas] = useState<treasureData[]>([]);
	const [treasureNextPage, setTreasureNextPage] = useState<boolean>(true);
	const treasurePage = useRef<number>(1);
	const treasureObserver = useRef<HTMLDivElement>(null);
	// 검색
	const [filter, setFilter] = useState<string[]>([]);
	const filterRef = useRef<string[]>([]);
	const defaultOrderBy = 0;
	const [orderBy, setOrderBy] = useState<number>(defaultOrderBy);
	const orderByRef = useRef<number>(null);
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");

	// 보물 리셋
	const treasureReset = () => {
		setTreasureDatas([]);
		setTreasureNextPage(true);
		treasurePage.current = 1;
	};
	// 보물 리스트 불러오기
	const treasureFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("treasureFetch", filterRef.current, orderByRef.current);
		searchYnRef.current = false;
		await Api.get("/present/list", {
			page: treasurePage.current,
			filter:
				filterRef.current.length > 0 ? filterRef.current.join() : null,
			orderBy: orderByRef.current ?? orderBy,
			keyword: keywordRef.current === "" ? undefined : keywordRef.current,
		})
			.then((res) => {
				if (res.data == undefined) {
					console.log(res);
				} else {
					const data = res.data.data.list;
					console.log(data);
					setTreasureDatas((prevDatas) => [...prevDatas, ...data]);
					setTreasureNextPage(data.length === 10);
					if (data.length) {
						treasurePage.current += 1;
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
		setLoading(false);
	};
	useEffect(() => {
		if (!treasureObserver.current || !treasureNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				treasureFetch();
			}
		});
		io.observe(treasureObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [treasureNextPage]);
	// 리셋
	useEffect(() => {
		filterRef.current = filter;
		orderByRef.current = orderBy;
		treasureReset();
		console.log(orderBy, filter);
	}, [orderBy, filter]);
	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), treasureReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		treasureReset();
	};
	return (
		<>
			<Page
				title="Harta Karun"
				contents={
					<S.wrapper>
						{/* 검색 */}
						<S.searchArea>
							<div className="searchBox">
								<input
									id="keyword"
									type="text"
									placeholder="kata pencarian"
								/>
								<S.searchButton onClick={() => searchAction()}>
									<img src={search} alt="" />
								</S.searchButton>
							</div>
							<S.searchFilter
								onClick={() => {
									if (isLoading) return;
									setOpenFilter(true);
								}}
							>
								<img src={sliders} alt="" />
								{filter.length > 0 && (
									<p>
										<S.bodyText_XS color="white">
											{filter.length}
										</S.bodyText_XS>
									</p>
								)}
							</S.searchFilter>
						</S.searchArea>
						{/* 리스트 */}
						<RefreshHandler
							handleRefresh={() => treasureReset()}
							contents={
								<S.wrapper>
									{treasureDatas.length === 0 ? (
										<NoList searchKeyword={keyword} />
									) : (
										<S.treasureList>
											{treasureDatas?.map(
												(treasureData, index) => (
													<S.treasureListItem
														onClick={() => {
															setOpenTRSR(true);
															SetTRSRIdx(
																treasureData.pmIdx
															);
														}}
														key={treasureData.pmIdx}
														style={{
															marginRight:
																index % 2 ===
																	0 && "1rem",
														}}
													>
														<div className="itemImage">
															<S.heightImg
																src={
																	imgUrl +
																	treasureData.imageUrl
																}
															/>
															<div className="treasureChest">
																{/* TODO: 획득 기준에 따른 분류 필요 */}
																<img
																	src={
																		chestGoldS
																	}
																	alt=""
																/>
															</div>
														</div>
														<S.bodyText_S
															className="name"
															$ellipsis={2}
														>
															{treasureData.name}
														</S.bodyText_S>
														<p>
															<S.bodyText_XS
																color={
																	S.color
																		.gray500
																}
															>
																Harga pas |{" "}
																{Util.addComma(
																	treasureData.displayPrice
																)}{" "}
																IDR
															</S.bodyText_XS>
														</p>
													</S.treasureListItem>
												)
											)}
										</S.treasureList>
									)}
									<div ref={treasureObserver} />
								</S.wrapper>
							}
						/>
					</S.wrapper>
				}
				bottomNav={true}
			/>
			<Filter
				isOpen={isOpenFilter}
				close={() => setOpenFilter(false)}
				filter={filter}
				setFilter={setFilter}
				defaultOrderBy={defaultOrderBy}
				orderBy={orderBy}
				setOrderBy={setOrderBy}
			/>
		</>
	);
};
