// 회원탈퇴
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";

const ExplainList = styled.ul`
	& > li {
		display: flex;
		margin-bottom: 1.2rem;
	}
	& > li:last-child {
		margin-bottom: 0rem;
	}
	.number {
		width: 2rem;
		height: 2rem;
		flex-shrink: 0;
		display: flex;
		justify-content: center;
	}
`;
const DetailItem = styled(S.textList)`
	margin-top: 0.8rem;
`;

export default () => {
	const navigate = useNavigate();
	// 탈퇴 완료
	const [isOpenAlert, setOpenAlert] = useState(false);
	// 동의 여부
	const [agree, setAgree] = useState<boolean>(false);
	// 탈퇴사유
	const [reason, setReason] = useState<number>();
	// 기타 사유 작성
	const [reasonText, setReasonText] = useState<string>("");
	// 탈퇴사유 리스트
	const radioList = [
		{ id: 0, text: "Saya khawatir informasi pribadi akan bocor." },
		{ id: 1, text: "Tidak ada kepercayaan pada layanan ini." },
		{ id: 2, text: "Tidak ada produk yang saya inginkan." },
		{
			id: 3,
			text: "Penukaran, pengembalian, dan pengembalian uang tidak nyaman.",
		},
		{ id: 4, text: "Acara dan manfaatnya tidak cukup." },
		{ id: 5, text: "Tidak nyaman menggunakan aplikasi ini." },
		{ id: 6, text: "Frekuensi kunjungannya rendah." },
		{ id: 7, text: "dll." },
	];
	const withDrawMember = async () => {
		await Api.patch("/auth/withDrawMember")
			.then((res) => {
				console.log(res);
				if (res.status == 200) {
					localStorage.removeItem("token");
					setOpenAlert(true);
				}
			})
			.catch((error) => console.log(error));
		//
	};

	return (
		<>
			<Page
				title="Hapus akun"
				contents={
					<S.pageContainer>
						<section>
							<h1>
								<S.subhead_2>
									Pastikan anda telah membaca informasi{`\n`}
									sebelum menghapus akun anda.
								</S.subhead_2>
							</h1>
							<p>
								<S.bodyText_M_long>
									Anda dan orang lain{" "}
									<S.bodyText_M_long color={S.color.error}>
										tidak bisa menggunakan kembali atau
										memulihkan kembali
									</S.bodyText_M_long>{" "}
									akun yang anda gunakan (akun anda) bila anda
									menghapusnya. Pikirkan dengan seksama.
								</S.bodyText_M_long>
							</p>
							<ExplainList>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											1.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Bila anda menghapus akun anda, anda
											tidak bisa menggunakan layanan
											GOPANG dengan akun anda yang telah
											dihapus.
										</S.bodyText_M_long>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											2.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Poin dan barang yang anda miliki
											akan hangus dan tidak bisa
											dipulihkan bila anda menghapus akun
											anda.
										</S.bodyText_M_long>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											3.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Informasi anggota dan riwayat
											penggunaan personal semuanya akan
											dihapus.
										</S.bodyText_M_long>
										<DetailItem $gray $small>
											<li>
												<S.bodyText_S>
													Informasi anggota : Hapus
													informasi nickname, email,
													nomor HP, alamat anggota
												</S.bodyText_S>
											</li>
											<li>
												<S.bodyText_S>
													Tempat transaksi : Hapus
													barang yang dijual, barang
													yang dibeli, status
													transaksi
												</S.bodyText_S>
											</li>
											<li>
												<S.bodyText_S>
													News Room : Hapus barang
													yang didapat, komentar,
													balasan
												</S.bodyText_S>
											</li>
										</DetailItem>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											4.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Riwayat pembayaran, riwayat
											pemisahan disimpan selama 5 tahun.
										</S.bodyText_M_long>
									</div>
								</li>
								<li>
									<p className="number">
										<S.bodyText_M_long>
											5.
										</S.bodyText_M_long>
									</p>
									<div>
										<S.bodyText_M_long>
											Anda tidak bisa mendaftar kembali
											menggunakan akun sama yang telah
											dihapus selama 60 hari.
										</S.bodyText_M_long>
									</div>
								</li>
							</ExplainList>
							<S.bar />
							<S.controlsWrap>
								<S.checkbox
									id="agree"
									size="L"
									type="checkbox"
									checked={agree}
									onChange={() => setAgree(!agree)}
								></S.checkbox>
								<label htmlFor="agree">
									<S.bodyText_M_long>
										Saya telah membaca dan menyetujui semua
										ketentuan di atas.
									</S.bodyText_M_long>
								</label>
							</S.controlsWrap>
						</section>
						<section>
							<h1>
								<S.subhead_2>
									Alasan penarikan dari layanan{" "}
									<S.subhead_2 color={S.color.error}>
										(wajib)
									</S.subhead_2>
								</S.subhead_2>
							</h1>
							<S.inputList>
								{radioList.map((item) => {
									return (
										<li key={item.id}>
											<S.controlsWrap>
												<S.radio
													id={"reason" + item.id}
													type="radio"
													name="reason"
													checked={reason == item.id}
													onChange={() =>
														setReason(item.id)
													}
												/>
												<label
													htmlFor={"reason" + item.id}
												>
													<S.bodyText_M
														color={S.color.gray800}
													>
														{item.text}
													</S.bodyText_M>
												</label>
											</S.controlsWrap>
										</li>
									);
								})}
							</S.inputList>
							<S.inputField
								style={{ marginTop: "0.8rem" }}
								$disabled={reason !== 7}
							>
								<div className="inputWrap">
									<textarea
										name=""
										id=""
										placeholder="Masukkan dalam 100 karakter."
										cols={30}
										rows={5}
										value={reasonText}
										onChange={(e) => {
											setReasonText(e.target.value);
										}}
										maxLength={100}
										disabled={reason !== 7}
									></textarea>
									<S.inputCount className="inTextArea">
										<S.bodyText_XS>
											{reasonText.length}/100
										</S.bodyText_XS>
									</S.inputCount>
								</div>
							</S.inputField>
						</section>
					</S.pageContainer>
				}
				button="Hapus akun"
				buttonAction={() => withDrawMember()}
				disabled={!agree || reason == undefined}
			/>
			<Modal
				isOpen={isOpenAlert}
				contents={
					<>
						<h2>
							<S.headline_4>Akun telah dihapus.</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Terima kasih telah menggunakan GOPANG.
							</S.bodyText_M_long>
						</p>
					</>
				}
				buttonTextL="Memeriksa"
				buttonActionL={() => {
					navigate("/");
				}}
			/>
		</>
	);
};
