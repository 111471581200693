import React, { PropsWithChildren, useEffect, useState } from "react";
import styled from "styled-components";

import * as S from "../Styles/Style";

import rotate from "../Images/rotate.png";
import { ReactComponent as XIcon } from "../Images/x.svg";

const FilterWrapper = styled(S.popupContainer)`
	justify-content: flex-end;
	& > div {
		width: 100%;
		border-radius: 2rem 2rem 0 0;
		padding-bottom: 0.8rem;
	}
	section {
		padding-bottom: 1rem;
		border-bottom: 1px solid ${S.color.gray300};
	}
	section:last-child {
		padding-bottom: 0;
		border-bottom: 0;
	}
`;
const FilterArea = styled.ul`
	display: flex;
	flex-wrap: wrap;
	li {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
	input {
		display: none;
	}
	label {
		padding: 0.7rem 1.4rem;
		border: 1px solid ${S.color.gray300};
		border-radius: 3rem;
	}
	input:checked + label {
		border-color: ${S.color.primary};
		span {
			color: ${S.color.primary} !important;
		}
	}
`;

interface DefaultType {
	division?: number;
	isOpen: boolean;
	close: () => void;
	filter?: string[];
	setFilter?: React.Dispatch<React.SetStateAction<string[] | number[]>>;
	defaultOrderBy?: number;
	orderBy?: number;
	setOrderBy?: React.Dispatch<React.SetStateAction<string | number>>;
	select?: number;
	setSelect?: React.Dispatch<React.SetStateAction<string | number>>;
	setSelectText?: React.Dispatch<React.SetStateAction<string>>;
	options?: { id: number; text: string }[];
}

export default ({
	division,
	isOpen,
	close,
	filter,
	setFilter,
	defaultOrderBy,
	orderBy,
	setOrderBy,
	select,
	setSelect,
	setSelectText,
	options,
}: PropsWithChildren<DefaultType>) => {
	// 최초 로드 시 현재 적용되어있는 필터 checked 필요
	const categoryList = [
		{ id: "1", text: "Living" },
		{ id: "2", text: "Sports" },
		{ id: "3", text: "Gifticon" },
		{ id: "4", text: "food" },
		{ id: "5", text: "Home appliances" },
		{ id: "6", text: "Toy" },
		{ id: "7", text: "Luxury" },
		{ id: "8", text: "Fashion accessories" },
		{ id: "9", text: "Life" },
		{ id: "10", text: "Cosmetics" },
		{ id: "11", text: "Gift card" },
	];
	const sortList = [
		{ id: 0, text: "Dari harga tertinggi" }, // 가격 높은순
		{ id: 1, text: "Dari harga terendah" }, // 가격 낮은순
		{ id: 2, text: "Dari tanggal terbaru" }, // 최신순
		{ id: 3, text: "Dari tanggal terlama" }, // 오래된순
	];
	const [filterList, setFilterList] = useState<string[]>(filter ?? []);
	const [sort, setSort] = useState<number | string>(defaultOrderBy);
	const [option, setOption] = useState<number | string>();
	// 체크/취소 토글
	const onCheckedElement = (checked, item) => {
		console.log(checked);
		console.log(item);
		if (checked) {
			setFilterList([...filterList, item]);
		} else {
			setFilterList(filterList.filter((el) => el !== item));
		}
	};
	// 필터 적용
	const applyfilter = (e) => {
		if (
			filterList?.length == 0 &&
			sort == undefined &&
			option == undefined
		) {
			setFilter && setFilter([]);
			close();
			return;
		}
		e.preventDefault();
		setFilter && setFilter(filterList);
		setOrderBy && setOrderBy(sort);
		setSelect && setSelect(option);
		setSelect &&
			option !== undefined &&
			setSelectText(options.filter((el) => el.id == option)[0].text);
		close();
	};
	// 기본 값으로 리셋
	const resetDefault = () => {
		setFilterList([]);
		setSort(defaultOrderBy);
	};
	// 적용 되어있는 값으로 리셋
	const reset = () => {
		console.log(filter, orderBy, select);
		setFilterList(filter);
		setSort(orderBy);
		setOption(select);
	};

	return (
		<FilterWrapper $isOpen={isOpen}>
			<S.popupWrapper>
				<S.popupXIcon
					onClick={() => {
						reset();
						close();
					}}
				>
					<XIcon fill={S.color.gray700} />
				</S.popupXIcon>
				<form onSubmit={applyfilter}>
					<h2 style={{ marginBottom: "1.6rem" }}>
						<S.headline_4>
							{setFilter && "Filter pencarian"}
							{!setFilter &&
								setOrderBy &&
								!setSelect &&
								"Mengurutkan urutan"}
							{!setFilter &&
								!setOrderBy &&
								setSelect &&
								"Klasifikasi konsultasi"}
						</S.headline_4>
					</h2>
					<div>
						{setFilter && (
							<section>
								<h3>
									<S.subhead_2 color={S.color.gray800}>
										Klasifikasi produk
									</S.subhead_2>
								</h3>
								<FilterArea>
									{categoryList.map((data) => {
										return (
											<li key={data.id}>
												<S.controlsWrap>
													<input
														id={
															"category" +
															data.id +
															division
														}
														value={data.id}
														type="checkbox"
														name={"category"}
														checked={filterList.includes(
															data.id
														)}
														onChange={(e) => {
															onCheckedElement(
																e.target
																	.checked,
																data.id
															);
														}}
													/>
													<label
														htmlFor={
															"category" +
															data.id +
															division
														}
													>
														<S.bodyText_M
															color={
																S.color.gray600
															}
														>
															{data.text}
														</S.bodyText_M>
													</label>
												</S.controlsWrap>
											</li>
										);
									})}
								</FilterArea>
							</section>
						)}
						{setOrderBy && (
							<section>
								{(setFilter || setSelect) && (
									<h3>
										<S.subhead_2 color={S.color.gray800}>
											Mengurutkan urutan
										</S.subhead_2>
									</h3>
								)}
								<S.inputList>
									{sortList.map((data) => {
										return (
											<li key={data.id}>
												<S.controlsWrap>
													<S.radio
														id={
															"sort" +
															data.id +
															division
														}
														type={"radio"}
														name="sort"
														checked={
															sort == data.id
														}
														onChange={() =>
															setSort(data.id)
														}
													/>
													<label
														htmlFor={
															"sort" +
															data.id +
															division
														}
													>
														<S.bodyText_M
															color={
																S.color.gray800
															}
														>
															{data.text}
														</S.bodyText_M>
													</label>
												</S.controlsWrap>
											</li>
										);
									})}
								</S.inputList>
							</section>
						)}
						{options && (
							<section>
								<S.inputList>
									{options.map((data) => {
										return (
											<li key={data.id}>
												<S.controlsWrap>
													<S.radio
														id={"select" + data.id}
														type={"radio"}
														name="select"
														checked={
															option == data.id
														}
														onChange={() =>
															setOption(data.id)
														}
													/>
													<label
														htmlFor={
															"select" + data.id
														}
													>
														<S.bodyText_M
															color={
																S.color.gray800
															}
														>
															{data.text}
														</S.bodyText_M>
													</label>
												</S.controlsWrap>
											</li>
										);
									})}
								</S.inputList>
							</section>
						)}
					</div>
					{/* 버튼 */}
					<S.buttonWrap>
						<S.buttonPrimary_S
							onClick={(e: React.MouseEvent) => {
								e.preventDefault();
								setFilter ? resetDefault() : applyfilter(e);
							}}
						>
							<S.button_S>
								{setFilter ? "Mengatur ulang" : "Memeriksa"}
							</S.button_S>
						</S.buttonPrimary_S>
						{setFilter && (
							<S.buttonPrimary_S
								onClick={(e: React.MouseEvent) =>
									applyfilter(e)
								}
								type="button"
								$variant
							>
								<S.button_S>Memeriksa</S.button_S>
							</S.buttonPrimary_S>
						)}
					</S.buttonWrap>
				</form>
			</S.popupWrapper>
		</FilterWrapper>
	);
};
