// 메인
import React, { useState, useEffect } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { IsLoginAtom, UserAtom } from "../Recoil/Atoms/UserState";
import {
	LoginAtom,
	AlertSettingAtom,
	AlertSettingContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Modal from "../Components/Modal";

import mainBgTop from "../Images/main_bg_top.gif";
import mainBgMiddle from "../Images/main_bg_middle.png";
import mainBgBottom from "../Images/main_bg_bottom.png";
import mainBupo from "../Images/main_bupo.png";
import mainBupoText from "../Images/main_bupo_text.png";
import mainNews from "../Images/main_news.gif";
import mainNewsRock from "../Images/newsroom_rock.png";
import mainNewsText from "../Images/main_news_text.png";
import mainTradeReady from "../Images/main_trade_ready.png";
import mainTrade from "../Images/main_trade.gif";
import mainTradeRock from "../Images/trade_rock.png";
import mainTradeText from "../Images/main_trade_text.png";
import mainMy from "../Images/main_my.gif";
import mainMyText from "../Images/main_my_text.png";
import mainProfileBg from "../Images/main_Profile_Bg.png";
import mainProfileBgGold from "../Images/main_Profile_Bg_gold.png";
import mainProfileBgPla from "../Images/main_Profile_Bg_pla.png";
import mainProfileBgDia from "../Images/main_Profile_Bg_dia.png";
import defaultProfile from "../Images/default_profile.png";
import treasure from "../Images/treasure.png";
import point from "../Images/point.png";
import plus from "../Images/plus.png";
import sideNav01 from "../Images/side_nav01.png";
import sideNav01Text from "../Images/side_nav01_text.png";
import sideNav02 from "../Images/side_nav02.png";
import sideNav02Text from "../Images/side_nav02_text.png";
import sideNav03 from "../Images/side_nav03.png";
import sideNav03Text from "../Images/side_nav03_text.png";
import sideNav04 from "../Images/side_nav04.png";
import sideNav04Text from "../Images/side_nav04_text.png";
import camera from "../Images/camera.png";
import bottomNew from "../Images/bottomNew_title.png";
import { ReactComponent as Rotate } from "../Images/rotate.svg";

const MainContainer = styled.div`
	height: 100%;
	background-color: #56bbcf;
	display: flex;
	flex-direction: column;
`;
const MainBg = styled.div`
	background-position: center;
	background-size: 100% 100%;
`;
const MainTop = styled(MainBg)`
	flex-grow: 0.28;
	background-image: url(${mainBgTop});
`;
const MainMiddle = styled(MainBg)`
	flex-grow: 0.5;
	background-image: url(${mainBgMiddle});
	position: relative;
`;
const MainBottom = styled(MainBg)`
	flex-grow: 0.22;
	background-image: url(${mainBgBottom});
	position: relative;
`;
const MainButton = styled.button`
	width: 50%;
	height: 30%;
	position: absolute;
	background-position: top center;
	background-size: auto 100%;
	background-repeat: no-repeat;
	transform: translateX(-50%);
`;
const ButtonNews = styled(MainButton)`
	top: -5%;
	left: 60%;
	background-image: url(${mainNews});
	background-position-y: 1.5rem;
	&::before {
		content: "";
		display: block;
		width: 6.4rem;
		height: 2.6rem;
		background-image: url(${mainNewsRock});
		background-size: contain;
		position: absolute;
		top: 85%;
		left: 85%;
		transform: translateX(-50%);
	}
`;
const ButtonTrade = styled(MainButton)`
	top: 30%;
	left: 30%;
	background-image: url(${mainTradeReady});
	background-size: auto 85%;
	background-position-y: 1.5rem;
	&::before {
		content: "";
		display: block;
		width: 4.8rem;
		height: 2.7rem;
		background-image: url(${mainTradeRock});
		background-size: contain;
		position: absolute;
		top: 85%;
		left: 10%;
		transform: translateX(-50%);
	}
`;
const ButtonMy = styled(MainButton)`
	height: 40%;
	top: 50%;
	left: 70%;
	background-image: url(${mainMy});
`;
const ButtonBupo = styled(MainButton)`
	height: 70%;
	top: 5%;
	left: 50%;
	background-image: url(${mainBupo});
	background-size: auto 85%;
`;
const ButtonText = styled.p<{ $url: string }>`
	width: 110%;
	height: 2.6rem;
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%);
	color: transparent;
	background-image: url(${(props) => props.$url && props.$url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;
const UserInfo = styled.div`
	width: 100%;
	top: 5%;
	position: absolute;
	padding: 0 1.4rem;
	display: flex;
	justify-content: space-between;
`;
const UserInfoArea = styled.div`
	height: 6.2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
const UserInfoWrap = styled.button`
	position: relative;
	padding-left: 4.6rem;
`;
const UserInfoIcon = styled.div<{ $icon?: string; $isPlofile?: boolean }>`
	width: ${(props) => (props.$isPlofile ? "5.8rem" : "3.2rem")};
	height: ${(props) => (props.$isPlofile ? "5.8rem" : "3.6rem")};
	left: ${(props) => (props.$isPlofile ? "0" : "2.6rem")};
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
	background-image: url(${(props) => props.$icon && props.$icon});
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
`;
const UserInfoFrame = styled.div<{ $icon: string; $isPlofile?: boolean }>`
	width: 5.8rem;
	height: 5.8rem;
	left: 0;
	top: 50%;
	position: absolute;
	transform: translateY(-50%);
	background-image: url(${(props) => props.$icon && props.$icon});
	background-size: 100% auto;
	background-position: center;
	background-repeat: no-repeat;
`;
const UserInfoPlusIcon = styled(UserInfoIcon)`
	width: 1.2rem;
	left: unset;
	right: 0.4rem;
	margin-top: 0.1rem;
`;
const UserProfile = styled.div<{ $url: string }>`
	width: 4.4rem;
	height: 4.4rem;
	margin: 0.7rem 0 0 0.6rem;
	border-radius: 50%;
	background-color: #38261b;
	background-image: url(${(props) => props.$url});
	background-position: center;
	background-size: cover;
`;
const UserInfoText = styled.div<{ $emphasis?: boolean }>`
	text-shadow: ${(props) =>
		props.$emphasis &&
		"-0.1rem 0 #5a403c, 0 0.1rem #5a403c, 0.1rem 0 #5a403c, 0 -0.1rem #5a403c"};
	font-family: ${(props) =>
		props.$emphasis ? "RobotoBold" : "RobotoMedium"};
	min-width: 9rem;
	color: ${"white"};
	font-size: 1.2rem;
	line-height: 1.4rem;
	padding: 0.6rem 1.6rem;
	background-color: ${S.color.dimmed70};
	border-radius: 0 5rem 5rem 0;
`;
const SideNav = styled.div`
	width: 100%;
	position: absolute;
	top: calc(5% + 8.2rem);
`;
const SideNavIcon = styled.button<{
	$icon: string;
	order: number;
	$text: string;
}>`
	left: ${(props) =>
		props.order % 2 === 0 ? "1.8rem" : "calc(100% - 7.4rem)"};
	top: ${(props) => Math.floor(props.order / 2) * 9.5}rem;
	background-image: url(${(props) => props.$icon && props.$icon});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	width: 5.6rem;
	height: 6.1rem;
	position: absolute;
	&::after {
		content: "";
		display: "block";
		width: 6.4rem;
		height: 3.4rem;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -1.2rem;
		background-image: url(${(props) => props.$text && props.$text});
		background-size: contain;
	}
`;
// 새로운 보물 모달
const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${S.color.gray100};
	h1 {
		margin-top: 1.5rem;
		margin-bottom: 3rem;
	}
`;
const BottomNewTitle = styled.div`
	width: 100%;
	height: 13rem;
	margin-bottom: 3.6rem;
	background-image: url(${bottomNew});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;
const BottomNewButtonArea = styled.div`
	padding: 2rem;
	display: flex;
	justify-content: space-between;
`;

export default () => {
	const navigate = useNavigate();
	const imgUrl = process.env.REACT_APP_IMG_URL;
	// 유저 정보
	const isLogin = useRecoilValue(IsLoginAtom);
	const userState = useRecoilValue(UserAtom);
	// 팝업
	const [isOpenLogin, setOpenLogin] = useRecoilState<boolean>(LoginAtom);
	const [isOpenPush, setOpenPush] = useState<boolean>(false);
	const setOpenAlertSetting = useSetRecoilState<boolean>(AlertSettingAtom);
	const setAlertSettingContents = useSetRecoilState(AlertSettingContentsAtom);
	// 푸시동의 팝업
	const [isOpenPushAgree, setOpenPushAgree] = useState<boolean>(false);
	// 새로운 보물 하단 모달
	const [isOpenNewTreasure, setOpenNewTreasure] = useState<boolean>(false);

	// 로그인 여부 확인 후 컨펌생성/화면이동 구분
	const loginCheck = (path, option?) => {
		userState.mmIdx === 0 ? setOpenLogin(true) : navigate(path, option);
	};

	// 최초 프로필 설정 표시될 이미지
	const profileImage = () => {
		if (userState.profileImageUrl == "") {
			return defaultProfile;
		} else {
			if (userState.profileImageUrl.includes("http")) {
				return userState.profileImageUrl;
			} else {
				return imgUrl + userState.profileImageUrl;
			}
		}
	};

	return (
		<MainContainer>
			{/* 회원 정보 영역 */}
			<UserInfo>
				<UserInfoArea>
					<UserInfoWrap onClick={() => navigate("/MYME001001")}>
						<UserInfoIcon $isPlofile>
							<UserProfile $url={profileImage()} />
						</UserInfoIcon>
						<UserInfoFrame
							// TODO: 유저 등급에 따라 프레임 변경
							$icon={mainProfileBg}
						></UserInfoFrame>
						<UserInfoText $emphasis>
							{isLogin ? userState.nick : "Log-in"}
						</UserInfoText>
					</UserInfoWrap>
				</UserInfoArea>
				<UserInfoArea>
					<UserInfoWrap onClick={() => loginCheck("/POIN001001")}>
						<UserInfoIcon $icon={treasure} />
						<UserInfoText>
							{isLogin ? Util.addComma(userState.goldboxCnt) : 0}
						</UserInfoText>
						<UserInfoPlusIcon $icon={plus} />
					</UserInfoWrap>
					<UserInfoWrap onClick={() => console.log("없음")}>
						<UserInfoIcon $icon={point} />
						<UserInfoText>
							{isLogin ? Util.addComma(userState.freePoint) : 0}
						</UserInfoText>
					</UserInfoWrap>
				</UserInfoArea>
			</UserInfo>
			<MainTop />
			<MainMiddle>
				<ButtonNews onClick={() => navigate("/NEWS001001")}>
					<ButtonText $url={mainNewsText} />
				</ButtonNews>
				<ButtonTrade onClick={() => {}}></ButtonTrade>
				<ButtonMy onClick={() => loginCheck("/MYME002001")}>
					<ButtonText $url={mainMyText} />
				</ButtonMy>
			</MainMiddle>
			<MainBottom>
				<ButtonBupo
					onClick={() =>
						loginCheck(
							userState.goldboxCnt > 0
								? "/BUPO001001"
								: "/POIN001001"
						)
					}
				>
					<ButtonText $url={mainBupoText} />
				</ButtonBupo>
			</MainBottom>
			{/* 사이드 메뉴 영역 */}
			<SideNav>
				<SideNavIcon
					$icon={sideNav01}
					$text={sideNav01Text}
					order={0}
					onClick={() => navigate("/TRSR001001")}
				/>
				<SideNavIcon
					$icon={sideNav03}
					$text={sideNav03Text}
					order={2}
					onClick={() => loginCheck("/UPGD001001")}
				/>
				<SideNavIcon
					$icon={sideNav04}
					$text={sideNav04Text}
					order={1}
					onClick={() => console.log("이벤트")}
				/>
			</SideNav>
			{/* 알림 동의 팝업 */}
			<Modal
				isOpen={isOpenPush}
				contents={
					<>
						<h2>
							<S.headline_4>
								Halo, kami adalah GOPANG!
							</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Kami akan mengirimkan pemberitahuan{"\n"}demi
								kenyamanan anda dalam menggunakan GOPANG.
							</S.bodyText_M_long>
						</p>
						<S.popupBox>
							<S.controlsWrap style={{ marginBottom: "0.8rem" }}>
								<S.checkbox
									// 체크 여부에 따라 동의 여부 업데이트
									defaultChecked={true}
									id="pushAgree"
									name={"pushAgree"}
									type="checkbox"
									size={"L"}
									onChange={(e) =>
										setAlertSettingContents({
											agree: e.target.checked,
											type: 0,
										})
									}
								/>
								<label htmlFor="pushAgree">
									<S.bodyText_M>
										Kirimkan kabar event/update.
									</S.bodyText_M>
								</label>
							</S.controlsWrap>
							<S.bodyText_S color={S.color.gray700}>
								Diperlukan pengaturan pemberitahuan{`\n`}
								internal perangkat tergantung pada persetujuan
								penerimaan pemberitahuan.
							</S.bodyText_S>
						</S.popupBox>
					</>
				}
				icon={"bell"}
				close={() => setOpenPushAgree(false)}
				buttonTextL={"Setuju"}
				buttonActionL={() => {
					setOpenAlertSetting(true);
				}}
			/>
			{/* 새로운 보물 */}
			<S.bottomPopupContainer
				$isOpen={isOpenNewTreasure}
				onClick={() => setOpenNewTreasure(false)}
			>
				<S.bottomPopupWrapper>
					<Container
						onClick={() => {
							{
								/* TODO: 보물 화면으로 이동 */
							}
						}}
					>
						<h1>
							<S.mainTitle_L>
								Lihatlah harta karun baru
							</S.mainTitle_L>
						</h1>
						<BottomNewTitle></BottomNewTitle>
					</Container>
					<BottomNewButtonArea>
						<button>
							<S.bodyText_M color={S.color.gray600}>
								tidak melihat hari ini
							</S.bodyText_M>
						</button>
						<button onClick={() => setOpenNewTreasure(false)}>
							<S.button_M>menutup</S.button_M>
						</button>
					</BottomNewButtonArea>
				</S.bottomPopupWrapper>
			</S.bottomPopupContainer>
		</MainContainer>
	);
};
