// 이용 내역
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { LoadingAtom, UserAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import TabMenu from "../Components/TabMenu";
import RefreshHandler from "../Components/RefreshHandler";

import chestGold from "../Images/chest_gold.png";
import point from "../Images/point.png";

const UserPropertyArea = styled(S.flexRowCenter)`
	background-color: ${S.color.orange50};
	border-radius: 1rem;
	margin: 2rem 1.4rem 0;
	padding: 1.2rem 0;
	img {
		height: 3.4rem;
		margin-right: 1.2rem;
	}
	p {
		border-radius: 2rem;
	}
`;
const TotalPointArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem 1.4rem 1.4rem;
	header {
		margin-bottom: 1rem;
	}
	div {
		padding: 0.4rem;
		padding-left: 1rem;
		background-color: ${S.color.gray200};
		border-radius: 2rem;
		span {
			margin: 0 2rem;
		}
	}
	img {
		height: 1.8rem;
	}
`;

interface boxHistoryData {
	boxActionCode: string;
	boxType: string;
	changeType: string;
	changeTypeCode: string;
	mbqhIdx: number;
	regDt: string;
	count: string;
	updownType: string;
}
interface pointHistoryData {
	changeType: string;
	changeTypeCode: string;
	mphmIdx: string;
	regDt: string;
	updownType: string;
	groupCount: string;
	count: number;
	userType: string;
}

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	const defaultTab = location.state ? location.state.data : 0;
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 황성화 탭 인덱스
	const [activeTab, setActiveTab] = useState<number>(defaultTab);
	// 상자내역 리스트 정보
	const [boxHistoryDatas, setBoxHistoryDatas] = useState<boxHistoryData[]>(
		[]
	);
	const [boxHistoryNextPage, setBoxHistoryNextPage] = useState<boolean>(true);
	const boxHistoryPage = useRef<number>(1);
	const boxHistoryObserver = useRef<HTMLDivElement>(null);
	// 포인트내역 리스트 정보
	const [pointHistoryDatas, setPointHistoryDatas] = useState<
		pointHistoryData[]
	>([]);
	const [pointHistoryNextPage, setPointHistoryNextPage] =
		useState<boolean>(true);
	const pointHistoryPage = useRef<number>(1);
	const pointHistoryObserver = useRef<HTMLDivElement>(null);
	// 상자내역 리셋
	const boxHistoryReset = () => {
		setBoxHistoryDatas([]);
		setBoxHistoryNextPage(true);
		boxHistoryPage.current = 1;
	};
	// 포인트내역 리셋
	const pointHistoryReset = () => {
		setPointHistoryDatas([]);
		setPointHistoryNextPage(true);
		pointHistoryPage.current = 1;
	};
	// 상자내역 리스트 불러오기
	const boxHistoryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("boxHistoryFetch");
		await Api.get(`/member/info/memberBoxHistory`, {
			page: boxHistoryPage.current,
			limit: 10,
		})
			.then((res) => {
				console.log(res.data);
				if (res.data) {
					const data = res.data.data.list;
					setBoxHistoryDatas((prevDatas) => [...prevDatas, ...data]);
					setBoxHistoryNextPage(data.length === 10);
					if (data.length) {
						boxHistoryPage.current += 1;
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
		setLoading(false);
	};
	// 포인트내역 리스트 불러오기
	const pointHistoryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("pointHistoryFetch");
		await Api.get(`/member/info/memberPointHistory`, {
			page: pointHistoryPage.current,
			limit: 10,
		})
			.then((res) => {
				console.log(res.data);
				if (res.data) {
					const data = res.data.data.list;
					setPointHistoryDatas((prevDatas) => [
						...prevDatas,
						...data,
					]);
					setPointHistoryNextPage(data.length === 10);
					if (data.length) {
						pointHistoryPage.current += 1;
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
		setLoading(false);
	};
	useEffect(() => {
		if (!boxHistoryObserver.current || !boxHistoryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				boxHistoryFetch();
			}
		});
		io.observe(boxHistoryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [boxHistoryNextPage, activeTab]);
	useEffect(() => {
		if (!pointHistoryObserver.current || !pointHistoryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				pointHistoryFetch();
			}
		});
		io.observe(pointHistoryObserver.current); // 관찰할 대상 등록

		return () => {
			io.disconnect();
		};
	}, [pointHistoryNextPage, activeTab]);
	// 각 탭 리셋
	useEffect(() => {
		activeTab === 0 ? boxHistoryReset() : pointHistoryReset();
	}, [activeTab]);

	// 상자 이력 구분
	const boxChangeText = (changeType: string) => {
		switch (changeType) {
			case "01": // 결제 구매
				return "Beli box";
			case "02": // 포인트 구매
				return "Beli box";
			case "04": // 합성 획득
				return "합성 획득";
			case "05": // 기타 획득
				return "기타 획득";
			case "21": // 테스트 지급
				return "Test";
			case "23": // 이벤트 지급
				return "Event";
			case "24": // 기타 지급
				return "Dll";
			default:
				return "-";
		}
	};
	// 포인트 이력 구분
	const pointChangeText = (changeType: string) => {
		switch (changeType) {
			case "12": // 분해
				return "Pemisahan";
			case "21": // 테스트 지급
				return "Test";
			case "23": // 이벤트 지급
				return "Event";
			case "24": // 기타 지급
				return "Dll";
			case "26": // 배송 환급
				return "배송 환급";
			case "27": // 배송 취소
				return "배송 취소";
			default:
				return "-";
		}
	};

	return (
		<>
			<Page
				title="Riwayat Penggunaan"
				contents={
					<>
						<TabMenu
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							tabs={[
								{
									index: 0,
									name: "Box",
									content: (
										<S.wrapper>
											<UserPropertyArea
												onClick={() => {
													userState.goldboxCnt > 0
														? navigate(
																"/BUPO001001",
																{
																	replace:
																		true,
																}
														  )
														: navigate(
																"/POIN001001",
																{
																	replace:
																		true,
																}
														  );
												}}
											>
												<img src={chestGold} />
												<p>
													<S.bodyText_M_long>
														Gold box{" "}
													</S.bodyText_M_long>
													<S.subhead_3>
														{Util.addComma(
															userState.goldboxCnt
														)}{" "}
														buah
													</S.subhead_3>
												</p>
											</UserPropertyArea>
											{/* 상자내역 */}
											{/* 리스트 */}
											<RefreshHandler
												handleRefresh={() =>
													boxHistoryReset()
												}
												contents={
													<S.wrapper>
														{boxHistoryDatas.length ===
															0 ||
														boxHistoryDatas ===
															undefined ? (
															<NoList />
														) : (
															boxHistoryDatas?.map(
																(
																	boxHistoryData,
																	index
																) => (
																	<S.helpItem
																		key={
																			index
																		}
																	>
																		<div>
																			<S.bodyText_M_long
																				$ellipsis={
																					1
																				}
																				style={{
																					marginBottom:
																						"0.8rem",
																				}}
																			>
																				{boxChangeText(
																					boxHistoryData.changeTypeCode
																				)}
																			</S.bodyText_M_long>
																			<S.bodyText_XS
																				color={
																					S
																						.color
																						.gray600
																				}
																			>
																				{Util.dateFormat(
																					boxHistoryData?.regDt
																				)}
																			</S.bodyText_XS>
																		</div>
																		<S.flexColEnd>
																			<S.subhead_3
																				$ellipsis={
																					1
																				}
																				style={{
																					marginBottom:
																						"0.4rem",
																				}}
																			>
																				{boxHistoryData.updownType ==
																				"1"
																					? "+"
																					: "-"}
																				{
																					boxHistoryData?.count
																				}
																			</S.subhead_3>
																			<S.bodyText_S
																				color={
																					S
																						.color
																						.gray600
																				}
																			>
																				{boxHistoryData.boxType ==
																				"2"
																					? "Gold"
																					: ""}
																			</S.bodyText_S>
																		</S.flexColEnd>
																	</S.helpItem>
																)
															)
														)}
														<div
															ref={
																boxHistoryObserver
															}
														/>
													</S.wrapper>
												}
											/>
										</S.wrapper>
									),
								},
								{
									index: 1,
									name: "Point",
									content: (
										<S.wrapper>
											<UserPropertyArea>
												<img src={point} />
												<p>
													<S.bodyText_M_long>
														Total poin saya{" "}
													</S.bodyText_M_long>
													<S.subhead_3>
														{Util.addComma(
															userState.freePoint
														)}
													</S.subhead_3>
												</p>
											</UserPropertyArea>
											{/* 포인트내역 */}
											{/* 리스트 */}
											<RefreshHandler
												handleRefresh={() =>
													pointHistoryReset()
												}
												contents={
													<S.wrapper>
														{pointHistoryDatas.length ===
														0 ? (
															<NoList />
														) : (
															pointHistoryDatas?.map(
																(
																	pointHistoryData,
																	index
																) => (
																	<S.helpItem
																		key={
																			index
																		}
																	>
																		<div>
																			<S.bodyText_M_long
																				$ellipsis={
																					1
																				}
																				style={{
																					marginBottom:
																						"0.8rem",
																				}}
																			>
																				{pointChangeText(
																					pointHistoryData.changeTypeCode
																				)}
																			</S.bodyText_M_long>
																			<S.bodyText_XS
																				color={
																					S
																						.color
																						.gray500
																				}
																			>
																				{Util.dateFormat(
																					pointHistoryData?.regDt
																				)}
																			</S.bodyText_XS>
																		</div>
																		<S.flexColEnd>
																			<S.subhead_3
																				$ellipsis={
																					1
																				}
																				style={{
																					marginBottom:
																						"0.4rem",
																				}}
																			>
																				{pointHistoryData.updownType ==
																				"1"
																					? "+"
																					: "-"}
																				{Util.addComma(
																					Number(
																						pointHistoryData.count
																					)
																				)}

																				P
																			</S.subhead_3>
																			<S.bodyText_S
																				color={
																					S
																						.color
																						.gray600
																				}
																			>
																				{
																					pointHistoryData.groupCount
																				}{" "}
																				kasus
																			</S.bodyText_S>
																		</S.flexColEnd>
																	</S.helpItem>
																)
															)
														)}
														<div
															ref={
																pointHistoryObserver
															}
														/>
													</S.wrapper>
												}
											/>
										</S.wrapper>
									),
								},
							]}
						/>
					</>
				}
				badge="Beli box"
				badgeAction={() => navigate("/POIN001001")}
			/>
		</>
	);
};
