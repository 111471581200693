// 배송요청
import React, { PropsWithChildren, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
import Page from "../Components/Page";

import test_1 from "../Images/test_1.png";
import point from "../Images/point.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import { ReactComponent as Chevron } from "../Images/chevron.svg";

const MyPoint = styled.div`
	width: 2.2rem;
	height: 2.2rem;
	position: relative;
	margin-left: 0.4rem;
`;

export default () => {
	const navigate = useNavigate();
	const location = useLocation().state;
	// 배송비 결제 팝업 생성
	const [isOpenDeliveryCharge, setOpenDeliveryCharge] =
		useState<boolean>(false);
	// 배송비 결제 안내 영역 확장
	const [isExpend, setExpend] = useState<boolean>(false);
	useEffect(() => {
		console.log(location.item);
	}, []);

	return (
		<>
			<Page
				title="Ajukan pengiriman"
				contents={
					<S.pageContainer>
						<section>
							<S.treasureBox>
								<div>
									<S.heightImg
										src={test_1}
										// 상품 이미지 등록 전
										// src={
										// 	imageUrl +
										// 	exchangeData.typeCImageUrl
										// }
									/>
								</div>
								<p>
									<S.subhead_3 $ellipsis={1}>
										{location.item.name}
									</S.subhead_3>
								</p>
							</S.treasureBox>
							<S.flexRowBetween>
								<S.subhead_2>Informasi pengiriman</S.subhead_2>
								<S.borderBadge
									$large
									onClick={() =>
										navigate("/DLVR003001", {
											state: { type: 1 },
										})
									}
								>
									<S.bodyText_XS>
										Pengaturan alamat pengiriman
									</S.bodyText_XS>
								</S.borderBadge>
							</S.flexRowBetween>
							<h2>
								<S.subhead_4>
									Nama alamat pengiriman
								</S.subhead_4>{" "}
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input placeholder="Masukkan nama alamat pengiriman"></input>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Penerima</S.subhead_4>{" "}
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input placeholder="Masukkan peneriman"></input>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Kontak</S.subhead_4>{" "}
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<S.inputField>
								<div className="inputWrap">
									<input placeholder="0062-18836432234"></input>
								</div>
							</S.inputField>
							<h2>
								<S.subhead_4>Alamat</S.subhead_4>{" "}
								<S.subhead_4 color={S.color.error}>
									*
								</S.subhead_4>
							</h2>
							<div className="inputArea">
								<S.inputField>
									<div className="inputWrap">
										<input placeholder="Masukkan alamat"></input>
									</div>
									<button>
										<S.button_XS>Cari alamat</S.button_XS>
									</button>
								</S.inputField>
								<S.inputField>
									<div className="inputWrap">
										<input placeholder="Masukkan alamat"></input>
									</div>
								</S.inputField>
								<S.inputField>
									<div className="inputWrap">
										<input placeholder="Masukkan alamat"></input>
									</div>
								</S.inputField>
								<S.controlsWrap>
									<S.checkbox
										type="checkbox"
										id="save"
									></S.checkbox>
									<label htmlFor="save">
										<S.bodyText_S color={S.color.gray800}>
											Simpan di daftar alamat.
										</S.bodyText_S>
									</label>
								</S.controlsWrap>
								<S.controlsWrap>
									<S.checkbox
										type="checkbox"
										id="basic"
									></S.checkbox>
									<label htmlFor="basic">
										<S.bodyText_S color={S.color.gray800}>
											Pilih sebagai alamat utama
										</S.bodyText_S>
									</label>
								</S.controlsWrap>
							</div>
							<h2>
								<S.subhead_4>
									Permintaan khusus pengiriman
								</S.subhead_4>
							</h2>
							<S.inputField style={{ marginBottom: "1.2rem" }}>
								<div className="inputWrap">
									<textarea
										name=""
										id=""
										// cols="30"
										rows={5}
										maxLength={50}
										placeholder="Masukkan dalam 50 karakter."
									></textarea>
									<S.inputCount className="inTextArea">
										<S.bodyText_XS>(0/50)</S.bodyText_XS>
									</S.inputCount>
								</div>
							</S.inputField>
							<S.textList $gray>
								<li>
									<S.bodyText_S>
										Pengajuan sampai jam 3 sore pada hari
										kerja akan dikirim hari itu juga,
										pengajuan setelahnya akan dikirim pada
										hari kerja setelahnya.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Bisa diperlukan waktu untuk persiapan
										pengiriman tergantu pada sifat produk
										dan ketersediaannya.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Pengiriman tidak bisa dilakukan atau
										diperlukan biaya pengiriman tambahan
										untuk Jeju/daerah pulau.
									</S.bodyText_S>
								</li>
							</S.textList>
						</section>
						<section>
							<S.borderBox style={{ padding: "1.2rem 0" }}>
								<p style={{ textAlign: "center" }}>
									<S.headline_4
										color={S.color.gray800}
										style={{ textAlign: "center" }}
									>
										Poin saya saat ini
									</S.headline_4>
								</p>
								<p style={{ textAlign: "center" }}>
									<S.headline_3
										color={S.color.primaryVariant}
									>
										000 P
									</S.headline_3>
								</p>
							</S.borderBox>
						</section>
					</S.pageContainer>
				}
				button={"Bayar biaya pengiriman"}
				buttonAction={() => setOpenDeliveryCharge(true)}
				disabled={false}
			/>
			{/* 배송비 결제 팝업 */}
			<S.bottomPopupContainer $isOpen={isOpenDeliveryCharge}>
				<S.popupWrapper $bottom={true}>
					<S.popupXIcon onClick={() => setOpenDeliveryCharge(false)}>
						<XIcon fill={S.color.gray700} />
					</S.popupXIcon>
					<h1>
						<S.headline_4>Pembayaran dengan poin</S.headline_4>
					</h1>
					<h2>
						<S.subhead_2 color={S.color.gray800}>Poin</S.subhead_2>
					</h2>
					<S.flexRowBetween>
						<S.subhead_3 color={S.color.gray800}>
							Yang anda miliki
						</S.subhead_3>
						<S.flexRowCenter>
							<S.subhead_2>450,100</S.subhead_2>
							<MyPoint>
								<S.heightImg src={point}></S.heightImg>
							</MyPoint>
						</S.flexRowCenter>
					</S.flexRowBetween>
					<S.flexRowCenter
						style={{
							justifyContent: "flex-start",
							marginTop: "0.6rem",
							marginBottom: "0.8rem",
						}}
					>
						<S.subhead_3
							style={{ marginRight: "0.6rem" }}
							color={S.color.gray800}
						>
							Gunakan
						</S.subhead_3>
						<S.bodyText_S color={S.color.gray600}>
							Poin bisa digunakan mulai dari 1P.
						</S.bodyText_S>
					</S.flexRowCenter>
					<S.inputField>
						<div className="inputWrap">
							<input type="text" placeholder="0 P" />
						</div>
						<button>
							<S.bodyText_S>Gunakan semua</S.bodyText_S>
						</button>
					</S.inputField>
					<S.bar />
					<h2>
						<S.subhead_2 color={S.color.gray800}>
							Cara pembayaran
						</S.subhead_2>
					</h2>
					<S.controlsWrap>
						<S.radio id="payment1" name="payment" type="radio" />
						<label htmlFor="payment1">
							<S.bodyText_M color={S.color.gray800}>
								Kartu Kredit
							</S.bodyText_M>
						</label>
					</S.controlsWrap>
					<S.bar />
					<h2>
						<S.subhead_2>Informasi pembayaran</S.subhead_2>
					</h2>
					<S.flexRowBetween style={{ marginBottom: "0.8rem" }}>
						<S.bodyText_M_long>
							Biaya pengiriman (IDR)
						</S.bodyText_M_long>
						<S.subhead_2>3,000</S.subhead_2>
					</S.flexRowBetween>
					<S.flexRowBetween>
						<S.bodyText_M_long>Poin (P)</S.bodyText_M_long>
						<S.subhead_2>0</S.subhead_2>
					</S.flexRowBetween>
					<S.bar style={{ borderColor: S.color.gray500 }} />
					<S.flexRowBetween>
						<S.subhead_2>Biaya yang akan dibayarkan</S.subhead_2>
						<S.subhead_2 color={S.color.primary}>
							3,000 IDR
						</S.subhead_2>
					</S.flexRowBetween>
					<S.expandedExplain $expand={isExpend}>
						<div
							onClick={() => {
								setExpend(!isExpend);
							}}
						>
							<header>
								<S.subhead_4>
									Pemberitahuan pembayaran biaya pengiriman
								</S.subhead_4>
								<Chevron fill={S.color.gray400} />
							</header>
							<S.textList>
								<li>
									<S.bodyText_S>
										Biaya pengiriman akan dibatalkan secara
										otomatis bila anda membatalkan
										pengiriman. Pengembalian dana akan
										selesai dalam waktu maksimal 5 hari
										kerja perusahaan penyedia kartu kredit
										dan bank.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Anda bisa membatalkan langsung di [Harta
										Karun Saya &gt; Pengajuan Pengiriman
										&gt; Status Pengiriman] bila barang
										belum dipesan.
									</S.bodyText_S>
								</li>
							</S.textList>
						</div>
					</S.expandedExplain>

					<S.buttonPrimary_S
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							setOpenDeliveryCharge(false);
							// 결제
						}}
					>
						<S.button_S>Payment and delivery request</S.button_S>
					</S.buttonPrimary_S>
				</S.popupWrapper>
			</S.bottomPopupContainer>
		</>
	);
};
