import { useEffect, useState } from "react";
import Api from "../libs/api";
import axios from "axios";
import React, { PropsWithChildren } from "react";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { UserAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";

import captain from "../Images/popup_captain.png";
import bubble from "../Images/popup_bubble.png";
import bell from "../Images/popup_bell.png";
import rotate from "../Images/popup_rotate.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import defaultProfile from "../Images/default_profile.png";
import { ReactComponent as Rotate } from "../Images/rotate.svg";
import { idText } from "typescript";

interface DefaultType {
	isOpen: boolean; // 팝업 생성 여부
	close: () => void; // 팝업 닫기
}

export default ({ isOpen, close }: PropsWithChildren<DefaultType>) => {
	const apiUrl = process.env.REACT_APP_API_URL;
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [nick, setNick] = useState<string>("");
	const [profile, setProfile] = useState<{
		url: string;
		file: File;
	}>({
		url: "",
		file: null,
	});
	const [profileExplain, setProfileExplain] = useState<string>("");
	const [isValid, setValid] = useState<boolean>(false);

	useEffect(() => {
		let result;

		if (userState.profileImageUrl == "") {
			result = defaultProfile;
		} else {
			if (userState.profileImageUrl.includes("http")) {
				result = userState.profileImageUrl;
			} else {
				result = imgUrl + userState.profileImageUrl;
			}
		}
		console.log(result);
		let copiedList = profile;
		copiedList.url = result;
		setProfile(copiedList);
		setNick(userState.nick);
	}, [userState]);
	// 최초 프로필 설정 표시될 이미지
	const profileImage = () => {
		if (profile.url == "") {
			if (userState.profileImageUrl == "") {
				return defaultProfile;
			} else {
				if (userState.profileImageUrl.includes("http")) {
					return userState.profileImageUrl;
				} else {
					return apiUrl + userState.profileImageUrl;
				}
			}
		} else {
			return profile;
		}
	};
	// 최초 프로필 설정 모달 이미지 등록
	const profileUpload = (e) => {
		const file = e.target.files[0];
		console.log(file.size);
		if (file.length === 0) {
			setProfile({
				url: "",
				file: null,
			});
		} else {
			if (file.size > 20 * 1024 * 1024) {
				return (
					setToastType(1),
					setToastContents(
						"File gambar hingga 20MB dapat dilampirkan." // 번역 확인 필요
					),
					setOpenToast(true)
				);
			} else {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onloadend = () => {
					setProfile({
						url: reader.result as string,
						file: file,
					});
				};
			}
		}
	};
	// 최초 프로필 설정 모달 저장 버튼 클릭
	const formData = new FormData();
	const memberInfoChange = async () => {
		const nick = (document.getElementById("nick") as HTMLInputElement)
			.value;
		// 중복확인 안한 경우
		if (!isValid) {
			setToastType(1);
			setToastContents("Pastikan nickname anda belum digunakan.");
			setOpenToast(true);
			return;
		}

		const file = profile.file;
		if (file == null) {
			if (profile.url.toString().includes("http")) {
				formData.append("reset", "1");
			} else {
				// 기본이미지로
				formData.append("reset", "0");
			}
		} else {
			formData.append("profileImageUrl", profile.file);
		}
		formData.append("nick", nick);
		for (let key of formData.keys()) {
			console.log(key, ":", formData.get(key));
		}
		const token = localStorage.getItem("token");
		await await axios
			.patch(apiUrl + "/auth/authFirstNick", formData, {
				headers: {
					Authorization: token,
					"content-type": "multipart/form-data",
				},
			})
			.then((res) => {
				console.log(res);
				close();
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};
	// 닉네임 중복체크
	const memberNickDuplicationCheck = async () => {
		// 영어, 한글, 숫자만 사용 가능
		const regex = /^[a-z|A-Z|0-9|ㄱ-ㅎ|가-힣]+$/;
		if (nick.length < 2) {
			// 2글자 미만 작성
			console.log("2글자 미만");
			setProfileExplain("Tulis minimal 2 suku kata.");
		} else if (nick.search(/\s/) != -1 || !regex.test(nick)) {
			// 특수문자 사용
			console.log("특수문자 사용");
			setProfileExplain("Karakter spesial tidak bisa digunakan.");
		} else if (false) {
			// 필터처리된 단어 포함
			console.log("필터처리된 단어 포함");
			setProfileExplain("Karakter spesial tidak bisa digunakan.");
		} else {
			await Api.get("/member/info/memberNickDuplicationCheck", {
				nick: nick,
			})
				.then((res) => {
					if (res.data.data === 0) {
						// 사용 가능
						console.log("사용 가능");
						setProfileExplain("Nickname bisa digunakan.");
						setValid(true);
					} else if (res.data.data > 0) {
						// 중복
						console.log("중복");
						setProfileExplain("Ada nickname yang sudah digunakan.");
						setValid(false);
					} else {
						setValid(false);
					}
				})
				.catch((error) => {
					console.log(error.response.data);
				});
		}
	};
	return (
		<S.popupContainer $isOpen={isOpen}>
			<S.popupWrapper>
				{/* 내용 */}
				<>
					<h1>
						<S.mainTitle_L>Pengaturan Nickname</S.mainTitle_L>
					</h1>
					<p>
						<S.subhead_2>
							Tentukan nickname yang akan anda gunakan di Treasure
							Ship.
						</S.subhead_2>
					</p>
					<S.textList style={{ marginBottom: "2rem" }} $gray>
						<li>
							<S.bodyText_M_long>
								Angka, hangeul, abjad semua bisa.
							</S.bodyText_M_long>
						</li>
						<li>
							<S.bodyText_M_long>
								Tulis minimal 2 suku kata.
							</S.bodyText_M_long>
						</li>
						<li>
							<S.bodyText_M_long>
								Bila terdapat kata-kata tidak pantas seperti
								kata-kata vulgar, pengguna bisa mendapatkan
								sanksi berupa pembatasan aktifitas dan
								modifikasi paksa.
							</S.bodyText_M_long>
						</li>
						<li>
							<S.bodyText_M_long>
								Hanya file gambar gif, jpg, dan png dalam ukuran
								20 MB yang dapat didaftarkan.
							</S.bodyText_M_long>
						</li>
					</S.textList>
					<S.popupBox>
						<S.setProfileArea>
							<S.userProfile
								$url={
									profile.url ? profile.url : defaultProfile
								}
							>
								<input
									type="file"
									id="profileUpload"
									accept=" .gif, .jpg, .jpeg, .png"
									onChange={(e) => profileUpload(e)}
								/>
								<label htmlFor="profileUpload" />
								<Rotate
									fill="white"
									onClick={() => {
										setProfile({
											url: "",
											file: null,
										});
									}}
								/>
							</S.userProfile>
							<S.inputField>
								<div className="inputWrap">
									<input
										id="nick"
										value={nick}
										onFocus={() => setNick("")}
										onChange={(e) => {
											setValid(false);
											setProfileExplain("");
											setNick(e.target.value);
										}}
										placeholder={"Nickname"}
										maxLength={8}
									/>
								</div>
							</S.inputField>
							<p>
								<S.bodyText_S
									color={
										isValid
											? S.color.point01
											: S.color.error
									}
								>
									{profileExplain}
								</S.bodyText_S>
							</p>
						</S.setProfileArea>
						<S.buttonPrimary_B
							onClick={() => memberNickDuplicationCheck()}
						>
							<S.button_XS>Periksa ulang</S.button_XS>
						</S.buttonPrimary_B>
					</S.popupBox>
				</>
				{/* 버튼 */}
				<S.buttonWrap>
					<S.buttonPrimary_S
						onClick={(e: React.MouseEvent) => {
							e.preventDefault();
							memberInfoChange();
						}}
					>
						<S.button_S>Simpan</S.button_S>
					</S.buttonPrimary_S>
				</S.buttonWrap>
			</S.popupWrapper>
		</S.popupContainer>
	);
};
