// 로그인
import React, { useEffect, useState } from "react";
import Api from "../libs/api";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { SetProfileAtom } from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Modal from "../Components/Modal";

import logo from "../Images/logo.svg";
import google from "../Images/google.png";
import xWhite from "../Images/x_white.png";

const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const LogoWrap = styled(S.flexRowCenter)`
	flex-grow: 1;
`;
const Logo = styled.img`
	width: 29rem;
	height: 9.4rem;
`;
const ButtonWrap = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 4rem;
`;
const GoogleLoginButton = styled.button`
	width: 92%;
	height: 5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 1rem;
	border: 1px solid ${S.color.gray400};
	margin-top: 1rem;
	position: relative;
`;
const GoogleLogo = styled.img`
	width: 2.4rem;
	height: 2.4rem;
	position: absolute;
	left: 1.7rem;
`;
declare global {
	interface Window {
		ReactNativeWebView: {
			postMessage: (value: string) => void;
		};
	}
}

export default () => {
	const navigate = useNavigate();
	const [isOpenAlert, setOpenAlert] = useState<boolean>(false);
	const [isOpenSetProfile, setOpenSetProfile] =
		useRecoilState<boolean>(SetProfileAtom); // 최초 프로필 설정 모달

	// 로그인 창 띄우기
	// const Login = () => {
	// 	const googleSocialLogin = useGoogleLogin({
	// 		scope: "email profile",
	// 		onSuccess: async ({ code }) => {
	// 			Api.get("/auth/oauth2/redirect/google", {
	// 				code: code,
	// 			}).then(({ data }) => {
	// 				console.log(data);
	// 				localStorage.setItem(
	// 					"token",
	// 					JSON.stringify({
	// 						accessToken: data.accessToken,
	// 						refreshToken: data.refreshToken,
	// 					})
	// 				);
	// 				if (data.stateCode == "01") {
	// 					// 약관 미동의 회원
	// 					navigate("/MEMB002001");
	// 				} else if (data.stateCode == "02") {
	// 					// 닉네임 미설정 회원
	// 					setOpenSetProfile(true);
	// 					navigate("/");
	// 				} else if (data.stateCode == "11") {
	// 					// 정상 회원
	// 					navigate("/");
	// 				} else if (
	// 					data.stateCode == "-999" ||
	// 					data.stateCode == "-900"
	// 				) {
	// 					// 탈퇴 회원
	// 					// TODO: 재가입 가능 일자
	// 					if (data.leaveDt) {
	// 						Util.dateFormat(data.leaveDt);
	// 					}
	// 					// TODO: 로그아웃, 메인 이동
	// 					setOpenAlert(true);
	// 				} else if (
	// 					data.stateCode == "-10" ||
	// 					data.stateCode == "-30"
	// 				) {
	// 					// 제한 회원
	// 				} else if (data.stateCode == "12") {
	// 					// 테스터
	// 					navigate("/");
	// 				} else {
	// 				}
	// 			});
	// 		},
	// 		onError: (errorResponse) => {
	// 			console.error(errorResponse);
	// 		},
	// 		flow: "auth-code",
	// 	});
	// 	return (
	// 		<GoogleLoginButton onClick={googleSocialLogin}>
	// 			<GoogleLogo src={google} />
	// 			<S.button_M>Google Log-in</S.button_M>
	// 		</GoogleLoginButton>
	// 	);
	// };
	const Login_test = () => {
		// const googleSocialLogin =
		Api.get("/auth/test", {}).then(({ data }) => {
			console.log(data.data.stateCode, "#@!#@!#@#????");
			const info = data.data;
			localStorage.setItem(
				"token",
				JSON.stringify({
					accessToken: info.accessToken,
					refreshToken: info.refreshToken,
				})
			);
			if (info.stateCode == "01") {
				// 약관 미동의 회원
				navigate("/MEMB002001");
			} else if (info.stateCode == "02") {
				// 닉네임 미설정 회원
				setOpenSetProfile(true);
				navigate("/");
			} else if (info.stateCode == "11") {
				// 정상 회원
				return navigate("/");
			} else if (info.stateCode == "-999" || info.stateCode == "-900") {
				// 탈퇴 회원
				// TODO: 재가입 가능 일자
				if (info.leaveDt) {
					Util.dateFormat(info.leaveDt);
				}
				// TODO: 로그아웃, 메인 이동
				setOpenAlert(true);
			} else if (info.stateCode == "-10" || info.stateCode == "-30") {
				// 제한 회원
			} else if (info.stateCode == "12") {
				// 테스터
				navigate("/");
			} else {
			}
		});
	};

	return (
		<Container>
			<Logo src={logo} />
			<button onClick={() => Login_test()}>
				<GoogleLogo src={google} />
				<S.button_M>Google Log-in</S.button_M>
			</button>
			{/* <LogoWrap>
				<Logo src={logo} />
			</LogoWrap>
			<ButtonWrap>{Login()}</ButtonWrap> */}
			{/* 재가입 제한 안내 */}
			<Modal
				isOpen={isOpenAlert}
				contents={
					<>
						<h2>
							<S.headline_4>
								Pemberitahuan pembatasan pendaftaran
							</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray900}>
								Anda tidak bisa mendaftar kembali menggunakan
								akun sama yang telah dihapus selama 60 hari.
							</S.bodyText_M_long>
						</p>
						<p>
							<S.bodyText_S color={S.color.gray700}>
								Tanggal bisa mendaftar kembali : 2023-07-12
							</S.bodyText_S>
						</p>
					</>
				}
				buttonTextL={"Memeriksa"}
				buttonActionL={() => setOpenAlert(false)}
			/>
		</Container>
	);
};
