// 약관
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";

const Container = styled.div`
	padding: 1.4rem;
`;

export default () => {
	// 황성화 탭 인덱스
	const [activeTab, setActiveTab] = useState<number>(0);

	return (
		<>
			<Page
				title="Syarat dan Ketentuan"
				contents={
					<>
						<TabMenu
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							tabs={[
								{
									index: 0,
									name: "syarat dan ketentuan",
									content: <></>,
								},
								{
									index: 1,
									name: "kebijakan privasi",
									content: <></>,
								},
							]}
						/>
					</>
				}
			/>
		</>
	);
};
