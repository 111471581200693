// 하단 메뉴바
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserAtom } from "../Recoil/Atoms/UserState";
import { LoginAtom } from "../Recoil/Atoms/PopupState";

import * as S from "../Styles/Style";

import navBg from "../Images/nav_bg.svg";
import navBg2 from "../Images/nav_bg2.svg";
import navBupo from "../Images/nav_bupo.svg";
import navHome from "../Images/nav_home.svg";
import navNewsroom from "../Images/nav_newsroom.svg";
import navExchange from "../Images/nav_exchange.svg";
import navChest from "../Images/nav_chest.svg";

const Container = styled.div`
	width: 100%;
	height: 0;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-shrink: 0;
	position: relative;
`;
const Bupo = styled.button`
	width: 6rem;
	height: 6.3rem;
	background-image: url(${navBupo});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	top: -10rem;
`;
const BGArea = styled.div`
	width: 100%;
	height: 6.3rem;
	position: absolute;
	bottom: 0;
	left: 0;
	&::before,
	&::after {
		content: "";
		display: block;
		background-image: url(${navBg2});
		background-size: auto 100%;
		position: absolute;
		left: 0;
		width: calc(50% - 10rem);
		height: 100%;
	}
	&::after {
		left: unset;
		right: 0;
	}
`;
const ButtonArea = styled.div`
	width: 100%;
	height: 6.3rem;
	display: flex;
	position: absolute;
	bottom: 0;
	left: 0;
	background-image: url(${navBg});
	background-size: auto 100%;
	background-position: bottom center;
	background-repeat: no-repeat;
	button {
		width: 20%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding-bottom: 0.5rem;
		img {
			height: 40%;
		}
		span {
			color: white;
		}
	}
`;

export default ({}) => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	// 팝업
	const [isOpenLogin, setOpenLogin] = useRecoilState<boolean>(LoginAtom);

	// 로그인 여부 확인 후 컨펌생성/화면이동 구분
	const loginCheck = (path: string, option?: {}) => {
		userState.mmIdx === 0 ? setOpenLogin(true) : navigate(path, option);
	};

	return (
		<Container>
			<Bupo
				onClick={() => {
					console.log(userState.goldboxCnt);
					loginCheck(
						userState.goldboxCnt > 0
							? "/BUPO001001"
							: "/POIN001001",
						{ replace: true }
					);
				}}
			/>
			<BGArea></BGArea>
			<ButtonArea>
				<button onClick={() => navigate("/", { replace: true })}>
					<img src={navHome} />
					<S.mainTitle_XS>Home</S.mainTitle_XS>
				</button>
				<button onClick={() => navigate("/NEWS001001")}>
					<img src={navNewsroom} />
					<S.mainTitle_XS>Newsroom</S.mainTitle_XS>
				</button>
				<button
					onClick={() => {
						console.log(userState.goldboxCnt);
						loginCheck(
							userState.goldboxCnt > 0
								? "/BUPO001001"
								: "/POIN001001",
							{ replace: true }
						);
					}}
				>
					<S.mainTitle_XS>Open</S.mainTitle_XS>
				</button>
				<button onClick={() => {}} style={{ opacity: 0.5 }}>
					<img src={navExchange} />
					<S.mainTitle_XS>Trade</S.mainTitle_XS>
				</button>
				<button onClick={() => loginCheck("/MYME002001")}>
					<img src={navChest} />
					<S.mainTitle_XS>Chest</S.mainTitle_XS>
				</button>
			</ButtonArea>
		</Container>
	);
};
