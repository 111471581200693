// 나의 문의
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
	ImageLargeViewAtom,
	ImageLargeViewUrlAtom,
} from "../Recoil/Atoms/PopupState";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";

import { ReactComponent as Plus } from "../Images/plus.svg";
import { ReactComponent as XIcon } from "../Images/x.svg";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	flex-shrink: 1;
	background-color: ${S.color.gray200};
`;
const InquiryItem = styled.div`
	width: 100%;
	padding: 2rem 1.4rem;
	border-bottom: 1px solid ${S.color.gray300};
	background-color: white;
`;
const InquiryInfo = styled.div`
	display: flex;
	margin-bottom: 1.2rem;
`;
const SpeechArea = styled.ul`
	height: 1rem;
	padding: 1.6rem 1.4rem 0;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	li {
		margin-bottom: 1.8rem;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		word-break: break-all;
	}
	li.answer {
		align-items: flex-start;
	}
	.explain {
		width: 100%;
		color: ${S.color.gray500};
		padding-bottom: 2rem;
		text-align: center;
	}
`;
const SpeechBubble = styled.div`
	flex-grow: 1;
	display: flex;
	max-width: 90%;
	padding: 1.6rem;
	background-color: white;
	border-radius: 1rem;
	margin-bottom: 0.6rem;
	p {
		flex-shrink: 0;
		margin-right: 0.2rem;
		span {
			line-height: 1.8rem;
		}
	}
	span {
		width: fit-content;
	}
`;
const SpeechBubbleImage = styled.div`
	width: 7.2rem;
	height: 7.2rem;
	overflow: hidden;
	position: relative;
	border-radius: 1rem;
	border: 1px solid ${S.color.gray100};
	margin-top: 1.2rem;
	margin-right: 1rem;
	&:last-child {
		margin-right: 0;
	}
`;
const AddQuestionArea = styled.div`
	padding: 2rem 1.4rem 0;
	background-color: white;
	border-radius: 2rem 2rem 0 0;
	h1 {
		margin-bottom: 1.2rem;
	}
`;
interface detailData {
	bqIdx: number;
	bqcIdx: string;
	comment: string;
	file1: string;
	file2: string;
	file3: string;
	regDt: string;
	regType: number;
}

export default () => {
	const navigate = useNavigate();
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const location = useLocation();
	const inquiryData = location.state ? location.state.data : {}; // 목록에서 가져온 기본 데이터
	const [detailData, setDetailData] = useState<detailData[]>([]); // 문의 상세 정보
	// 팝업
	const setOpenImageLargeView = useSetRecoilState(ImageLargeViewAtom);
	const setImageLargeViewUrl = useSetRecoilState(ImageLargeViewUrlAtom);
	// 추가질문
	const [formText, setFormText] = useState<string>("");
	// 얼럿 생성
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	// 업로드될 파일
	const [imgFile, setImgFile] = useState([]);
	// 업로드된 파일 개수
	const [fileCnt, setFileCnt] = useState<number>(0);
	// 문의 스크롤 제일하단 기본
	const scrollRef = useRef<HTMLUListElement | null>(null);
	// 종료된 문의 여부
	const isEnd = inquiryData.progressCode == "1";
	const options = [
		{ id: 1, text: "Menggunakan" }, // 이용 문의
		{ id: 2, text: "Pembayaran" }, // 결제 문의
		{ id: 4, text: "Pengiriman" }, // 배송 문의
		{ id: 7, text: "Anggota terendah" }, // 회원 문의
		{ id: 8, text: "Peristiwa" }, // 이벤트 문의
		{ id: 99, text: "Laporan kesalahan" }, // 오류제보
		{ id: 6, text: "dll" }, // 기타
	];

	useEffect(() => {
		// 제일 하단으로 스크롤 이동
		if (scrollRef.current) {
			scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
		}
		console.log(inquiryData);
	}, [detailData]);
	useEffect(() => {
		getDetail();
	}, []);
	const resetForm = () => {
		setFormText("");
		setImgFile([]);
		setFileCnt(0);
	};
	// 문의 상세 정보
	const getDetail = async () => {
		await Api.patch(`/board/qnaView`, {
			bqIdx: Number(inquiryData.bqIdx),
		})
			.then((res) => {
				if (res.data == undefined) {
					console.log(res.response);
				} else {
					console.log("getDetail : ", res.data.data);
					setDetailData(res.data.data);
				}
			})
			.catch(() => {});
	};
	// 답변 상태 확인
	const progressState = (progressCode) => {
		switch (progressCode) {
			case "1": // 답변 완료
				return (
					<S.subhead_4 color={S.color.gray700}>
						Jawaban selesai
					</S.subhead_4>
				);
			case "2": // 답변 중
				return (
					<S.subhead_4 color={S.color.primary}>Membalas</S.subhead_4>
				);
			case "0": // 답변 전
				return (
					<S.subhead_4 color={S.color.gray700}>
						Sebelum membalas
					</S.subhead_4>
				);
		}
	};
	// 파일 업로드
	const setThumbnail = (e) => {
		const fileArr = e.target.files;

		let maxFileCnt = 3; // 첨부파일 최대 개수
		let remainFileCnt = maxFileCnt - imgFile.length; // 추가로 첨부가능한 개수
		let curFileCnt = fileArr.length; // 현재 선택된 첨부파일 개수

		// 첨부파일 개수 확인
		if (curFileCnt > remainFileCnt) {
			setToastType(1);
			setToastContents("Maksimal 3 gambar dapat didaftarkan."); // 번역 확인 필요
			setOpenToast(true);
		}

		let fileURLs = [];
		let file;

		for (let i = 0; i < Math.min(curFileCnt, remainFileCnt); i++) {
			file = fileArr[i];
			let reader = new FileReader();
			// 20MB 용량 제한
			if (file.size > 20 * 1024 * 1024)
				return (
					setToastType(1),
					setToastContents(
						"File gambar hingga 20MB dapat dilampirkan." // 번역 확인 필요
					),
					setOpenToast(true)
				);
			reader.onload = () => {
				fileURLs[i] = reader.result;
				setImgFile([
					...imgFile,
					{ image: reader.result as string, file: file },
				]);
			};
			reader.readAsDataURL(file);
		}
	};
	useEffect(() => {
		setFileCnt(imgFile.length);
	}, [imgFile]);
	// 업로드 파일 미리보기 영역
	const ImageArea = imgFile.map((item, index) => {
		return (
			<div className="imageArea" key={index}>
				<img src={item.image} alt="" />
				<button
					onClick={() =>
						setImgFile(
							imgFile.filter(
								(element) => element.image !== item.image
							)
						)
					}
				>
					<XIcon fill="white" />
				</button>
			</div>
		);
	});
	// 추가 문의 등록
	const onSubmit = async () => {
		if (isLoading) return;
		if (formText === "") return;
		// 내용을 입력해주세요 토스트 생성
		setLoading(true);
		const formData = new FormData();
		formData.append("bqIdx", inquiryData.bqIdx);
		formData.append("comment", formText);
		imgFile.map((item, index) => {
			formData.append(`file${index + 1}`, item.file);
		});
		for (let key of formData.keys()) {
			console.log(key, ":", formData.get(key));
		}
		await Api.post("/board/replyInsert", formData)
			.then((res) => {
				if (res.data == undefined) {
					console.log(res.response);
					alert("실패");
				} else {
					if (res.data.statusCode == 200) {
						// 작성완료
						getDetail();
						resetForm();
					} else {
						console.log(res.data);
					}
				}
			})
			.catch((error) => {
				console.log(error.response.data);
			});
		setLoading(false);
	};
	// 이미지 리스트 가져오기
	const renderImage = (data) => {
		let result = [];
		for (let i = 1; i < 4; i++) {
			data[`file${i}`] &&
				result.push(
					<SpeechBubbleImage
						key={i}
						onClick={() => {
							setOpenImageLargeView(true);
							setImageLargeViewUrl(data[`file${i}`]);
						}}
					>
						<S.heightImg src={imgUrl + data[`file${i}`]} />
					</SpeechBubbleImage>
				);
		}
		return result;
	};
	// 상담분류 텍스트
	const categoryText = (categoryCode: string) => {
		return options.filter((item) => item.id === Number(categoryCode))[0]
			.text;
	};
	// 연속 줄바꿈 제한
	const textCheck = (text: string) => {
		var txt = text.replace(/\n\n\n$/gm, "\n");
		setFormText(txt);
	};

	return (
		<>
			<Page
				title="Pertanyaan Saya"
				backTo={"/HELP004001"}
				contents={
					<Container>
						<InquiryItem>
							<p style={{ marginBottom: "0.8rem" }}>
								<S.subhead_4 color={S.color.gray500}>
									{categoryText(inquiryData.categoryCode)}
								</S.subhead_4>
							</p>
							<InquiryInfo>
								<S.subhead_2 $ellipsis={3}>
									{inquiryData.questionSubject}
								</S.subhead_2>
							</InquiryInfo>
							<S.flexRowStart>
								<S.bodyText_S
									color={S.color.gray600}
									style={{ marginRight: "1rem" }}
								>
									{Util.dateFormat(inquiryData.regDt)}
								</S.bodyText_S>
								{progressState(inquiryData.progressCode)}
							</S.flexRowStart>
						</InquiryItem>
						<SpeechArea ref={scrollRef}>
							<li>
								<SpeechBubble>
									<p>
										<S.subhead_2 color={S.color.primary}>
											Q.
										</S.subhead_2>
									</p>
									<div>
										<S.bodyText_S>
											{inquiryData.questionText}
										</S.bodyText_S>
										<S.flexRowStart>
											{renderImage(inquiryData)}
										</S.flexRowStart>
									</div>
								</SpeechBubble>
								<S.bodyText_XS color={S.color.gray500}>
									{Util.dateFormat(inquiryData.regDt)}
								</S.bodyText_XS>
							</li>
							{detailData?.map((detailData, index) => {
								return (
									<li
										className={
											detailData.regType == 1
												? "answer"
												: ""
										}
										key={index}
									>
										<SpeechBubble>
											<p>
												{detailData.regType == 1 ? (
													<S.subhead_2
														color={
															S.color.secondary
														}
													>
														A.
													</S.subhead_2>
												) : (
													<S.subhead_2
														color={S.color.primary}
													>
														Q.
													</S.subhead_2>
												)}
											</p>
											<div>
												<S.bodyText_S>
													{detailData.comment}
												</S.bodyText_S>
												<S.flexRowStart>
													{renderImage(detailData)}
												</S.flexRowStart>
											</div>
										</SpeechBubble>
										<S.bodyText_XS color={S.color.gray500}>
											{Util.dateFormat(detailData.regDt)}
										</S.bodyText_XS>
									</li>
								);
							})}
							{inquiryData.progressCode == "2" && (
								<p className="explain">
									<S.bodyText_XS color={S.color.error}>
										Jika tidak ada pertanyaan lebih lanjut
										dalam waktu 24 jam setelah tanggapan
										{"\n"}administrator, pertanyaan akan
										ditutup secara otomatis adalah.
									</S.bodyText_XS>
								</p>
							)}
						</SpeechArea>
						{!isEnd && (
							<AddQuestionArea>
								<h1>
									<S.subhead_2>
										Pertanyaan tambahan
									</S.subhead_2>
								</h1>
								<S.inputField
									style={{ marginBottom: "1.2rem" }}
								>
									<div className="inputWrap">
										<textarea
											id="formText"
											rows={3}
											value={formText}
											onChange={(e) =>
												textCheck(e.target.value)
											}
											maxLength={1000}
											placeholder="Masukkan dalam 1,000 karakter."
										></textarea>
										<S.inputCount className="inTextArea">
											<S.bodyText_XS>
												{formText.length}/1000
											</S.bodyText_XS>
										</S.inputCount>
									</div>
								</S.inputField>
								<S.uploadFile>
									<input
										id="uploadFile"
										type="file"
										accept="image/jpg,image/png,image/jpeg,image/gif"
										onChange={setThumbnail}
										multiple
									/>
									<button
										id="addFileButton"
										onClick={() =>
											document
												.getElementById("uploadFile")
												.click()
										}
									>
										<Plus fill="white" />
										<S.bodyText_XS color="white">
											{fileCnt}
											/3
										</S.bodyText_XS>
									</button>
									<div id="imageContainer">{ImageArea}</div>
								</S.uploadFile>
								<p style={{ marginTop: "0.8rem" }}>
									<S.bodyText_XS color={S.color.gray600}>
										Anda bisa melampirkan maksimal 3 file
										foto ukuran masing-masing 20 MB dengan
										format GIF, PNG, JPG.
									</S.bodyText_XS>
								</p>
								<S.buttonPrimary_S onClick={() => onSubmit()}>
									<S.button_S>Masukkan pertanyaan</S.button_S>
								</S.buttonPrimary_S>
							</AddQuestionArea>
						)}
					</Container>
				}
				absolute={isEnd}
				button={isEnd ? "Tulis pertanyaan baru" : ""}
				buttonAction={() => navigate("/HELP004002")}
			/>
		</>
	);
};
