import { useState } from "react";
import styled from "styled-components";
import React, { PropsWithChildren } from "react";

import * as S from "../Styles/Style";

import captain from "../Images/popup_captain.png";
import bubble from "../Images/popup_bubble.png";
import bell from "../Images/popup_bell.png";
import rotate from "../Images/popup_rotate.png";
import { ReactComponent as XIcon } from "../Images/x.svg";

interface DefaultType {
	isOpen: boolean; // 팝업 생성 여부
	contents: React.ReactNode; // 팝업 내용
	icon?: string;
	close?: () => void; // 팝업 닫기
	buttonTextL?: string; // 왼쪽 버튼 내용
	buttonTextR?: string; // 오른쪽 버튼 내용
	buttonActionL?: () => void; // 왼쪽 버튼 액션
	buttonActionR?: () => void; // 오른쪽 버튼 액션
	buttonColumn?: boolean;
}

export default ({
	isOpen,
	contents,
	icon,
	close,
	buttonTextL,
	buttonTextR,
	buttonActionL,
	buttonActionR,
	buttonColumn,
}: PropsWithChildren<DefaultType>) => {
	return (
		<S.popupContainer $isOpen={isOpen}>
			<S.popupWrapper style={icon && { paddingTop: "6rem" }}>
				{icon && (
					<S.popupIcon>
						<img src={bubble} alt="" className="bubble" />
						<img src={captain} alt="" className="captain" />
						{icon == "bell" && (
							<img src={bell} alt="" className="icon" />
						)}
						{icon == "rotate" && (
							<img src={rotate} alt="" className="icon" />
						)}
					</S.popupIcon>
				)}
				{close && (
					<S.popupXIcon onClick={() => close()}>
						<XIcon fill={S.color.gray700} />
					</S.popupXIcon>
				)}

				{/* 내용 */}
				{contents}
				{/* 버튼 */}
				{buttonTextL && (
					<S.buttonWrap className={buttonColumn && "column"}>
						<S.buttonPrimary_S
							onClick={(e: React.MouseEvent) => {
								e.preventDefault();
								buttonActionL();
							}}
						>
							<S.button_S>
								{buttonTextL ? buttonTextL : "Batal"}
							</S.button_S>
						</S.buttonPrimary_S>
						{buttonTextR && (
							<S.buttonPrimary_S
								$variant
								onClick={(e: React.MouseEvent) => {
									e.preventDefault();
									buttonActionR();
								}}
							>
								<S.button_S>
									{buttonTextR ? buttonTextR : "Memeriksa"}
								</S.button_S>
							</S.buttonPrimary_S>
						)}
					</S.buttonWrap>
				)}
			</S.popupWrapper>
		</S.popupContainer>
	);
};
