// 배송 상세정보
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";

import test_1 from "../Images/test_1.png";

const StateBox = styled(S.flexRowCenter)`
	padding: 2rem 0;
	background-color: ${S.color.gray100};
`;
const InfoBox = styled(S.borderBox)`
	padding: 1.6rem;
	& > div {
		align-items: flex-start;
		/* margin-bottom: 0.8rem; */
		& > p {
			margin-right: 1rem;
		}
	}
`;

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	// 배송취소 팝업 생성
	const [isOpenCancel, setOpenCancel] = useState(false);
	// TODO: 배송상태 구분
	const deliveryState = "";
	return (
		<>
			<Page
				title="Ajukan pengiriman"
				contents={
					<S.pageContainer>
						<section>
							<S.treasureBox>
								<div>
									<img src={test_1} />
								</div>
								<p>
									<S.subhead_3 $ellipsis={1}>
										[Paul Brial] Anting cincin hoop tekstur
										Bling
									</S.subhead_3>
								</p>
							</S.treasureBox>
							<h1>
								<S.subhead_2>Status pengiriman</S.subhead_2>
							</h1>
							<StateBox>
								<S.bodyText_M_long color={S.color.primary}>
									Status Sedang meminta pengiriman.
								</S.bodyText_M_long>
							</StateBox>
							<h1>
								<S.subhead_2>Informasi pengiriman</S.subhead_2>
							</h1>
							<InfoBox>
								<S.flexRowStart
									style={{ marginBottom: "0.8rem" }}
								>
									<p>
										<S.subhead_4 color={S.color.gray500}>
											Alamat
										</S.subhead_4>
									</p>
									<div>
										<p>
											<S.subhead_4
												color={S.color.gray800}
											>
												Home
											</S.subhead_4>
										</p>
										<S.bodyText_S color={S.color.gray800}>
											Jalan Jenderal Gatot Subroto Kav. 57
											Jakarta Selatan 12950
										</S.bodyText_S>
									</div>
								</S.flexRowStart>
								<S.flexRowStart>
									<p>
										<S.subhead_4 color={S.color.gray500}>
											Kontak
										</S.subhead_4>
									</p>
									<S.bodyText_S>
										0062-18836432234
									</S.bodyText_S>
								</S.flexRowStart>
								<S.bar $size={"1.2rem"} />
								<div>
									<p style={{ marginBottom: "0.6rem" }}>
										<S.subhead_4 color={S.color.gray600}>
											Permintaan khusus pengiriman
										</S.subhead_4>
									</p>
									<S.bodyText_S color={S.color.gray800}>
										Jalan Jenderal Gatot Subroto Kav. 57
										Jakarta Selatan Permintaan khusus
										pengiriman
									</S.bodyText_S>
								</div>
							</InfoBox>
							<h1>
								<S.flexRowBetween>
									<S.subhead_2>
										Informasi Pembayaran
									</S.subhead_2>
									{/* TODO: 배송 취소 상태면 버튼 삭제 */}
									{true && (
										<S.borderBadge
											$large
											onClick={() => setOpenCancel(true)}
										>
											<S.bodyText_XS>
												Pembatalan pengiriman
											</S.bodyText_XS>
										</S.borderBadge>
									)}
								</S.flexRowBetween>
							</h1>
							<InfoBox>
								<S.flexRowBetween>
									<S.subhead_4 color={S.color.gray500}>
										Alamat
									</S.subhead_4>
									<S.bodyText_S color={S.color.gray800}>
										+ 2,200 IDR
									</S.bodyText_S>
								</S.flexRowBetween>
								<S.flexRowBetween
									style={{ margin: "0.8rem 0" }}
								>
									<S.subhead_4 color={S.color.gray500}>
										Kontak
									</S.subhead_4>
									<S.bodyText_S color={S.color.gray800}>
										+ 300 P
									</S.bodyText_S>
								</S.flexRowBetween>
								<S.flexRowBetween>
									<S.subhead_4 color={S.color.gray500}>
										Cara pembayaran
									</S.subhead_4>
									<S.bodyText_S color={S.color.gray800}>
										Kartu Kredit
									</S.bodyText_S>
								</S.flexRowBetween>
							</InfoBox>
						</section>
						<section>
							<h2>
								<S.subhead_3 color={S.color.gray800}>
									Pemberitahuan pembayaran biaya pengiriman
								</S.subhead_3>
							</h2>
							<S.textList $gray>
								<li>
									<S.bodyText_S>
										Pengajuan sampai jam 3 sore pada hari
										kerja akan dikirim hari itu juga,
										pengajuan setelahnya akan dikirim pada
										hari kerja setelahnya.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Bisa diperlukan waktu untuk persiapan
										pengiriman tergantu pada sifat produk
										dan ketersediaannya.
									</S.bodyText_S>
								</li>
								<li>
									<S.bodyText_S>
										Pengiriman tidak bisa dilakukan atau
										diperlukan biaya pengiriman tambahan
										untuk Jeju/daerah pulau.
									</S.bodyText_S>
								</li>
							</S.textList>
						</section>
					</S.pageContainer>
				}
			/>
			<Modal
				isOpen={isOpenCancel}
				contents={
					<>
						<h2>
							<S.headline_4>Batalkan pengiriman</S.headline_4>
						</h2>
						<S.bodyText_M_long color={S.color.gray800}>
							Apakah Anda ingin membatalkan permintaan pengiriman?
							{"\n"}
							Jumlah pembayaran yang digunakan adalah Pengembalian
							dana akan dilakukan dalam waktu maksimal 5 hari
							kerja dari perusahaan kartu kredit atau bank.{"\n"}
							Poin akan segera dikembalikan.
						</S.bodyText_M_long>
					</>
				}
				buttonTextL="Batal"
				buttonActionL={() => setOpenCancel(false)}
				buttonTextR="Memeriksa"
				buttonActionR={() => {}}
			/>
		</>
	);
};
