// 뉴스룸
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import styled from "styled-components";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom, EmphasizePrice } from "../Recoil/Atoms/UserState";
import {
	ImageLargeViewAtom,
	ImageLargeViewUrlAtom,
	TRSRAtom,
	TRSRIdxAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import NoList from "../Components/NoList";
import RefreshHandler from "../Components/RefreshHandler";

import defaultProfile from "../Images/default_profile.png";
import tierBadgeGold from "../Images/tier_badge_gold.png";
import newsTitle from "../Images/news_title.png";

const PresentName = styled(S.subhead_3)`
	margin: 1rem 0 0.4rem;
`;

interface NewsData {
	nmIdx: number;
	bmIdx: number;
	pmIdx: number;
	mmIdx: number;
	newsfeedTypeCode: number;
	newsfeedTypeName: string;
	useYn: number;
	displayYn: number;
	regIdx: string;
	regIp: string;
	regDt: string;
	modIdx: number;
	modIp: string;
	modDt: string;
	presentName: string;
	presentImageUrl: string;
	displayPrice: number;
	nick: string;
	profileImageUrl: string;
}

export default () => {
	const windowSize = useRef([window.innerWidth, window.innerHeight]);
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	const emphasizePrice = useRecoilValue(EmphasizePrice);
	// 보물상세정보 팝업 생성
	const setOpenTRSR = useSetRecoilState<boolean>(TRSRAtom);
	const SetTRSRIdx = useSetRecoilState<number>(TRSRIdxAtom);
	// 팝업
	const setOpenImageLargeView = useSetRecoilState(ImageLargeViewAtom);
	const setImageLargeViewUrl = useSetRecoilState(ImageLargeViewUrlAtom);
	// 뉴스룸 리스트
	const [newsDatas, setNewsDatas] = useState<NewsData[]>([]);
	const [hasNextPage, setHasNextPage] = useState<boolean>(true);
	const page = useRef<number>(1);
	const observerTargetEl = useRef<HTMLDivElement>(null);

	// 뉴스룸 목록 리셋
	const newsReset = () => {
		setNewsDatas([]);
		setHasNextPage(true);
		page.current = 1;
	};
	// 뉴스룸 목록 조회
	const fetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("newsroomFetch");
		await Api.get("/newsfeed/list", { page: page.current, limit: 10 })
			.then((res) => {
				if (res.data == undefined) {
					console.log(res);
				} else {
					const data = res.data.data.list;
					console.log(data);
					setNewsDatas((prevDatas) => [...prevDatas, ...data]);
					setHasNextPage(data.length === 10);
					if (data.length) {
						page.current += 1;
					}
				}
			})
			.catch((error) => {
				console.error(error.response.data);
			});
		setLoading(false);
	};
	// 프로필 표시될 이미지
	const profileImage = (profileImage) => {
		if (profileImage !== "") {
			if (profileImage.includes("http")) {
				return profileImage;
			} else {
				return imgUrl + profileImage;
			}
		} else {
			return defaultProfile;
		}
	};

	useEffect(() => {
		if (!observerTargetEl.current || !hasNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				fetch();
			}
		});
		io.observe(observerTargetEl.current);

		return () => {
			io.disconnect();
		};
	}, [hasNextPage]);

	return (
		<>
			<Page
				title="News Room"
				contents={
					<RefreshHandler
						handleRefresh={() => {
							newsReset();
						}}
						contents={
							<S.wrapper>
								{newsDatas.length === 0 ||
								newsDatas === undefined ? (
									<NoList />
								) : (
									newsDatas?.map((newsData) => (
										<S.listItem
											style={{
												margin:
													newsData.displayPrice >=
													emphasizePrice
														? "0"
														: "0 1.4rem",
											}}
											key={newsData.nmIdx}
											$emphasize={
												newsData.displayPrice >=
												emphasizePrice
													? true
													: false
											}
										>
											<h1>
												<img src={newsTitle} alt="" />
											</h1>
											<div className="itemWrap">
												<div
													className="itemImage"
													onClick={() => {
														setOpenTRSR(true);
														SetTRSRIdx(
															newsData.pmIdx
														);
													}}
												>
													<S.heightImg
														// 상품 이미지 등록 전
														src={
															imgUrl +
															newsData.presentImageUrl
														}
													/>
												</div>
												<div className="itemInfo">
													<S.flexRowStart className="userInfoArea">
														<S.userImageArea>
															<S.userImage
																$large={
																	newsData.displayPrice >=
																	emphasizePrice
																}
																onClick={() => {
																	setOpenImageLargeView(
																		true
																	);
																	setImageLargeViewUrl(
																		newsData.profileImageUrl
																	);
																}}
															>
																<S.heightImg
																	src={profileImage(
																		newsData.profileImageUrl
																	)}
																/>
															</S.userImage>
															{/* <S.userTier
																// TODO: 회원 등급에 따라 뱃지 변경
																$large={
																	newsData.displayPrice >=
																	emphasizePrice
																}
																$url={
																	tierBadgeGold
																}
															/> */}
														</S.userImageArea>
														{newsData.displayPrice >=
														emphasizePrice ? (
															<S.subhead_4
																color="white"
																className="nick"
															>
																{newsData.nick}
															</S.subhead_4>
														) : (
															<S.bodyText_XS
																color={
																	S.color
																		.gray400
																}
															>
																{newsData.nick}
															</S.bodyText_XS>
														)}
													</S.flexRowStart>
													{newsData.displayPrice >=
													emphasizePrice ? (
														<S.subhead_3
															onClick={() => {
																setOpenTRSR(
																	true
																);
																SetTRSRIdx(
																	newsData.pmIdx
																);
															}}
															color="white"
															$ellipsis={2}
															style={{
																marginTop:
																	"1.2rem",
															}}
														>
															{
																newsData.presentName
															}
														</S.subhead_3>
													) : (
														<PresentName
															onClick={() => {
																setOpenTRSR(
																	true
																);
																SetTRSRIdx(
																	newsData.pmIdx
																);
															}}
															$ellipsis={2}
														>
															{
																newsData.presentName
															}
														</PresentName>
													)}
													{newsData.displayPrice >=
													emphasizePrice ? (
														<p>
															<S.bodyText_S
																style={{
																	marginRight:
																		"0.6rem",
																}}
																color={
																	S.color
																		.point03
																}
															>
																Harga pas
															</S.bodyText_S>
															<S.subhead_3
																color={
																	S.color
																		.point03
																}
															>
																{Util.addComma(
																	newsData.displayPrice
																)}{" "}
																IDR
															</S.subhead_3>
														</p>
													) : (
														<S.bodyText_XS
															color={
																S.color.gray600
															}
														>
															Harga pas &nbsp;|{" "}
															&nbsp;
															{Util.addComma(
																newsData.displayPrice
															)}{" "}
															IDR
														</S.bodyText_XS>
													)}
												</div>
											</div>
										</S.listItem>
									))
								)}
								<div ref={observerTargetEl} />
							</S.wrapper>
						}
					/>
				}
				bottomNav={true}
			/>
		</>
	);
};
