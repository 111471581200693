// 보물상세정보
import React, { PropsWithChildren, useEffect, useState } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { UserAtom } from "../Recoil/Atoms/UserState";
import { LoginAtom, TRSRAtom, TRSRIdxAtom } from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";

import frame from "../Images/frame.png";
import xWhite from "../Images/x_white.png";
import chestGoldS from "../Images/chest_gold_s.png";
import { ReactComponent as Chevron } from "../Images/chevron.svg";

const Container = styled.div`
	padding: 1.4rem;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
`;
const AbsoluteButtonWrap = styled.div`
	width: 100%;
	padding: 1.4rem;
	position: absolute;
	bottom: 0;
	background: linear-gradient(transparent 0%, white 20%);
`;
const TreasureImage = styled(S.borderBox)`
	height: 15rem;
	overflow: hidden;
	position: relative;
	& > img {
		height: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.treasureChest {
		position: absolute;
		right: 0;
		bottom: 0;
		display: flex;
		padding: 0.5rem;
		img {
			height: 1.8rem;
			margin-left: 0.4rem;
		}
	}
`;

interface PresentData {
	buyUrl: string;
	categoryCode: string;
	description: string;
	displayDt: string;
	displayPrice: number;
	displayYn: number;
	gifticonCode: string;
	gmIdx: number;
	gradeCode: string;
	imageUrl: string;
	limitCount: string;
	linkUrl: string;
	memo: string;
	modDt: string;
	modIdx: number;
	modelNumber: string;
	name: string;
	notifyYn: number;
	pmIdx: string;
	presentOptions: string;
	realPrice: number;
	regDt: string;
	regIdx: number;
	registType: string;
	typeCode: string;
	presentDescription: string;
}

export default () => {
	const navigate = useNavigate();
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const userState = useRecoilValue(UserAtom);
	const [isOpenTRSR, setOpenTRSR] = useRecoilState<boolean>(TRSRAtom);
	const [TRSRIdx, setTRSTIdx] = useRecoilState<number>(TRSRIdxAtom);
	// 팝업
	const setOpenLogin = useSetRecoilState<boolean>(LoginAtom);

	// 로그인 여부 확인 후 컨펌생성/화면이동 구분
	const loginCheck = (path, option?) => {
		userState.mmIdx === 0 ? setOpenLogin(true) : navigate(path, option);
	};

	// 보물 상세 정보
	const [presentData, setPresentData] = useState<PresentData>();

	// 보물 상세 정보
	const presentView = async () => {
		if (TRSRIdx === null) {
			return false;
		}
		await Api.get(`/present/view/${TRSRIdx}`)
			.then((res) => {
				const data = res.data.data;
				console.log(data);
				setPresentData(data);
			})
			.catch((error) => {
				console.log(error.response.data);
			});
	};
	useEffect(() => {
		if (TRSRIdx !== undefined) {
			presentView();
		}
	}, [TRSRIdx]);

	return (
		<S.popupContainer
			$isOpen={isOpenTRSR}
			style={{ backgroundColor: "white" }}
		>
			<S.page>
				<S.pageTitle>
					<button
						className="close"
						onClick={() => {
							setPresentData(null);
							setTRSTIdx(null);
							setOpenTRSR(false);
						}}
					>
						<img src={xWhite} />
					</button>
					<S.mainTitle_M color="white">
						Informasi terperinci harta karun
					</S.mainTitle_M>
				</S.pageTitle>
				<Container style={{ paddingBottom: "10rem" }}>
					<TreasureImage>
						<img src={imgUrl + presentData?.imageUrl} />
						<div className="treasureChest">
							{/* TODO: 획득 기준에 따른 분류 필요 */}
							<img src={chestGoldS} />
						</div>
					</TreasureImage>
					<div style={{ margin: "1.4rem 0 0.5rem" }}>
						<S.bodyText_M_long>
							{presentData?.name}
						</S.bodyText_M_long>
					</div>
					<div>
						<S.bodyText_XS color={S.color.gray500}>
							Harga pas &nbsp;| &nbsp;
							{Util.addComma(presentData?.displayPrice)} IDR
						</S.bodyText_XS>
					</div>
					{/* 상세정보 이동 버튼 */}
					{presentData?.linkUrl && (
						<S.buttonPrimary_B
							onClick={() => window.open(presentData?.linkUrl)}
							style={{
								borderRadius: "0.6rem",
								marginTop: "3.4rem",
							}}
						>
							<S.bodyText_M_long color={S.color.primary}>
								Informasi lengkap pada pergerakan
							</S.bodyText_M_long>
							<Chevron height="2rem" fill={S.color.primary} />
						</S.buttonPrimary_B>
					)}
					<S.treasureInfo
						style={{
							marginTop: "3.4rem",
						}}
					>
						{/* <img src={test_1_2} className="brand" /> */}
						<S.headline_2>{presentData?.name}</S.headline_2>
						<S.bodyText_L color={S.color.gray700}>
							{presentData?.modelNumber}{" "}
							{presentData?.presentOptions}
						</S.bodyText_L>

						<img src={frame} className="frame" />
						<div className="detail">
							<img src={imgUrl + presentData?.imageUrl} />
						</div>

						<div className="detailInfo">
							<p>
								<S.headline_4 color="white">
									Detil Deskripsi
								</S.headline_4>
							</p>
							<div
								dangerouslySetInnerHTML={{
									__html: presentData?.description,
								}}
							></div>
						</div>
					</S.treasureInfo>
					<S.bodyText_S color={S.color.gray400}>
						{presentData?.presentDescription}
					</S.bodyText_S>
					{/* <S.textList>
						<li>
							<S.bodyText_S color={S.color.gray400}>
								Informasi lengkap pada halaman terperinci hanya
								sekedar referensi dan mengacu pada informasi
								yang kami dapat saat mendaftarkan barang. Oleh
								sebab itu bisa terdapat perbedaan pada barang
								yang asli tergantung pada perubahan spesifikasi
								dari produsen.
							</S.bodyText_S>
						</li>
						<li>
							<S.bodyText_S color={S.color.gray400}>
								Apabila terjadi masalah pada ketersediaan,
								barang yang dikirim bisa diganti dengan barang
								lain yang memiliki fungsi atau level yang sama.
							</S.bodyText_S>
						</li>
					</S.textList> */}
				</Container>
				<AbsoluteButtonWrap>
					<S.buttonPrimary
						onClick={() => {
							setOpenTRSR(false);
							loginCheck(
								userState.goldboxCnt > 0
									? "/BUPO001001"
									: "/POIN001001"
							);
						}}
					>
						Undian Harta Karun
					</S.buttonPrimary>
				</AbsoluteButtonWrap>
			</S.page>
		</S.popupContainer>
	);
};
