// 내보물함
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingAtom } from "../Recoil/Atoms/UserState";
import {
	ToastAtom,
	ToastTypeAtom,
	ToastContentsAtom,
} from "../Recoil/Atoms/PopupState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import TabMenu from "../Components/TabMenu";
import NoList from "../Components/NoList";
import Filter from "../Components/Filter";
import RefreshHandler from "../Components/RefreshHandler";

import lock from "../Images/lock.png";
import unlock from "../Images/unlock.png";
import search from "../Images/search.png";
import sliders from "../Images/sliders.png";

const MyButton = styled.button<{ disabled?: boolean }>`
	width: calc(100% / 3 - 0.6rem);
	height: 3.4rem;
	border: 1px solid ${S.color.gray400};
	border-color: ${(props) => props.disabled && S.color.gray300};
	border-radius: 0.6rem;
	margin-top: 1.4rem;
	background-color: ${(props) => props.disabled && S.color.gray100};
	span {
		white-space: nowrap;
		color: ${(props) => props.disabled && S.color.gray400};
	}
`;

interface Data {
	boxName: string;
	boxPrice: number;
	pumIdx: number;
	bmIdx: number;
	pmIdx: number;
	stateCode: string;
	regDt: string;
	modDt: string;
	lockYn: number;
	useYn: number;
	name: string;
	imageUrl: string;
	typeCode: string;
	displayPrice: number;
	realPrice: number;
	categoryCode: string;
	LEN_NAME: string;
	exIdx: number;
	pdmIdx: string;
	shortName: string;
}

export default () => {
	const navigate = useNavigate();
	const location = useLocation();
	const imgUrl = process.env.REACT_APP_IMG_URL;
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 황성화 탭 인덱스
	const defaultTab = location.state ? location.state.data : 0;
	const [activeTab, setActiveTab] = useState<number>(defaultTab);
	// 필터 모달 생성
	const [isOpenFilterA, setOpenFilterA] = useState<boolean>(false);
	const [isOpenFilterB, setOpenFilterB] = useState<boolean>(false);
	// 잠금 상태 변경 토스트 팝업
	const setOpenToast = useSetRecoilState<boolean>(ToastAtom);
	const setToastType = useSetRecoilState<number>(ToastTypeAtom);
	const setToastContents = useSetRecoilState<string>(ToastContentsAtom);
	const [isLocked, setLocked] = useState<boolean>(false);
	// 내보물함 리스트 정보
	const [myDatas, setMyDatas] = useState<Data[]>([]);
	const [myNextPage, setMyNextPage] = useState<boolean>(true);
	const myPage = useRef<number>(1);
	const myObserver = useRef<HTMLDivElement>(null);
	// 내배송리스트 정보
	const [deliveryDatas, setDeliveryDatas] = useState<Data[]>([]);
	const [deliveryNextPage, setDeliveryNextPage] = useState<boolean>(true);
	const deliveryPage = useRef<number>(1);
	const deliveryObserver = useRef<HTMLDivElement>(null);
	// 검색
	// A
	const [filterA, setFilterA] = useState<string[]>([]);
	const filterRefA = useRef<string[]>([]);
	const defaultOrderBy = 0;
	const [orderByA, setOrderByA] = useState<number>(0);
	const orderByRefA = useRef<number>(null);
	const [searchYn, setSearchYn] = useState<boolean>(false);
	const searchYnRef = useRef<boolean>(false);
	const [keyword, setKeyword] = useState<string>("");
	const keywordRef = useRef<string>("");
	// B
	const [filterB, setFilterB] = useState<string[]>([]);
	const filterRefB = useRef<string[]>([]);
	const [orderByB, setOrderByB] = useState<number>(0);
	const orderByRefB = useRef<number>(null);
	// 거래취소/배송취소
	// 거래/분해 불가
	//
	// 내보물함 리셋
	const myReset = () => {
		setMyDatas([]);
		setMyNextPage(true);
		myPage.current = 1;
	};
	// 내배송리스트 리셋
	const deliveryReset = () => {
		setDeliveryDatas([]);
		setDeliveryNextPage(true);
		deliveryPage.current = 1;
	};
	// 내 보물함 목록 조회
	const myFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("myFetch", keywordRef.current);
		await Api.get(`/present/memberPresentList`, {
			page: myPage.current,
			filter:
				filterRefA.current.length > 0
					? filterRefA.current.join()
					: null,
			orderBy: orderByRefA.current ?? orderByA,
			stateCode: "00, 01, 02, 03",
			keyword: keywordRef.current === "" ? undefined : keywordRef.current,
		})
			.then((res) => {
				// console.log(res);
				const data = res.data.data.list;
				setMyDatas((prevDatas) => [...prevDatas, ...data]);
				setMyNextPage(data.length === 10);
				if (data.length) {
					myPage.current += 1;
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
		setLoading(false);
	};
	// 내 배송함 목록 조회
	const deliveryFetch = async () => {
		if (isLoading) return;
		setLoading(true);
		console.log("deliveryFetch", filterRefB.current);
		await Api.get(`/present/memberPresentList`, {
			page: deliveryPage.current,
			filter:
				filterRefB.current.length > 0
					? filterRefB.current.join()
					: null,
			orderBy: orderByRefB.current ?? orderByB,
			stateCode: "40, 41, 42, 43, 44, 45",
		})
			.then((res) => {
				// console.log(res);
				const data = res.data.data.list;
				setDeliveryDatas((prevDatas) => [...prevDatas, ...data]);
				setDeliveryNextPage(data.length === 10);
				if (data.length) {
					deliveryPage.current += 1;
				}
			})
			.catch((error) => {
				console.log(error.response);
			});
		setLoading(false);
	};
	useEffect(() => {
		if (!myObserver.current || !myNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				myFetch();
			}
		});
		io.observe(myObserver.current);

		return () => {
			io.disconnect();
		};
	}, [myNextPage, activeTab]);
	useEffect(() => {
		if (!deliveryObserver.current || !deliveryNextPage) return;

		const io = new IntersectionObserver((entries, observer) => {
			if (entries[0].isIntersecting) {
				deliveryFetch();
			}
		});
		io.observe(deliveryObserver.current);

		return () => {
			io.disconnect();
		};
	}, [deliveryNextPage, activeTab]);
	// 리스트 리셋
	useEffect(() => {
		if (activeTab === 0) {
			filterRefA.current = filterA;
			orderByRefA.current = orderByA;
			myReset();
		} else {
			filterRefB.current = filterB;
			orderByRefB.current = orderByB;
			deliveryReset();
		}
	}, [activeTab, orderByA, filterA, orderByB, filterB]);
	// 내 보물함 잠금
	const presentLockUpdate = async (
		pumIdx: number,
		lockYn: number,
		index: number
	) => {
		console.log(pumIdx, lockYn === 1 ? 0 : 1);
		await Api.patch(`/present/memberPresentLockUpdate`, {
			pumIdx: pumIdx,
			lockYn: lockYn === 1 ? 0 : 1,
		})
			.then((res) => {
				console.log(res);
				// 잠금 결과 팝업 생성
				setLocked(lockYn === 1);
				setToastType(0);
				setToastContents(
					lockYn === 1 ? "Kunci telah terbuka." : "Telah terkunci."
				);
				setOpenToast(true);

				// 리셋 없이 잠금 상태만 변경
				const newData = [...myDatas];
				newData[index].lockYn = lockYn === 1 ? 0 : 1;
				setMyDatas(newData);
			})
			.catch((error) => {
				console.log(error.data);
			});
	};
	// 검색
	const searchAction = () => {
		if (isLoading) return;
		// 앞 공백 제거
		const searchKeyword = (
			document.getElementById("keyword") as HTMLInputElement
		).value.replace(/^\s*/, "");
		// 검색어 지우고 검색 버튼 클릭 시 새로고침
		if (searchKeyword === "")
			return setKeyword(""), (keywordRef.current = ""), myReset();
		// 변수에 검색어 할당
		keywordRef.current = searchKeyword;
		setKeyword(searchKeyword);
		(document.getElementById("keyword") as HTMLInputElement).value =
			searchKeyword;
		// 검색 실행
		setSearchYn(true);
		searchYnRef.current = true;
		myReset();
	};
	// 내보물함 리스트
	const RenderItem = ({ data, type, index }) => {
		return (
			<S.listItem
				key={data.pumIdx}
				onClick={() =>
					type == "delivery" &&
					navigate("/DLVR002001", {
						state: {
							item: data,
						},
					})
				}
			>
				<div className="itemWrap">
					<div className="itemImage">
						<S.heightImg src={imgUrl + data.imageUrl} />
					</div>
					<div className="itemInfo">
						<S.bodyText_XS
							color={S.color.gray500}
							style={{ margin: "0.4rem 0 1.4rem" }}
						>
							{Util.dateFormat(data.modDt, "date")}
						</S.bodyText_XS>
						<S.subhead_3 $ellipsis={2}>{data.name}</S.subhead_3>
						<S.bodyText_XS color={S.color.gray500}>
							Harga pas &nbsp;| &nbsp;
							{Util.addComma(data.displayPrice)} IDR
						</S.bodyText_XS>
					</div>
					<S.handlingButtonArea>
						{type == "my" && (
							<S.handlingButton
								onClick={() => {
									presentLockUpdate(
										data.pumIdx,
										data.lockYn,
										index
									);
								}}
								$url={data.lockYn === 1 ? lock : unlock}
							></S.handlingButton>
						)}
						{type == "delivery" && (
							<S.badge>
								<S.bodyText_XS color={S.color.primary}>
									Permintaan pengiriman
								</S.bodyText_XS>
							</S.badge>
						)}
					</S.handlingButtonArea>
				</div>
				{type == "my" && (
					<S.flexRowBetween>
						<MyButton
							// TODO: 배송중, 배송완료인 경우 상세정보로 이동
							onClick={() =>
								navigate("/DLVR001001", {
									state: {
										item: data,
									},
								})
							}
						>
							<S.bodyText_S color={S.color.gray700}>
								Untuk mengirim
							</S.bodyText_S>
						</MyButton>
						<MyButton disabled>
							<S.bodyText_S color={S.color.gray700}>
								Transaksi
							</S.bodyText_S>
						</MyButton>
						<MyButton
							onClick={() => navigate("/UPGD001001")}
							disabled={data.lockYn === 1}
						>
							<S.bodyText_S color={S.color.gray700}>
								Pisahkan
							</S.bodyText_S>
						</MyButton>
					</S.flexRowBetween>
				)}
			</S.listItem>
		);
	};

	return (
		<>
			<Page
				title="Harta Karun Saya"
				contents={
					<TabMenu
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						tabs={[
							{
								index: 0,
								name: "Peti harta karun",
								content: (
									<S.wrapper>
										{/* 내보물함 */}
										{/* 검색 */}
										<S.searchArea>
											<div className="searchBox">
												<input
													id="keyword"
													type="text"
													placeholder="kata pencarian"
												/>
												<S.searchButton
													onClick={() =>
														searchAction()
													}
												>
													<img src={search} />
												</S.searchButton>
											</div>
											<S.searchFilter
												onClick={() => {
													if (isLoading) return;
													setOpenFilterA(true);
												}}
											>
												<img src={sliders} />
												{filterA.length > 0 && (
													<p>
														<S.bodyText_XS color="white">
															{filterA.length}
														</S.bodyText_XS>
													</p>
												)}
											</S.searchFilter>
										</S.searchArea>
										<RefreshHandler
											handleRefresh={() => {
												myReset();
											}}
											contents={
												<S.wrapper>
													{myDatas.length === 0 ||
													myDatas === undefined ? (
														<NoList
															searchKeyword={
																keyword
															}
														/>
													) : (
														<S.presentList>
															{myDatas?.map(
																(
																	data,
																	index
																) => (
																	<RenderItem
																		key={
																			index
																		}
																		data={
																			data
																		}
																		type="my"
																		index={
																			index
																		}
																	/>
																)
															)}
														</S.presentList>
													)}
													<div ref={myObserver} />
												</S.wrapper>
											}
										/>
									</S.wrapper>
								),
							},
							{
								index: 1,
								name: "Status pengiriman",
								content: (
									<S.wrapper>
										<S.flexRowBetween
											style={{
												margin: "2rem 1.4rem 0",
											}}
										>
											<S.bodyText_S
												color={S.color.gray600}
											>
												Riwayat pengiriman selama 3
												bulan terakhir
											</S.bodyText_S>
											<S.searchFilter
												onClick={() => {
													if (isLoading) return;
													setOpenFilterB(true);
												}}
											>
												<img src={sliders} />
												{filterB.length > 0 && (
													<p>
														<S.bodyText_XS color="white">
															{filterB.length}
														</S.bodyText_XS>
													</p>
												)}
											</S.searchFilter>
										</S.flexRowBetween>
										<RefreshHandler
											handleRefresh={() => {
												deliveryReset();
											}}
											contents={
												<S.wrapper>
													{deliveryDatas.length ===
														0 ||
													deliveryDatas ===
														undefined ? (
														<NoList />
													) : (
														<S.presentList>
															{deliveryDatas?.map(
																(
																	data,
																	index
																) => (
																	<RenderItem
																		key={
																			index
																		}
																		data={
																			data
																		}
																		type="delivery"
																		index={
																			index
																		}
																	/>
																)
															)}
														</S.presentList>
													)}
													<div
														ref={deliveryObserver}
													/>
												</S.wrapper>
											}
										/>
									</S.wrapper>
								),
							},
						]}
					/>
				}
				bottomNav={true}
			/>
			<Filter
				division={0}
				isOpen={isOpenFilterA}
				close={() => setOpenFilterA(false)}
				filter={filterA}
				setFilter={setFilterA}
				defaultOrderBy={defaultOrderBy}
				orderBy={orderByA}
				setOrderBy={setOrderByA}
			/>
			<Filter
				division={1}
				isOpen={isOpenFilterB}
				close={() => setOpenFilterB(false)}
				filter={filterB}
				setFilter={setFilterB}
				defaultOrderBy={defaultOrderBy}
				orderBy={orderByB}
				setOrderBy={setOrderByB}
			/>
		</>
	);
};
