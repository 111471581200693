// 공통 스타일
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import * as S from "../Styles/Style";

import concertOneRegular from "../Fonts/ConcertOne-Regular.woff";
import RobotoBold from "../Fonts/Roboto-Bold.woff";
import RobotoMedium from "../Fonts/Roboto-Medium.woff";
import RobotoRegular from "../Fonts/Roboto-Regular.woff";

const GlobalStyle = createGlobalStyle`
	/* MainTitle font */
	@font-face {
        font-family: 'concertOneRegular';
        src: local('concertOneRegular'), local('concertOneRegular');
        font-style: normal;
        src: url(${concertOneRegular}) format('truetype');
  	}
	/* Body font */
	@font-face {
        font-family: 'RobotoBold';
        src: local('RobotoBold'), local('RobotoBold');
        font-style: normal;
        src: url(${RobotoBold}) format('truetype');
  	}
	@font-face {
        font-family: 'RobotoMedium';
        src: local('RobotoMedium'), local('RobotoMedium');
        font-style: normal;
        src: url(${RobotoMedium}) format('truetype');
  	}
	@font-face {
        font-family: 'RobotoRegular';
        src: local('RobotoRegular'), local('RobotoRegular');
        font-style: normal;
        src: url(${RobotoRegular}) format('truetype');
  	}

	${reset};
 
	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		background-color: transparent;
		font-family: "RobotoRegular";
		font-size: 1.4rem;
		border: 0;
		outline: none;
		-ms-overflow-style: none;
		scrollbar-width: none;
	}
	*::-webkit-scrollbar {
    display: none; 
	}
	html, body, #root {
		height: 100%;
		overflow: hidden;
		font-size: 62.5%
	}
	@media (max-width: 330px) {
		html {
		font-size: 52.5%
		}
	}
	button, label {
		border: 0;
		background-color: transparent;
	}
	p, span, button, input {
		white-space: pre-line;
	}
	textarea {
		resize: none;
	}
	p, span {
		text-align: left;
	}
	input::placeholder {
		color: ${S.color.gray400};
	}
	textarea::placeholder {
		color: ${S.color.gray400};
	}
	.block {
		display: block;
	}
`;

export default GlobalStyle;
