// 보물상자 오픈
import React, { useState, useEffect, useRef } from "react";
import Api from "../libs/api";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useRecoilState, useRecoilValue } from "recoil";
import { UserAtom, LoadingAtom } from "../Recoil/Atoms/UserState";
import Util from "../Store/Util";

import * as S from "../Styles/Style";
import Page from "../Components/Page";
import Modal from "../Components/Modal";

import boxOpenBg from "../Images/box_open_bg.png";
import chestGold from "../Images/chest_gold.png";
import { ReactComponent as XIcon } from "../Images/x.svg";
import { ReactComponent as Help } from "../Images/question_circle_fill.svg";
import api from "../libs/api";

const Container = styled.div`
	flex-grow: 1;
	background-image: url(${boxOpenBg});
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 2rem 1.4rem;
`;
const BoxSelectArea = styled(S.flexRowCenter)`
	button {
		width: 18rem;
		padding-bottom: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img {
		height: 10rem;
		margin-bottom: 0.6rem;
	}
	p {
		width: 100%;
		padding: 0.4rem 0;
		background-color: ${S.color.orange800};
		border: 1px solid ${S.color.orange900};
		box-shadow: 0 0.2rem 0.1rem ${S.color.orange900};
		border-radius: 0.6rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		span {
			display: block;
			text-align: center;
		}
	}
`;
const OpenArea = styled.div`
	padding: 1.5rem 2rem 2rem;
	border-radius: 1rem;
	border: 2px solid #d35e2f66;
	background-color: ${S.color.gray100};
	background-color: white;
	position: relative;
	header {
		margin-bottom: 0.6rem;
	}
`;
const BoxCountArea = styled.div`
	margin: 0.8rem 0;
	border-bottom: 1px solid ${S.color.primary};
	display: flex;
	align-items: center;
	justify-content: space-between;
	input {
		width: 100%;
		font-size: 1.6rem;
		padding: 1rem;
		font-family: "concertOneRegular";
	}
	button {
		width: 2.2rem;
		height: 2.2rem;
		background-color: ${S.color.gray400};
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
`;
const BoxCountButtonArea = styled(S.flexRowBetween)`
	margin: 1.2rem 0 2.4rem;
	button {
		flex-grow: 2;
		flex-shrink: 1;
		padding: 0.6rem 1rem;
		margin-right: 0.8rem;
		border-radius: 2rem;
		background-color: ${S.color.orange50};
		box-shadow: 0 0.4rem 0 ${S.color.primary};
		border: 1px solid ${S.color.primary};
		color: ${S.color.primary};
	}
	button:last-child {
		flex-grow: 1;
		margin-right: 0;
		flex-shrink: 0;
	}
`;
const HelpButton = styled.button`
	position: absolute;
	top: 2rem;
	right: 2.2rem;
	svg {
		width: 2.2rem;
		height: 2.2rem;
	}
`;
// 오픈 안내
const HelpPopup = styled(S.popupWrapper)`
	p {
		text-align: left;
	}
`;

export default () => {
	const navigate = useNavigate();
	const userState = useRecoilValue(UserAtom);
	const [isLoading, setLoading] = useRecoilState<boolean>(LoadingAtom);
	// 선택된 상자 개수
	const [selectedBoxCount, setSelectedBoxCount] = useState<number>(0);
	// 팝업
	const [isOpenHelp, setOpenOpenHelp] = useState<boolean>(false);

	// 상자 개수 선택
	const addBoxCount = (count: number) => {
		// 선택 가능한 최대 상자 개수 계산
		const userBoxCount = userState.goldboxCnt;
		const maxCount = userBoxCount > 50 ? 50 : userBoxCount;
		if (selectedBoxCount + count >= maxCount) {
			setSelectedBoxCount(maxCount);
		} else if (selectedBoxCount <= maxCount) {
			setSelectedBoxCount(selectedBoxCount + count);
		}
	};
	// 상자 오픈
	const boxOpen = async () => {
		if (isLoading) return;
		setLoading(true);
		await Api.post("/box/open", {
			bmIdx: "2",
			openCnt: selectedBoxCount.toString(),
			// testing:
		})
			.then((res) => {
				// console.log(res);
				if (res.data == undefined) {
				} else {
					if (res.data.statusCode === 200) {
						navigate("/BUPO001002", {
							state: { data: res.data.data },
							replace: true,
						});
					}
				}
			})
			.catch((error) => console.log(error));
		setLoading(false);
	};
	return (
		<>
			<Page
				title="Buka kotak harta karun"
				badge="Beli box"
				badgeAction={() => navigate("/POIN001001")}
				backTo={"/"}
				contents={
					<>
						<Container>
							<BoxSelectArea>
								<button>
									<img src={chestGold} />
									<p>
										<S.mainTitle_M color="white">
											Gold box
										</S.mainTitle_M>
										<S.bodyText_S color="white">
											Anda memiliki{" "}
											{Util.addComma(
												userState.goldboxCnt
											)}{" "}
											buah
										</S.bodyText_S>
									</p>
								</button>
							</BoxSelectArea>
							<OpenArea>
								<header>
									<S.mainTitle_M>
										Pilih jumlah kotakharta karun{`\n`}yang
										akan anda buka.
									</S.mainTitle_M>
								</header>
								<p>
									<S.bodyText_S color={S.color.gray500}>
										Anda bisa membuka maksimal 50 kotak
										harta karun.
									</S.bodyText_S>
								</p>
								<BoxCountArea>
									<input
										type="number"
										placeholder="0"
										value={selectedBoxCount}
										style={{ textAlign: "center" }}
										readOnly
									/>
									<button
										onClick={() => setSelectedBoxCount(0)}
									>
										<XIcon fill="white" width="80%" />
									</button>
								</BoxCountArea>
								<BoxCountButtonArea>
									<button onClick={() => addBoxCount(1)}>
										<S.mainTitle_S>+1</S.mainTitle_S>
									</button>
									<button onClick={() => addBoxCount(5)}>
										<S.mainTitle_S>+5</S.mainTitle_S>
									</button>
									<button onClick={() => addBoxCount(10)}>
										<S.mainTitle_S>+10</S.mainTitle_S>
									</button>
									<button onClick={() => addBoxCount(50)}>
										<S.mainTitle_S>Maksimal</S.mainTitle_S>
									</button>
								</BoxCountButtonArea>
								<S.buttonPrimary
									onClick={() => boxOpen()}
									disabled={selectedBoxCount == 0}
								>
									<S.button_M>
										Buka kotak harta karun
									</S.button_M>
								</S.buttonPrimary>
								<HelpButton
									onClick={() => setOpenOpenHelp(true)}
								>
									<Help fill="#CFCFCF" />
								</HelpButton>
							</OpenArea>
						</Container>
					</>
				}
			/>
			{/* 오픈 안내 */}
			<Modal
				isOpen={isOpenHelp}
				contents={
					<>
						<h2>
							<S.headline_4>Informasi Pembukaan</S.headline_4>
						</h2>
						<p>
							<S.bodyText_M_long color={S.color.gray800}>
								Anda akan mendapatkan satu barang secara acak
								bila membuka random box yang anda beli. Bila
								anda mendapatkan barang setelah membuka kotak
								harta karun, anda tidak bisa membatalkan
								pembayaran karena terhitung sudah menggunakan
								kotak harta karun.
							</S.bodyText_M_long>
						</p>
						<p style={{ marginTop: "1.2rem" }}>
							<S.subhead_3>
								[Informasi Pengiriman/Pengembalian]
							</S.subhead_3>
						</p>
						<S.textList $gray>
							<li>
								<S.bodyText_S>
									Bila menginginkan pengiriman barang yang
									didapat dari kotak harta karun, anda harus
									mengajukan pengiriman di harta karun saya.
									{"\n"}
									(Biaya pengiriman 3,000P, Jeju/daerah pulau
									6,000P)
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Anda bisa membatalkan pengiriman di status
									pengiriman bila harta karun saya berstatus
									mengajukan pengiriman.
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Anda tidak bisa menukar atau mengembalikan
									barang yang telah dikirim dengan alasan
									berubah pikiran atau tidak puas pada barang
									tersebut.
								</S.bodyText_S>
							</li>
							<li>
								<S.bodyText_S>
									Bila terdapat masalah pada barang yang telah
									dikirim (salah kirim, rusak, dll), anda bisa
									menggantinya melalui pertanyaan 1:1.
								</S.bodyText_S>
							</li>
						</S.textList>
					</>
				}
				close={() => setOpenOpenHelp(false)}
			/>
		</>
	);
};
