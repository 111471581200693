// 풀다운 리프레시
import React, {
	PropsWithChildren,
	ReactElement,
	useRef,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as S from "../Styles/Style";

const Container = styled.div`
	height: 1rem;
	flex-grow: 1;
	flex-shrink: 1;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	.loading-element {
		width: 100%;
		height: 0;
		max-height: 5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		overflow: hidden;
		flex-shrink: 0;
	}
	.contents-element {
		height: 100%;
		flex-grow: 1;
	}
`;
const LoadingAni = styled.ul`
	display: flex;
	justify-content: space-between;
	padding-top: 1rem;
	li {
		width: 1rem;
		height: 1rem;
		border-radius: 0.5rem;
		background-color: ${S.color.gray500};
		margin-left: 0.5rem;
		animation: loading 500ms alternate-reverse ease-in-out infinite;
	}
	li:nth-child(2) {
		animation-delay: 200ms;
	}
	li:nth-child(3) {
		animation-delay: 400ms;
	}
	@keyframes loading {
		0% {
			scale: 0.3;
			opacity: 0.3;
		}
		100% {
			scale: 1;
			opacity: 1;
		}
	}
`;

interface DefaultType {
	contents: React.ReactNode;
	handleRefresh: () => void;
}
export default ({
	contents,
	handleRefresh,
}: PropsWithChildren<DefaultType>) => {
	const div = useRef(null);
	const divTop = useRef(null); // div 최초 top
	const divCurrentTop = useRef(null); // 터치 시작하는 시점에서의 top
	const loading = useRef(null);
	const touchStartY = useRef(0);
	const loadingHeight = useRef(0);
	const isLoading = useRef(false);
	// 로딩 요소의 최대 높이
	const MAX_HEIGHT = 50;
	function handleTouchStart(e) {
		if (isLoading.current) return;
		if (divTop.current === null) {
			divTop.current = div.current.getBoundingClientRect().top;
		}
		divCurrentTop.current = div.current.getBoundingClientRect().top;
		if (div.current.scrollTop !== 0) return;
		touchStartY.current = e.changedTouches[0].clientY - divTop.current;
	}
	function handleTouchMove(e) {
		if (isLoading.current) return;
		// 최근 위치
		const clientY = e.changedTouches[0].clientY - divTop.current;

		const height = Math.floor((clientY - touchStartY.current) * 0.2);
		if (clientY >= touchStartY.current) {
			loading.current.style.height = `${height / 10}rem`;
			loadingHeight.current = height;
		}
	}
	function handleTouchEnd(e) {
		if (isLoading.current) return;
		// 로딩 요소의 높이가 MAX_HEIGHT 보다 크다면
		// 로딩 요소의 맨 위에 있을 때
		if (
			loadingHeight.current >= MAX_HEIGHT &&
			divTop.current === divCurrentTop.current
		) {
			// 새로고침 함수 실행
			isLoading.current = true;
			handleRefresh();

			setTimeout(() => {
				loading.current.style.height = 0;
				loadingHeight.current = 0;
				touchStartY.current = 0;
				isLoading.current = false;
			}, 500);
		} else {
			loading.current.style.height = 0;
			loadingHeight.current = 0;
			touchStartY.current = 0;
		}
	}
	return (
		<Container
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
		>
			<div ref={loading} id="loading" className="loading-element">
				<LoadingAni>
					<li></li>
					<li></li>
					<li></li>
				</LoadingAni>
			</div>
			<div className="contents-element" ref={div}>
				{contents}
			</div>

			{<div style={{ height: "1rem" }}></div>}
		</Container>
	);
};
